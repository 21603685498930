import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { isUndefined } from 'lodash';
import { useGlobalStore } from '../../../lib/store';
import { useInputStore } from '../../../lib/input';

import Tabs from '../../../components/common/tabs/';
import General from './tabs/general';

const InputContext = React.createContext();

const tabs = [
  {
    label: '保險基本資料',
    component: <General context={InputContext} />,
    props: {},
  },
];

const Profile = ({ _id, mode, prefix, title, titleIcon }) => {
  const { setDefaultTopnav } = useGlobalStore();
  const store = useInputStore({
    title,
    id: _id,
    prefix,
    mode,
    dataPreprocess: (record) => {
      if (mode === 'copy') {
        if (record?.end_date) {
          record.effective_date = dayjs(record.end_date).add(1, 'day');
          record.end_date = undefined;
        }
      }
    },
    populate: ['contract', 'reg_mark'],
    customValidateFields: {
      input__vehicle_id: 'current_vehicle.vehicle._id',
      input__vehicle_effective_date: 'current_vehicle.effective_date',
      input__vehicle_end_date: 'current_vehicle.end_date',
      input__contract_id: 'contract._id',
    },
    customFieldsModifier: (changes, rawData) => {
      if (!isUndefined(changes['input__vehicle_id'])) {
        changes['input__vehicle_effective_date'] =
          changes['input__vehicle_effective_date'] ||
          rawData?.current_vehicle?.effective_date;
        changes['input__vehicle_end_date'] =
          changes['input__vehicle_end_date'] ||
          rawData?.current_vehicle?.end_date;
      } else if (
        changes['input__vehicle_effective_date'] ||
        changes['input__vehicle_end_date']
      ) {
        changes['input__vehicle_object_id'] = rawData?.current_vehicle?._id;
      }
      return changes;
    },
  });
  const { canSave, initStore, save } = store;

  useEffect(() => {
    initStore({ _id, mode, prefix });
  }, []);
  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      canSave,
      save,
      mode,
      copy: mode === 'edit' && `/${prefix}/copy/${_id}`,
      titleIcon,
    });
  }, [prefix, canSave, save, titleIcon]);
  return (
    <InputContext.Provider value={store}>
      <Tabs tabs={tabs} prefix={prefix} />
    </InputContext.Provider>
  );
};

export default Profile;
