import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Index from '../../components/pages/listing';
import Profile from './profile';
import Upload from './upload';

import { useGlobalStore } from '../../lib/store';

const fieldsToDisplay = ['import_type', 'remarks'];

function Page({ mode, prefix, title, sidebarItem, newEntry }) {
  const { _id } = useParams();
  const { setActiveSidebarItem } = useGlobalStore();
  useEffect(() => {
    setActiveSidebarItem(sidebarItem);
  }, []);
  const isHome = mode === 'home';
  const isImport = mode === 'import';

  return (
    <div>
      {isHome ? (
        <Index prefix={prefix} title={title} newEntry={newEntry} />
      ) : isImport ? (
        <Upload
          prefix={prefix}
          title={title}
          fieldsToDisplay={fieldsToDisplay}
        />
      ) : (
        <Profile _id={_id} mode={mode} prefix={prefix} title={title} />
      )}
    </div>
  );
}

export default Page;
