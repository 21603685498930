import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { findIndex } from 'lodash';

const Tabs = ({ tabs }) => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState('0');
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const hash = location.hash.replace(/#/, '');
    const tab = query.get('tab') || hash;
    const presetTab = findIndex(tabs, { key: tab });
    if (presetTab > 0) {
      setTabIndex('' + presetTab);
    }
  }, [location, tabs]);
  return (
    <Box>
      <TabContext value={tabIndex} scrollButtons allowScrollButtonsMobile>
        <TabList
          value={tabIndex}
          onChange={(_, index) => {
            setTabIndex(index);
            window.location.hash = tabs[index].key;
          }}
          aria-label='basic tabs example'
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            '.MuiTabs-scroller': {
              overflow: 'auto !important',
            },
            '.MuiTabs-flexContainer': {
              width: 'fit-content',
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab label={tab.label} value={'' + index} key={`tab__${index}`} />
          ))}
        </TabList>
        {tabs.map((tab, index) => (
          <TabPanel
            sx={{
              '.MuiTabPanel-root': {
                padding: '0px',
              },
            }}
            value={'' + index}
            key={`tabPanel__${index}`}
          >
            {tab.component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default Tabs;
