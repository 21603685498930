import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalStore } from '../../../lib/store';
import { formatData, useInputStore } from '../../../lib/input';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { get, post } from 'lib/request';
import { map } from 'lodash';
import { ChevronRight, Delete } from '@mui/icons-material';
import To from 'components/common/to';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
const useStore = () => {
  const [rows, setRows] = useState([]);
  const [schema, setSchema] = useState({});
  const [fieldsToDisplay, setFieldsToDisplay] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchData = async () => {
    const data = await get({ url: `/expenses_batch/batches` });
    console.log('111', data.data.records);
    if (data && data?.data) {
      setRows(data.data?.result?.data?.records || []);
      setSchema(data.data?.schema);
      setFieldsToDisplay(
        data.data?.pageConfig?.pages?.batches?.fieldsToDisplay
      );
    }
  };
  const deleteRow = async (row) => {
    const data = await post({
      url: `/expenses_batch/delete-batch`,
      body: { import_batch_id: row?.import_batch_id },
    });
    if (data) {
      fetchData();
    }
    setDialogOpen(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return {
    rows,
    schema,
    fieldsToDisplay,
    deleteRow,
    dialogOpen,
    setDialogOpen,
  };
};

export default function Batch({ prefix, title }) {
  const {
    rows,
    schema,
    fieldsToDisplay,
    deleteRow,
    dialogOpen,
    setDialogOpen,
  } = useStore();

  const { setDefaultTopnav } = useGlobalStore();
  useEffect(() => {
    setDefaultTopnav({
      url: `/expenses`,
      title,
      // canSave,
      // save,
    });
  }, [prefix, title]);

  return (
    <>
      <Dialog open={!!dialogOpen}>
        <DialogTitle id='alert-dialog-title'>確定刪除此紀錄？</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>否</Button>
          <Button onClick={() => deleteRow(dialogOpen)} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='right'></TableCell>
              {map(fieldsToDisplay, (field) => {
                return <TableCell align='right'>{field}</TableCell>;
              })}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align='right'>
                  <Button onClick={() => setDialogOpen(row)}>
                    <Delete />
                  </Button>
                </TableCell>
                {map(fieldsToDisplay, (field, key) => {
                  return (
                    <TableCell align='right'>
                      {formatData({ text: row[key], type: schema[key]?.type })}
                    </TableCell>
                  );
                })}
                <TableCell align='right'>
                  <To url={`/expenses?import_batch_id=${row.import_batch_id}`}>
                    <ChevronRight />
                  </To>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
