import React, { useContext, useEffect, useState } from 'react';
import { isEmpty, lowerCase } from 'lodash';

import Switcher from '../../../../components/common/input/switcher';
import Status from '../../../../components/status';
import {
  Section,
  RelationSection,
} from '../../../../components/common/tabs/section-block';
import MetaData from '../../../../components/meta-data';
import { get } from 'lib/request';

function ProfileGeneral({ context }) {
  const {
    mode,
    prefix,
    data,
    schema,
    setInputs,
    saveBtnClicked,
    pushItemToField,
    fieldsToDisplay,
  } = useContext(context);

  const [hkids, setHkids] = useState([]);

  useEffect(() => {
    get({ url: `/driver/column/hkid` }).then((response) => {
      const existing = response?.data.map((d) => lowerCase(d.label));
      setHkids(existing);
    });
  }, []);

  if (isEmpty(schema)) return null;
  return (
    <div className='flex gap-4 py-10' role='tabpanel'>
      <div className='_p-8 _shadow-md _bg-white grow flex flex-col gap-4'>
        <div>
          <Section>
            {fieldsToDisplay.map((field) => {
              if (field !== 'status') {
                return (
                  <Switcher
                    key={field}
                    schema={{
                      ...schema[field],
                      editable:
                        mode === 'edit' ? schema[field]?.editable : true,
                    }}
                    value={data?.[field]}
                    setInputs={setInputs}
                    saveBtnClicked={saveBtnClicked}
                    field={field}
                    pushItemToField={pushItemToField}
                    suggestEndpoint={`/${prefix}/column/${field}`}
                    customErrorHandler={
                      field === 'hkid'
                        ? (value) => {
                            return hkids.includes(lowerCase(value))
                              ? '此紀錄已被登記'
                              : null;
                          }
                        : null
                    }
                  />
                );
              }
              return null;
            })}
          </Section>
        </div>
        <MetaData data={data} />
      </div>

      <div className='w-6/12 xl:w-5/12 2xl:w-4/12 flex flex-col gap-4 shrink-0'>
        <Status data={data} setInputs={setInputs} />
        {/* <CompanySection
					current_company={data.current_company}
					setInputs={setInputs}
					saveBtnClicked={saveBtnClicked}
				/> */}
      </div>
    </div>
  );
}

const CompanySection = ({
  current_company = null,
  setInputs,
  saveBtnClicked,
}) => {
  const { company, effective_date, end_date } = current_company || {};
  return (
    <div>
      <RelationSection
        title='Company'
        isEdit={company?._id}
        url={`/company/${company?._id || ''}`}
      >
        <Switcher
          value={
            current_company
              ? {
                  _id: company?._id,
                  label: company?.name_tc,
                }
              : undefined
          }
          field={'input__company_id'}
          setInputs={setInputs}
          suggestEndpoint={`/company/all-company-select?filters=_id^!=${
            company?._id || ''
          }`}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: 'text',
            title: 'Company',
            select: true,
          }}
        />
        <Switcher
          value={effective_date}
          field={'input__company_effective_date'}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: 'date',
            title: 'Company effective date',
            is_required: effective_date,
          }}
        />
        <Switcher
          value={end_date}
          field={'input__company_end_date'}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: 'date',
            title: 'Company end date',
            is_required: end_date,
          }}
        />
      </RelationSection>
    </div>
  );
};

export default ProfileGeneral;
