import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Index from '../../components/pages/listing';
import Profile from './profile';
import Upload from './upload';

import { useGlobalStore } from '../../lib/store';

const fieldsToDisplay = ['import_type', 'remarks'];

function Page({
  mode,
  prefix,
  title,
  sidebarItem,
  newEntry,
  upload,
  titleIcon,
}) {
  const { _id } = useParams();
  const location = useLocation();
  const { setActiveSidebarItem } = useGlobalStore();
  useEffect(() => {
    setActiveSidebarItem(sidebarItem);
  }, []);
  const isHome = mode === 'home';
  const isImport = mode === 'import';

  const query = new URLSearchParams(location.search);

  return (
    <div>
      {isHome ? (
        // <Index prefix={prefix} title={title} newEntry={newEntry} />
        <Index
          prefix={prefix}
          title={title}
          newEntry={newEntry}
          titleIcon={titleIcon}
          // isExpenses={true}
          upload={upload}
          defaultFilter={{
            import_batch_id: query.get('import_batch_id') || undefined,
          }}
        />
      ) : isImport ? (
        <Upload
          prefix={prefix}
          title={title}
          fieldsToDisplay={fieldsToDisplay}
          titleIcon={titleIcon}
        />
      ) : (
        <Profile
          _id={_id}
          mode={mode}
          prefix={prefix}
          title={title}
          titleIcon={titleIcon}
        />
      )}
    </div>
  );
}

export default Page;
