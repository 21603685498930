import React, { useMemo, useContext } from 'react';
import { isArray } from 'lodash';

import Table from '../../../../components/common/table';
import TabTable from 'components/common/tabs/table';

function Gpses({ context, fieldsToDisplay }) {
  const { data } = useContext(context);

  const rows = useMemo(() => {
    if (data?.gpses && isArray(data?.gpses)) {
      return data.gpses.map((item, index) => {
        return {
          id: index,
          _id: item?._id,
          gps_number: item?.gps_number,
          charge: item?.charge,
          effective_date: item?.vehicles?.effective_date,
          end_date: item?.vehicles.end_date,
          objectId: item?.vehicles?._id,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <TabTable
      schema={[
        {
          id: 'gps_number',
          isId: true,
          label: 'GPS No.',
          editable: false,
          selectPath: '/gps/all-gps-select',
          free_solo: false,
        },
        {
          id: 'charge',
          label: '每月費用',
          editable: false,
          show_dollar: true,
        },
        {
          id: 'effective_date',
          label: '生效日期',
          type: 'date',
        },
        {
          id: 'end_date',
          label: '結束日期',
          type: 'date',
        },
      ]}
      setting={{
        collection: 'gps',
        keyName: 'vehicle',
        orderBy: 'effective_date',
      }}
      targetId={data._id}
      rows={rows}
    />
  );
}

export default Gpses;
