import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { basename } from './lib/config';

import {
	createBrowserRouter,
	RouterProvider,
	Link,
	BrowserRouter,
} from 'react-router-dom';

// const router = createBrowserRouter(
// 	[
// 		{
// 			path: '/login',
// 			element: <Link to='/'>login</Link>,
// 		},
// 		{
// 			path: '/company',
// 			element: <CompanyPage />,
// 			children: [
// 				{
// 					path: ':_id',
// 				},
// 			],
// 		},
// 		{
// 			path: '/company/new',
// 			element: <CompanyPage isNew />,
// 		},
// 		{
// 			path: '/company/copy/:_id',
// 			element: <CompanyPage isNew />,
// 		},
// 		{
// 			path: '/login',
// 			element: <LoginPage />,
// 		},
// 		{
// 			path: '/',
// 			element: <Index />,
// 		},
// 	],
// 	{
// 		basename,
// 	}
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<CssBaseline />
		{/* <Document>
			<RouterProvider router={router} />
		</Document> */}
		<BrowserRouter basename={basename}>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
