import Sidebar from '../components/sidebar';
import TopNav from '../components/topnav';
import Popup from '../components/common/modal';
import Alert from '../components/common/alert';
import { useGlobalStore } from '../lib/store';
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import LoginPage from './login';
import { LinearProgress } from '@mui/material';
import queryString from 'query-string';

function _document({ children }) {
  const { initStore, isLoading, showAlert, showSidebar, showTopnav } =
    useGlobalStore();

  const location = useLocation();

  useEffect(() => {
    const { alert, alertType } = queryString.parse(location.search);
    if (alert) {
      showAlert({ message: alert, type: alertType });
    }
  }, [location]);

  useEffect(() => {
    initStore();
  }, []);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <div className='flex animate-[fadeIn_0.3s_linear]'>
      <Popup />
      {showSidebar ? (
        <nav className='w-[280px] shrink-0'>
          <Sidebar />
        </nav>
      ) : null}
      <div className='grow bg-zinc-100 min-h-screen p-4 min-w-0 max-w-100'>
        {showTopnav ? <TopNav /> : null}
        <div className='fixed top-16 right-4 z-50 max-w-[300px]'>
          <Alert />
        </div>
        <div className={`${showTopnav ? 'pt-14' : ''} w-full h-full`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default _document;
