import React, { useEffect } from 'react';

import List from '../common/lists';
import { useGlobalStore } from '../../lib/store';

function Index({
  prefix,
  title,
  model,
  newEntry = true,
  upload = false,
  isExpenses = false,
  downloadable = false,
  defaultSort,
  defaultFilter,
  titleIcon,
}) {
  const { setDefaultTopnav } = useGlobalStore();

  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      newEntry: newEntry && `/${prefix}/new`,
      upload: upload && `/${prefix}/import`,
      isExpenses,
      titleIcon,
    });
  }, [prefix]);
  return (
    <div>
      <List
        prefix={prefix}
        model={model}
        defaultSort={defaultSort}
        defaultFilter={defaultFilter}
        downloadable={downloadable}
        pageName='listing'
        titleIcon={titleIcon}
      />
    </div>
  );
}

export default Index;
