import { useEffect, useState } from 'react';
import { atom, useAtom } from 'jotai';
import { useGet, post } from './request';
import { redirect } from './config';
import { useLocation } from 'react-router-dom';

export const _username = atom(null);
export const _isAdmin = atom(null);
export const _topnav = atom(null);
export const _alert = atom(null);
export const _isLoading = atom(true);
export const _showSidebar = atom(true);
export const _showTopnav = atom(true);
export const _documentTitle = atom('Admin - WCCL - VMS');
export const _permissions = atom({});

export const _activeSidebarItem = atom([]);

export const useGlobalStore = () => {
  const [documentTitle, setDocumentTitle] = useAtom(_documentTitle);
  const [isLoading, setIsLoading] = useAtom(_isLoading);
  const [username, setUsername] = useAtom(_username);
  const [isAdmin, setIsAdmin] = useAtom(_isAdmin);
  const [topnav, setTopnav] = useAtom(_topnav);
  const [alert, setAlert] = useAtom(_alert);
  const [permissions, setPermissions] = useAtom(_showSidebar);
  const [showSidebar, setShowSidebar] = useAtom(_showSidebar);
  const [showTopnav, setShowTopnav] = useAtom(_showTopnav);
  const [activeSidebarItem, setActiveSidebarItem] = useAtom(_activeSidebarItem);
  const { result: getUsernameRequestResult, getRequest: getUsernameRequest } =
    useGet();

  const initStore = () => {
    getUsernameRequest({ url: '/profile', prefix: '/user' });
  };

  const logout = async () => {
    await post({ url: '/logout', prefix: '/user' });
    redirect('/');
  };

  useEffect(() => {
    if (getUsernameRequestResult && getUsernameRequestResult.is200) {
      if (getUsernameRequestResult?.data?.username) {
        setIsLoading(false);
        setUsername(
          getUsernameRequestResult?.data?.display_name ||
            getUsernameRequestResult.data.username
        );
        setIsAdmin(getUsernameRequestResult?.data?.is_admin);
        setPermissions(getUsernameRequestResult?.data?.permissions);
      } else {
        redirect('/login');
      }
    }
  }, [getUsernameRequestResult]);

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  const showAlert = ({ message, type, timeout = 10000 }) => {
    setAlert({ message, type, timeout });
  };

  return {
    isLoading,
    initStore,
    username,
    setUsername,
    isAdmin,
    permissions,
    logout,
    topnav,
    showSidebar,
    showTopnav,
    setDefaultTopnav: (props) => {
      setTopnav(props);
    },
    setTopnav,
    showAlert,
    setAlert,
    alert,
    activeSidebarItem,
    setActiveSidebarItem,
    setDocumentTitle,
    setShowSidebar,
    setShowTopnav,
  };
};

export const usePath = () => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  return {
    get: (key) => {
      return query.get(key);
    },
  };
};
