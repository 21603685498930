import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';

import { useGlobalStore } from '../../../lib/store';

function AlertComponent() {
	const [active, setActive] = useState(false);
	const { alert, setAlert } = useGlobalStore();
	useEffect(() => {
		if (alert) {
			setActive(true);
			const timer = setTimeout(() => {
				setAlert(null);
				setActive(false);
			}, alert.timeout);
			return () => clearTimeout(timer);
		}
	}, [alert, setAlert]);

	if (!active) return null;
	return <Alert severity={alert.type}>{alert.message}</Alert>;
}

export default AlertComponent;
