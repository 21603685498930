import React, { useEffect, useMemo } from 'react';
import { useGlobalStore } from '../../../lib/store';
import { useInputStore } from '../../../lib/input';

import Tabs from '../../../components/common/tabs/';
import General from './tabs/general';
import Vehicles from './tabs/vehicles';
import Drivers from './tabs/drivers';

const InputContext = React.createContext();

const tabs = [
  {
    label: '公司基本資料',
    component: <General context={InputContext} />,
    props: {},
  },
  {
    label: '車輛/機械',
    component: <Vehicles context={InputContext} />,
    props: {},
  },
  // {
  // 	label: '司機',
  // 	component: <Drivers context={InputContext} />,
  // 	props: {},
  // },
];

const Profile = ({ _id, mode, prefix, title, titleIcon }) => {
  const { setDefaultTopnav } = useGlobalStore();
  const store = useInputStore({
    title,
    id: _id,
    prefix,
    mode,
    populate: ['sign_image', 'chop_image', 'logo_image'],
    queryParams: '',
    customValidateFields: {
      sign_image: 'sign_image._id',
      chop_image: 'chop_image._id',
      logo_image: 'logo_image._id',
    },
    customFieldsModifier: (changes, rawData) => {
      if (changes['sign_image'] === '') {
        delete changes.sign_image;
      }
      if (changes['chop_image'] === '') {
        delete changes.chop_image;
      }
      if (changes['logo_image'] === '') {
        delete changes.logo_image;
      }
      return changes;
    },
  });
  const { canSave, initStore, save } = store;

  useEffect(() => {
    initStore({ _id, mode, prefix });
  }, []);
  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      canSave,
      save,
      mode,
      titleIcon,
      // copy: mode === 'edit' && `/${prefix}/copy/${_id}`,
    });
  }, [prefix, canSave, save, titleIcon]);
  return (
    <InputContext.Provider value={store}>
      <Tabs tabs={tabs} prefix={prefix} />
    </InputContext.Provider>
  );
};

export default Profile;
