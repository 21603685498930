import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useGlobalStore } from '../../../lib/store';
import { useInputStore } from '../../../lib/input';

import Tabs from '../../../components/common/tabs/';
import General from './tabs/general';

const InputContext = React.createContext();

const Profile = ({ _id, mode, prefix, title, titleIcon }) => {
  const { setDefaultTopnav } = useGlobalStore();
  const store = useInputStore({
    title,
    id: _id,
    prefix,
    mode,
    dataPreprocess: (record) => {
      if (mode === 'copy') {
        if (record?.end_date) {
          record.effective_date = dayjs(record.end_date).add(1, 'day');
          record.end_date = undefined;
        }
      }
    },
    customFieldsModifier: (changes, rawData) => {
      if (changes['input__contract_id']) {
        changes['input__type'] = 'add';
      }
      return changes;
    },
    populate: ['contract'],
    customValidateFields: {
      input__contract_id: 'contract._id',
    },
  });
  const { canSave, initStore, save } = store;

  const tabs = useMemo(
    () => [
      {
        label: '許可證基本資料',
        component: <General context={InputContext} />,
        props: {},
      },
    ],
    []
  );

  useEffect(() => {
    initStore({ _id, mode, prefix });
  }, []);
  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      canSave,
      save,
      mode,
      copy: mode === 'edit' && `/${prefix}/copy/${_id}`,
      titleIcon,
    });
  }, [prefix, canSave, save, titleIcon]);
  return (
    <InputContext.Provider value={store}>
      <Tabs tabs={tabs} prefix={prefix} />
    </InputContext.Provider>
  );
};

export default Profile;
