import React, { useMemo, useContext } from 'react';
import { isArray } from 'lodash';

import Table from '../../../../components/common/table';
import TabTable from 'components/common/tabs/table';

function Autotolls({ context, fieldsToDisplay }) {
	const { data } = useContext(context);

	const rows = useMemo(() => {
		if (data?.autotolls && isArray(data?.autotolls)) {
			return data.autotolls.map((item, index) => {
				return {
					id: index,
					_id: item?._id,
					autotoll_number: item?.autotoll_number,
					effective_date: item?.vehicles?.effective_date,
					end_date: item?.vehicles.end_date,
					objectId: item?.vehicles?._id,
				};
			});
		}
		return [];
	}, [data]);

	return (
		<TabTable
			schema={[
				{
					id: 'autotoll_number',
					isId: true,
					label: 'Autotoll No.',
					editable: false,
					selectPath: '/autotoll/all-autotoll-select',
					free_solo: false,
				},
				{
					id: 'effective_date',
					label: '生效日期',
					type: 'date',
				},
				{
					id: 'end_date',
					label: '結束日期',
					type: 'date',
				},
			]}
			setting={{
				collection: 'autotoll',
				keyName: 'vehicle',
				orderBy: 'effective_date',
			}}
			targetId={data._id}
			rows={rows}
		/>
	);
}

export default Autotolls;
