import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Index from '../../components/pages/listing';

import { useGlobalStore } from '../../lib/store';

function Page({
  mode,
  prefix,
  title,
  model,
  sidebarItem,
  newEntry,
  titleIcon,
}) {
  const { _id } = useParams();
  const { setActiveSidebarItem } = useGlobalStore();
  useEffect(() => {
    setActiveSidebarItem(sidebarItem);
  }, []);

  return (
    <div className='w-full h-full'>
      <Index
        prefix={prefix}
        model={model}
        title={title}
        newEntry={newEntry}
        titleIcon={titleIcon}
        defaultSort={{ field: 'end_date', order: 'asc' }}
      />
    </div>
  );
}

export default Page;
