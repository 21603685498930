import React, { useContext, useMemo } from 'react';

import TabTable from 'components/common/tabs/table';

function Inventories({ context }) {
  const { data } = useContext(context);
  const rows = useMemo(() => {
    if (data && data.inventories) {
      return data.inventories.map((item, index) => {
        return {
          ...item,
          id: index,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <div>
      <div>
        <div className='p-2 shadow-md bg-white grow flex flex-col gap-4'>
          <div className='flex justify-center items-end'>
            尚餘共 {data.total_outstanding}(L)
          </div>
        </div>
      </div>
      <TabTable
        schema={[
          // {
          //   id: 'from_key',
          //   isId: true,
          //   label: 'Key',
          //   editable: false,
          // },
          {
            id: 'unit_price',
            label: '單價',
            show_dollar: true,
          },
          {
            id: 'qty',
            label: '升數',
          },
          {
            id: 'unit',
            label: '單位',
          },
          {
            id: 'amount',
            label: '金額',
            show_dollar: true,
          },
          {
            id: 'outstanding',
            label: '尚餘',
          },
          {
            id: 'inventory_date',
            label: '日期',
            type: 'date',
          },
        ]}
        setting={{
          addable: false,
          editable: false,
          deletable: false,
        }}
        collectionId={data._id}
        rows={rows}
      />
    </div>
  );
}

export default Inventories;
