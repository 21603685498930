import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Index from '../../components/pages/listing';
import Profile from './profile';
import Upload from './upload';
import Batch from './batch';

import { useGlobalStore } from '../../lib/store';

const fieldsToDisplay = ['import_type', 'import_remarks'];

function Page({ mode, prefix, title, sidebarItem, newEntry, titleIcon }) {
  const { _id } = useParams();
  const location = useLocation();
  const { setActiveSidebarItem } = useGlobalStore();
  useEffect(() => {
    setActiveSidebarItem(sidebarItem);
  }, []);
  const isHome = mode === 'home';
  const isImport = mode === 'import';
  const isBatch = mode === 'batch';

  const query = new URLSearchParams(location.search);

  return (
    <div>
      {isHome ? (
        <Index
          prefix={prefix}
          title={title}
          newEntry={newEntry}
          titleIcon={titleIcon}
          defaultFilter={{
            import_batch_id: query.get('import_batch_id') || undefined,
          }}
        />
      ) : isBatch ? (
        <Batch title={title} prefix={prefix} titleIcon={titleIcon} />
      ) : isImport ? (
        <Upload
          prefix={prefix}
          title={title}
          fieldsToDisplay={fieldsToDisplay}
          titleIcon={titleIcon}
        />
      ) : (
        <Profile
          _id={_id}
          mode={mode}
          prefix={prefix}
          title={title}
          titleIcon={titleIcon}
        />
      )}
    </div>
  );
}

export default Page;
