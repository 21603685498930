import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find, forEach, isEmpty, map, reduce, get as _get, uniq } from 'lodash';

import ImportCsv from '../../../components/common/importcsv';
import { useGlobalStore } from '../../../lib/store';
import { get, post } from '../../../lib/request';
import { redirect, toISOString } from '../../../lib/config';
import { usePermissions } from 'lib/permissions';

const _schema = {
  title: {
    title: 'title',
    type: 'text',
  },
  address: {
    title: 'address',
    type: 'textarea',
  },
  in_charge: {
    title: '負責人',
    type: 'text',
  },
  contact_number: {
    title: 'contact_number',
    type: 'text',
    is_phone: true,
  },
};

const Upload = ({ prefix, title, fieldsToDisplay }) => {
  const [error, setError] = useState({});
  const [value, setValue] = useState([]);
  const [records, setRecords] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [schema, setSchema] = useState(null);

  const { setDefaultTopnav } = useGlobalStore();
  const { isWritable } = usePermissions();

  const customError = useCallback(({ msg, field, value, currentRow }) => {
    if (field === 'end_date' && value && currentRow['effective_date']) {
      const { effective_date } = currentRow;
      if (new Date(effective_date) > new Date(value)) {
        msg = 'Remove date must be after Installation date';
      }
    }
    return msg;
  }, []);

  const customWarning = useCallback(
    ({ msg, field, value, currentRow }) => {
      if (
        field === 'contract_number' &&
        find(records, { label: value }) &&
        value
      ) {
        msg = 'Record already exist';
      }
      if (field === 'vehicle' && !find(vehicles, { label: value }) && value) {
        msg = 'Vehicle does not exist';
      }
      return msg;
    },
    [records, vehicles]
  );

  const writable = isWritable({ collection: prefix });

  const canSave = useMemo(() => {
    return !isEmpty(value) && writable;
  }, [value, writable]);

  useEffect(() => {
    if (isEmpty(vehicles) && isEmpty(records) && !isEmpty(value) && prefix) {
      get({ url: `/${prefix}/all-${prefix}-select?statusless=true` }).then(
        ({ is200, data }) => {
          if (is200) {
            setRecords(data);
          }
        }
      );
      get({ url: `/vehicle/all-vehicle-select?statusless=true` }).then(
        ({ is200, data }) => {
          if (is200) {
            setVehicles(data);
          }
        }
      );
    }
  }, [value, records, vehicles, prefix]);

  useEffect(() => {
    get({ url: `/${prefix}/schema` }).then(({ is200, data }) => {
      if (is200) {
        const { schema } = data;
        if (schema) {
          setSchema({
            ...schema,
            ..._schema,
          });
        }
      }
    });
  }, [prefix]);

  const save = useCallback(async () => {
    const body = map(value, (row) => {
      const vehicle = find(vehicles, { label: row.vehicle })?._id;
      return {
        ...row,
        vehicle,
      };
    });

    console.log({ body });

    const result = await post({
      url: `/${prefix}/import`,
      isImport: true,
      body,
    });

    if (result && result.is200 && result.data) {
      console.log(result);
      redirect(
        `/${prefix}?alert=${encodeURIComponent(
          `${result.data.success} record(s) Created/Updated!`
        )}`
      );
    }
  }, [prefix, value, vehicles]);

  useEffect(() => {
    setDefaultTopnav({ url: `/${prefix}`, title, canSave, save });
  }, [prefix, canSave, save]);

  if (isEmpty(schema)) return null;
  return (
    <ImportCsv
      fieldsToDisplay={uniq([...Object.keys(_schema), ...fieldsToDisplay])}
      schema={schema}
      setError={setError}
      setValue={setValue}
      value={value}
      customError={customError}
      customWarning={customWarning}
    />
  );
};
export default Upload;
