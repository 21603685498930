import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useStore } from './store';
import {
  filter,
  get,
  isArray,
  isBoolean,
  isEmpty,
  isUndefined,
  reduce,
  debounce,
  isNull,
  endsWith,
  map,
  forEach,
} from 'lodash';

import {
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Button,
} from '@mui/material';
import {
  NavigateBefore,
  NavigateNext,
  ArrowDownward,
  ArrowUpward,
  Done,
  Clear,
  Delete,
} from '@mui/icons-material';
import To from '../to';
import { isISODateString } from '../../../lib/config';
import { DatePicker } from '@mui/x-date-pickers';
import { get as _get } from 'lib/request';
import JsFileDownloader from 'js-file-downloader';
import { backend, backendPrefix } from 'lib/config';

function Lists({
  prefix,
  model,
  url,
  rowUrl,
  deleteRow,
  pageName,
  defaultSort,
  defaultFilter = {},
  downloadable = false,
}) {
  const {
    initStore,
    data = null,
    setSort,
    setFilters,
    setPage,
    filters,
    pagesize,
    currentPage,
    fieldsToDisplay,
    headerNames = {},
    loading,
  } = useStore({ url });

  const [sortField, setSortField] = useState({ order: 'asc' });
  const [fliterFields, setFilterFields] = useState({});
  const hasRecords = data?.records && !isEmpty(data?.records);

  useEffect(() => {
    let sort = null;
    let filter = null;
    if (defaultSort) {
      setSortField(defaultSort);
      sort = `sort=${defaultSort.order === 'asc' ? '' : '-'}${
        defaultSort.field
      }`;
    }
    if (defaultFilter && Object.values(defaultFilter)[0] !== undefined) {
      filter = Object.entries(defaultFilter)
        .map(([key, value]) => `${key}^~=${value}`)
        .join(',');
      setFilterFields({});
      forEach(defaultFilter, (value, key) => {
        setFilterFields({
          [key]: value,
        });
      });
    }
    initStore({ prefix, model, pageName, sort, filter });
  }, [prefix, model, pageName]);

  const cols = useMemo(() => {
    return fieldsToDisplay.map((field) => {
      const _schema = get(data, `schema[${field}]`, {});
      return {
        field,
        headerName: headerNames[field] || _schema?.title,
        type: endsWith(field, '_date') ? 'date' : _schema?.type,
      };
    });
  }, [fieldsToDisplay, headerNames, data]);

  const schema = useMemo(() => {
    if (data) {
      return data?.schema;
    }
    return {};
  }, [data]);

  const doFilter = useCallback(
    debounce(() => {
      const arr = reduce(
        fliterFields,
        (arr, value, key) => {
          if (!isUndefined(value) && value) {
            if (isBoolean(value) || isISODateString(value)) {
              arr.push(`${key}^=${value}`);
            } else {
              arr.push(`${key}^~=${value}`);
            }
          }
          return arr;
        },
        []
      );
      setFilters(arr.join(','));
    }, 500),
    [fliterFields]
  );

  const downloadCsv = useCallback(() => {
    new JsFileDownloader({
      url: `${backend}${backendPrefix}/${prefix}/lists-csv?pageSize=10000&filters=${
        filters || ''
      }`,
      filename: `${prefix}.csv`,
      withCredentials: true,
    });
  }, [filters, prefix]);

  useEffect(() => {
    if (!isEmpty(fliterFields)) {
      doFilter();
    }
    return doFilter.cancel;
  }, [fliterFields, doFilter]);

  const custom_pagesize = data?.metadata?.pageSize || pagesize;
  return (
    <div className='w-full'>
      {hasRecords && data?.metadata && (
        <div className='py-2 px-4'>
          {downloadable && (
            <div className=''>
              <Button onClick={downloadCsv}>Download CSV</Button>
            </div>
          )}
          <div className='flex gap-2 items-center justify-end'>
            <div className='text-lg'>
              {(parseInt(data?.metadata?.page) - 1) * custom_pagesize + 1}-
              {(parseInt(data?.metadata?.page) - 1) * custom_pagesize +
                (data?.records || []).length}{' '}
              of {data?.metadata?.total || 0}
            </div>
            <div className=''>
              <IconButton
                onClick={() => setPage(parseInt(data?.metadata?.page) - 1)}
                disabled={!data?.metadata?.hasPrevPage}
              >
                <NavigateBefore />
              </IconButton>
            </div>
            <div className=''>
              <IconButton
                onClick={() => setPage(parseInt(data?.metadata?.page) + 1)}
                disabled={!data?.metadata?.hasNextPage}
              >
                <NavigateNext />
              </IconButton>
            </div>
          </div>
        </div>
      )}

      <div className='w-full'>
        <div className='border rounded-tr-xl rounded-tl-xl bg-zinc-50 flex gap-4 p-3 border-b-0'>
          {deleteRow && <div className='w-8'></div>}
          {cols.map((col) => {
            return (
              <div className='grow basis-0 flex flex-col' key={col.field}>
                <div
                  className='text-md font-bold text-left hover:cursor-pointer select-none'
                  onClick={() => {
                    const { order } = sortField;
                    setSortField({
                      field: col.field,
                      order: order === 'asc' ? 'desc' : 'asc',
                    });
                    setSort(`sort=${order === 'asc' ? '' : '-'}${col.field}`);
                  }}
                >
                  <span>{col.headerName}</span>
                  {sortField.field === col.field && (
                    <span>
                      {sortField.order === 'asc' ? (
                        <ArrowDownward color='disabled' fontSize='small' />
                      ) : (
                        <ArrowUpward color='disabled' fontSize='small' />
                      )}
                    </span>
                  )}
                </div>
                <div className='grow py-2'>
                  {col.type === 'date' ? (
                    <DatePicker
                      clearable
                      slotProps={{
                        actionBar: { actions: ['clear', 'today'] },
                      }}
                      format='YYYY-MM-DD'
                      onChange={(date) => {
                        setFilterFields((v) => {
                          return {
                            ...v,
                            [col.field]:
                              date && date.isValid()
                                ? date.toISOString()
                                : undefined,
                          };
                        });
                      }}
                      sx={{
                        '.MuiOutlinedInput-input': {
                          padding: 0,
                          paddingLeft: 1,
                          height: 40,
                        },
                      }}
                    />
                  ) : col.field === 'status' ? (
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        value={fliterFields.status || ''}
                        onChange={(e) => {
                          setFilterFields((v) => {
                            return {
                              ...v,
                              status: e.target.value,
                            };
                          });
                        }}
                        sx={{
                          height: '40px',
                        }}
                      >
                        <MenuItem value={undefined}>
                          <Done color='success' /> | <Clear color='warning' />
                        </MenuItem>
                        <MenuItem value={true}>
                          <Done color='success' />
                        </MenuItem>
                        <MenuItem value={false}>
                          <Clear color='warning' />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      size='small'
                      fullWidth
                      value={fliterFields[col.field]}
                      onChange={(e) => {
                        setFilterFields((v) => {
                          return {
                            ...v,
                            [col.field]: e.target.value,
                          };
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className='bg-white border rounded-bl-xl rounded-br-xl text-lg py-3'>
          {loading || !hasRecords ? (
            <div className='h-40 flex items-center justify-center w-full text-xl'>
              {loading ? <CircularProgress /> : 'no record'}
            </div>
          ) : (
            (data?.records || []).map((record) => {
              return (
                <div className='flex gap-4 w-full flex gap-4 py-2 hover:bg-zinc-200 px-3 even:bg-zinc-50'>
                  {deleteRow && (
                    <div className='w-8'>
                      <Delete
                        className='cursor-pointer'
                        onClick={() => deleteRow(record)}
                      />
                    </div>
                  )}
                  <To
                    className='flex items-center w-full gap-4'
                    url={
                      rowUrl ? rowUrl(record._id) : `/${prefix}/${record._id}`
                    }
                    key={record._id}
                  >
                    {cols.map((col) => {
                      const _schema = schema[col.field];
                      const showDollar = _schema?.show_dollar;
                      const textRight = _schema?.text_right;
                      const showZero = _schema?.show_zero;
                      const text_position = textRight
                        ? 'text-right'
                        : 'text-left';
                      const v = record[col.field];
                      const value = v
                        ? isISODateString(v)
                          ? dayjs(v).format('YYYY-MM-DD')
                          : showDollar
                          ? `$${parseFloat(v)
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                          : v
                        : col.type === 'boolean'
                        ? v
                        : showZero && showDollar
                        ? '$0'
                        : showZero
                        ? '0'
                        : '';
                      return (
                        <div
                          className={`grow basis-0 ${text_position} shrink-0 break-all`}
                          key={col.field}
                        >
                          {isBoolean(value) ? (
                            value ? (
                              <Done color='success' />
                            ) : (
                              <Clear color='warning' />
                            )
                          ) : (
                            value || ''
                          )}
                        </div>
                      );
                    })}
                  </To>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default Lists;
