const Logo = () => {
	return (
		<img
			className='my-4 w-[200px] h-[48px]'
			src='/assets/images/logo.png'
			alt='logo'
		/>
	);
};

export default Logo;
