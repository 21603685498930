import { useGlobalStore } from './store';
import { find } from 'lodash';

export const usePermissions = () => {
  const { permissions } = useGlobalStore();

  const isWritable = ({ collection }) => {
    return find(permissions, { collection })?.writable;
  };
  const isReadable = ({ collection }) => {
    return find(permissions, { collection })?.readable;
  };

  return {
    permissions,
    isWritable,
    isReadable,
  };
};
