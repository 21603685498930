import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalStore } from '../../../lib/store';
import { formatData, useInputStore } from '../../../lib/input';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from 'components/common/lists';
import { get, post } from 'lib/request';
import { map } from 'lodash';
import {
  ChevronRight,
  Delete,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';
import To from 'components/common/to';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { redirect, toISOString } from '../../../lib/config';
import { useParams } from 'react-router-dom';

const useStore = () => {
  const [data, setDatas] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesize, setPagesizes] = useState([]);
  const [rows, setRows] = useState([]);
  const [schema, setSchema] = useState({});
  const [fieldsToDisplay, setFieldsToDisplay] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const startOfMonth = new Date();
  startOfMonth.setDate(1);
  const lastDayOfMonth = new Date(
    startOfMonth.getFullYear(),
    startOfMonth.getMonth() + 1,
    0
  );

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const text = params.get('start_date');
  const [expenseStartDate, setExpenseStartDate] = useState(dayjs(text));
  const [expenseEndDate, setExpenseEndDate] = useState(
    dayjs(params.get('end_date'))
  );

  console.log('reports params', params, params.get('start_date'));

  const fetchData = async () => {
    const data = await get({
      url: `/monthly_expenses/reports?page=${page}&start_date=${expenseStartDate}&end_date=${expenseEndDate}`,
    });
    if (data && data?.data) {
      console.log('reports page data', data.start_date);
      setRows(data.data?.result?.data?.records || []);
      setDatas(data.data?.result?.data || []);
      setPagesizes(data.data?.result?.data?.metadata?.pageSize);
      setExpenseStartDate(expenseStartDate);
      setExpenseEndDate(expenseEndDate);
      setSchema(data.data?.schema);
      setFieldsToDisplay(
        data.data?.pageConfig?.pages?.reports?.fieldsToDisplay
      );
    }
  };
  const { showAlert } = useGlobalStore();

  useEffect(() => {
    fetchData();
  }, [page, expenseStartDate, expenseEndDate]);

  return {
    rows,
    schema,
    fieldsToDisplay,
    dialogOpen,
    setDialogOpen,
    pagesize,
    data,
    setPage,
    expenseStartDate,
    setExpenseStartDate,
    expenseEndDate,
    setExpenseEndDate,
  };
};

export default function MonthlyExpenses({ prefix, title, titleIcon }) {
  const {
    rows,
    schema,
    fieldsToDisplay,
    dialogOpen,
    setDialogOpen,
    pagesize,
    data,
    setPage,
    expenseStartDate,
    setExpenseStartDate,
    expenseEndDate,
    setExpenseEndDate,
  } = useStore();
  const { page } = useParams();

  const { setDefaultTopnav } = useGlobalStore();

  useEffect(() => {
    setDefaultTopnav({
      url: `/monthly_expenses`,
      title,
      // canSave,
      // save,
      isExpensesBatch: false,
      titleIcon,
    });
  }, [prefix, title, titleIcon]);

  return (
    <>
      <div className='w-full h-full flex flex-row items-center -justify-center pt-4'>
        <div className='-text-center text-xl mb-2 mx-2'>Start Date:</div>
        <FormControl>
          <DatePicker
            clearable
            slotProps={{
              actionBar: { actions: ['clear', 'today'] },
            }}
            format='YYYY-MM-DD'
            value={expenseStartDate}
            onChange={(v) => {
              if (v) {
                const date = dayjs(v);
                if (date.isValid()) {
                  setExpenseStartDate(date);
                }
              } else if (v === null) {
                setExpenseStartDate(null);
              }
            }}
          />
        </FormControl>
      </div>
      <div className='w-full h-full flex flex-row items-center -justify-center pt-4'>
        <div className='-text-center text-xl mb-2 mx-2'>End Date:</div>
        <FormControl>
          <DatePicker
            clearable
            slotProps={{
              actionBar: { actions: ['clear', 'today'] },
            }}
            format='YYYY-MM-DD'
            value={expenseEndDate}
            onChange={(v) => {
              if (v) {
                const date = dayjs(v);
                if (date.isValid()) {
                  setExpenseEndDate(date);
                }
              } else if (v === null) {
                setExpenseEndDate(null);
              }
            }}
          />
        </FormControl>
      </div>

      {expenseStartDate ? (
        <List
          url={'/monthly_expenses/reports?start_date='}
          rowUrl={(id) => `/contract/${id}`}
          prefix='monthly_expenses'
          downloadable={false}
          pageName='listing'
          titleIcon={titleIcon}
        />
      ) : null}
    </>
  );
}
