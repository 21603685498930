import React, { useEffect, useContext, useState, useMemo, useRef } from 'react';
import { every, findIndex, isEmpty } from 'lodash';

import Switcher from '../../../../components/common/input/switcher';
import Status from '../../../../components/status';
import {
  Section,
  RelationSection,
} from '../../../../components/common/tabs/section-block';
import MetaData from '../../../../components/meta-data';
import { useGlobalStore } from 'lib/store';
import { Checkbox } from '@mui/material';

function parsePermissions(permissions) {
  return permissions
    .map(({ collection, writable, readable }) => {
      const writePermission = writable ? 'w' : '';
      const readPermission = readable ? 'r' : '';
      return `${collection}:${writePermission}${readPermission}`;
    })
    .join('|');
}

function ProfileGeneral({ context }) {
  const {
    mode,
    prefix,
    data,
    schema,
    setInputs,
    saveBtnClicked,
    pushItemToField,
    fieldsToDisplay,
  } = useContext(context);

  const { permissions } = useGlobalStore();
  const permissionsRef = useRef();

  const [updatePermissions, setUpdatePermissions] = useState(permissions);

  const handlePermissions = (collection, type, value) => {
    setUpdatePermissions((v) => {
      const index = findIndex(v, { collection });
      const item = [...v];
      item[index] = {
        ...v[index],
        [type]: value,
      };
      return item;
    });
  };

  const handleSelectAllPermissions = (type, value) => {
    setUpdatePermissions((v) => {
      const items = [...v];
      return items.map((item) => ({
        ...item,
        [type]: value,
      }));
    });
  };

  useEffect(() => {
    const updated = parsePermissions(updatePermissions);
    const original = parsePermissions(permissions);

    if (updated !== original) {
      if (!permissionsRef.current) {
        permissionsRef.current = {};
      }
      permissionsRef.current.value = updated;
      setInputs((v) => {
        return {
          ...v,
          permissions: permissionsRef,
        };
      });
    }
  }, [updatePermissions, permissions, setInputs]);

  if (isEmpty(schema)) return null;
  return (
    <div className='flex gap-4 py-10' role='tabpanel'>
      <div className='_p-8 _shadow-md _bg-white grow flex flex-col gap-4'>
        <div>
          <Section>
            {fieldsToDisplay.map((field) => {
              if (field !== 'status') {
                return (
                  <Switcher
                    key={field}
                    schema={{
                      ...schema[field],
                      editable:
                        mode === 'edit' ? schema[field]?.editable : true,
                    }}
                    value={data[field]}
                    setInputs={setInputs}
                    saveBtnClicked={saveBtnClicked}
                    field={field}
                    pushItemToField={pushItemToField}
                    suggestEndpoint={`/${prefix}/column/${field}`}
                  />
                );
              }
              return null;
            })}
          </Section>
        </div>
        <div>
          <Section>
            <div className='flex gap-2 items-center'>
              <div className='w-40'></div>
              <div className='w-20'>
                <div>可讀取</div>
                <div>
                  <Checkbox
                    checked={every(updatePermissions, ['readable', true])}
                    onChange={(e) =>
                      handleSelectAllPermissions('readable', e.target.checked)
                    }
                  />
                </div>
              </div>
              <div className='w-20'>
                <div>可寫入</div>
                <div>
                  <Checkbox
                    checked={every(updatePermissions, ['writable', true])}
                    onChange={(e) =>
                      handleSelectAllPermissions('writable', e.target.checked)
                    }
                  />
                </div>
              </div>
            </div>
            {updatePermissions.map((row) => {
              return (
                <div className='flex gap-2 items-center'>
                  <div className='w-40'>{row?.collection}</div>
                  <div className='w-20'>
                    <Checkbox
                      checked={row?.readable}
                      onChange={(e) =>
                        handlePermissions(
                          row?.collection,
                          'readable',
                          e.target.checked
                        )
                      }
                    />
                  </div>
                  <div className='w-20'>
                    <Checkbox
                      checked={row?.writable}
                      onChange={(e) =>
                        handlePermissions(
                          row?.collection,
                          'writable',
                          e.target.checked
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}
          </Section>
        </div>
        <MetaData data={data} />
      </div>
      <div className='w-3/12 xl:w-5/12 2xl:w-4/12 flex flex-col gap-4 shrink-0'>
        <Status data={data} setInputs={setInputs} />
      </div>
    </div>
  );
}

export default ProfileGeneral;
