import React, { useEffect } from 'react';
import { IconButton, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  Add,
  Save,
  NavigateBefore,
  Upload,
  ContentCopy,
  Settings,
} from '@mui/icons-material';

import List from '../../../components/common/lists';
import { redirect } from '../../../lib/config';
import { useGlobalStore } from '../../../lib/store';
import To from '../../../components/common/to';

const fieldsToDisplay = ['collection_name', 'action', 'createdAt'];

function Index({ prefix }) {
  const { setTopnav } = useGlobalStore();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    setTopnav(<TopNav prefix={prefix} />);
  }, []);

  return (
    <div>
      <List
        prefix={prefix}
        fieldsToDisplay={fieldsToDisplay}
        defaultFilter={{
          doc_id: query.get('doc_id') || undefined,
        }}
        pageName='listing'
      />
    </div>
  );
}

const TopNav = ({ prefix }) => {
  return (
    <div className='flex items-center'>
      <div className='pl-2 pr-2'>
        {/* <To url={'/log'}>
          <IconButton>
            <NavigateBefore />
          </IconButton>
        </To> */}
        <Settings />
      </div>
      <div className='text-xl capitalize'>{prefix}</div>
    </div>
  );
};

export default Index;
