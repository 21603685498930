import React, { useMemo, useContext } from 'react';
import { isArray } from 'lodash';

import Table from '../../../../components/common/table';
import TabTable from 'components/common/tabs/table';

function Fuels({ context, fieldsToDisplay }) {
	const { data } = useContext(context);

	const rows = useMemo(() => {
		if (data?.fuels && isArray(data?.fuels)) {
			return data.fuels.map((item, index) => {
				return {
					id: index,
					_id: item?._id,
					fuel_type: item?.fuel_type,
					provider: item?.provider,
					card_number: item?.card_number,
					effective_date: item?.vehicles?.effective_date,
					end_date: item?.vehicles.end_date,
					objectId: item?.vehicles?._id,
				};
			});
		}
		return [];
	}, [data]);

	return (
		<TabTable
			schema={[
				{
					id: 'fuel_type',
					label: '燃油種類',
					editable: false,
				},
				{
					id: 'provider',
					label: '供應商',
					editable: false,
				},
				{
					id: 'card_number',
					isId: true,
					label: '油咭號碼',
					editable: false,
					selectPath: '/fuel/all-fuel-select',
					free_solo: false,
				},
				{
					id: 'account_number',
					label: '油咭賬號',
					editable: false,
				},
				{
					id: 'effective_date',
					label: '生效日期',
					type: 'date',
				},
				{
					id: 'end_date',
					label: '結束日期',
					type: 'date',
				},
			]}
			setting={{
				collection: 'fuel',
				keyName: 'vehicle',
				orderBy: 'effective_date',
			}}
			targetId={data._id}
			rows={rows}
		/>
	);
}

export default Fuels;
