import { useCallback, useMemo, useState } from 'react';
import {
  isEmpty,
  reduce,
  forEach,
  get as _get,
  clone,
  set,
  isUndefined,
  isFunction,
  map,
  find,
} from 'lodash';

import { get, post, put } from './request';
import { useGlobalStore } from './store';
import { flattenObject, parseISODateString, redirect } from './config';

export const getErrorMessage = ({ schema, text }) => {
  const {
    regExp,
    is_required = false,
    is_email,
    is_phone,
    is_number_only,
    is_positive,
    is_short_date,
  } = schema;
  let message = null;

  if (is_required && isEmpty(text)) {
    message = '此欄位不能為空';
  }

  if (text !== null && text !== '' && typeof text !== 'undefined') {
    if (is_email && !/^\S+@\S+\.\S+$/.test(text)) {
      message = '請輸入正確電郵';
    }

    if (is_phone && !/^[1-9]\d{7}$/.test(text)) {
      message = '請輸入正確電話號碼';
    }

    if (is_number_only && !/^[-]?\d+(\.\d+)?$/.test(text)) {
      message = '請輸入數字';

      if (!message && is_positive && !/^\d+(\.\d+)?$/.test(text)) {
        message = '請輸入正數';
      }
    }

    if (is_short_date && !/^\d{4}-\d{2}-\d{2}$/.test(text)) {
      message = '請輸入有效日期';
    }
    if (regExp && !isEmpty(regExp)) {
      const test = new RegExp(regExp);

      if (!test.test(text)) {
        message = '請輸入正確格式';
      }
    }
  }

  return message;
};

export const exportBodyFiles = ({ body, fields }) => {
  return reduce(
    body,
    (obj, curr, index) => {
      if (fields.includes(index)) {
        obj.files = {
          ...(obj?.files || {}),
          [index]: curr,
        };
      } else {
        obj.body = {
          ...(obj?.body || {}),
          [index]: curr,
        };
      }
      console.log({ obj });
      return obj;
    },
    {}
  );
};

export const formatData = ({ text, key, type, show_dollar }) => {
  if (!isEmptyInput(text)) {
    if ((key && key.includes('_date')) || type === 'date') {
      return parseISODateString(text);
    }
    if (show_dollar) {
      return `$${text}`;
    }
  }
  return text;
};

export const isEmptyInput = (v) => v === '' || v === null || v === undefined;

export const useInputStore = ({
  title,
  id,
  prefix,
  mode,
  dataPreprocess,
  queryParams = '',
  populate = [],
  customValidateFields = {},
  customFieldsModifier,
}) => {
  const [loading, setLoading] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  const [fieldsToDisplay, setFieldsToDisplay] = useState([]);
  const [relationSeciton, setRelationSeciton] = useState({});
  const [inputs, setInputs] = useState({});
  const [data, setData] = useState({});
  const [schema, setSchema] = useState(null);
  const [rawData, setRawData] = useState(null);
  const { showAlert, permissions } = useGlobalStore();
  const isCopy = mode === 'copy';
  const isEdit = mode === 'edit';

  const queryString = useMemo(() => {
    return `?${map(populate, (v) => {
      return `populate[]=${v}`;
    }).join('&')}`;
  }, [populate]);

  const removeUnchangedFields = useCallback(
    (obj, field, value, conditions) => {
      const ref = isCopy ? {} : rawData;
      forEach(conditions, (rawDataKey, inputField) => {
        if (field === inputField) {
          const previous = _get(ref, rawDataKey);
          if (previous === value) {
            delete obj[field];
          } else if (!(isEmptyInput(previous) && isEmptyInput(value))) {
            obj[field] = value;
          }
        }
      });
      return obj;
    },
    [rawData]
  );
  const getBody = () => {
    const edited = reduce(
      inputs,
      (obj, input, field) => {
        if (input?.current?.type === 'checkbox') {
          obj[field] = input?.current?.checked;
        } else {
          if (!isUndefined(input?.current?.customValue)) {
            obj[field] = input.current.customValue;
          } else if (!isUndefined(input?.current?.value)) {
            obj[field] = input.current.value;
          }
        }
        return obj;
      },
      {}
    );
    const old_data = flattenObject(
      reduce(
        fieldsToDisplay,
        (obj, field) => {
          obj[field] = data[field];
          return obj;
        },
        {}
      )
    );
    const changes = reduce(
      { ...old_data, ...edited },
      (obj, value, field) => {
        if (isEmptyInput(old_data[field]) && isEmptyInput(value)) {
        } else if (old_data[field] !== value || mode !== 'edit') {
          obj[field] = value;
        }

        obj = removeUnchangedFields(obj, field, value, customValidateFields);

        return obj;
      },
      {}
    );

    if (isFunction(customFieldsModifier)) {
      return customFieldsModifier(changes, isCopy ? {} : rawData);
    } else {
      return changes;
    }
  };

  const fetch = async ({ id, mode, prefix }) => {
    const url = `/${prefix}/${
      mode === 'new' ? 'schema' : `/get/${id}${queryParams || queryString}`
    }`;
    setLoading(true);
    const { data, is200 } = await get({
      url,
    });

    if (is200 && data) {
      let { record } = data || null;
      if (record) {
        // if (mode !== 'edit') {
        // 	delete record.company;
        // 	delete record.companies;
        // }
        if (isFunction(dataPreprocess)) {
          dataPreprocess(record);
        }
        setData(record || {});
        setRawData(record || {});
      }
      setFieldsToDisplay(data?.pageConfig?.pages?.profile?.fieldsToDisplay);
      setRelationSeciton(data?.pageConfig?.pages?.profile?.relationSeciton);
      setSchema(data.schema);
    }
    setLoading(false);
  };

  const initStore = useCallback(() => {
    if (prefix && mode && !rawData) {
      fetch({ id, prefix, mode });
    }
  }, [id, prefix, mode, rawData]);

  const canSave = useMemo(() => {
    return find(permissions, { collection: prefix })?.writable;
  }, [permissions, prefix]);

  const save = useCallback(async () => {
    if (!saveBtnClicked) setSaveBtnClicked(true);

    const canSave = reduce(
      inputs,
      (state, input) => {
        const invalid = input?.current?.ariaInvalid;
        if (invalid === 'true') {
          state = false;
        }
        return state;
      },
      true
    );

    if (!canSave) {
      showAlert({ message: '請填寫正確資料', type: 'error' });
    } else {
      const body = getBody();
      console.log({ body });
      if (!isEmpty(body)) {
        if (mode === 'edit') {
          const { is200 } = await put({
            url: `/${prefix}/update/${id}`,
            body,
          });
          if (is200) {
            showAlert({ message: '成功更新!' });
            fetch({ id, mode, prefix });
          } else {
            showAlert({ message: '發生錯誤' });
          }
        } else {
          const { data, is200 } = await post({
            url: `/${prefix}/new`,
            body,
          });
          if (is200 && data._id) {
            showAlert({ message: '成功更新!' });
            redirect(
              `/${prefix}/${data._id}?alert=${encodeURIComponent(
                `新 ${title} 已創建!`
              )}`
            );
          } else {
            const { err = '' } = data || {};
            const message = err ? `[${err}]` : '';
            showAlert({ message: `發生錯誤 ${message}`, type: 'error' });
          }
        }
      }
    }
  }, [prefix, data, mode, id, inputs, saveBtnClicked]);

  return {
    mode,
    isCopy,
    isEdit,
    prefix,
    schema,
    data,
    rawData,
    canSave,
    save,
    initStore,
    saveBtnClicked,
    setInputs,
    fieldsToDisplay,
    relationSeciton,
    pushItemToField: (field) => {
      const copy = clone(data);
      set(copy, field, null);
      console.log({ copy });
      setData(copy);
    },
  };
};
