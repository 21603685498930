import React, { useContext, useMemo } from 'react';

import Table from '../../../../components/common/table';

const fieldsToDisplay = {
	staff_number: '員工編號', //員工編號
	name: '英文名稱',
	name_tc: '名稱',
	license: '駕駛執照',
	dob: '歲數 (出生日期)',
	effective_date: '受雇日期',
	end_date: '受雇終止日',
};

function Drivers({ context }) {
	const { data } = useContext(context);
	const rows = useMemo(() => {
		if (data && data.drivers) {
			return data.drivers.map((item, index) => {
				return {
					...item?.driver,
					...item,
					id: index,
				};
			});
		}
		return [];
	}, [data]);

	return (
		<Table
			data={rows}
			fieldsToDisplay={fieldsToDisplay}
			editEndPoint='/driver'
		/>
	);
}

export default Drivers;
