import React, { useMemo, useContext } from 'react';
import { isArray } from 'lodash';

import Table from '../../../../components/common/table';
import TabTable from 'components/common/tabs/table';

function Licenses({ context, fieldsToDisplay }) {
  const { data } = useContext(context);

  const rows = useMemo(() => {
    if (data?.licenses && isArray(data?.licenses)) {
      return data.licenses.map((item, index) => {
        return {
          id: index,
          _id: item?.license?._id,
          permit_fee: item?.license?.permit_fee,
          special_permit: item?.license?.special_permit,
          license_fee: item?.license?.license_fee,
          effective_date: item?.license?.effective_date,
          end_date: item?.license?.end_date,
          objectId: item?._id,
          contract_number: item?.license?.contract?.contract_number,
          reg_mark: item?.license?.reg_mark?.reg_mark,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <TabTable
      schema={[
        {
          id: 'effective_date',
          label: '續牌日期',
          type: 'date',
        },
        {
          id: 'end_date',
          label: '結束日期',
          type: 'date',
        },
        {
          id: 'license_fee',
          label: '牌費',
          show_dollar: true,
        },
        {
          id: 'special_permit',
          label: '特別許可證',
        },
        {
          id: 'permit_fee',
          label: '特別許可費',
          show_dollar: true,
        },
        {
          id: 'reg_mark',
          label: '車牌',
        },
        {
          id: 'contract_number',
          label: '扣數合約',
        },
      ]}
      setting={{
        popup: false,
        collection: 'vehicle',
        keyName: 'license',
        orderBy: 'effective_date',
        copyable: true,
      }}
      collectionId={data._id}
      rows={rows}
    />
  );
}

export default Licenses;
