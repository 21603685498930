import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalStore } from '../../../lib/store';
import { formatData, useInputStore } from '../../../lib/input';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { get, post } from 'lib/request';
import { map } from 'lodash';
import {
  ChevronRight,
  Delete,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';
import To from 'components/common/to';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const useStore = () => {
  const [data, setDatas] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesize, setPagesizes] = useState([]);
  const [rows, setRows] = useState([]);
  const [schema, setSchema] = useState({});
  const [fieldsToDisplay, setFieldsToDisplay] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchData = async () => {
    const data = await get({ url: `/expenses_batch/batches?page=${page}` });
    if (data && data?.data) {
      setRows(data.data?.result?.data?.records || []);
      setDatas(data.data?.result?.data || []);
      setPagesizes(data.data?.result?.data?.metadata?.pageSize);
      setSchema(data.data?.schema);
      setFieldsToDisplay(
        data.data?.pageConfig?.pages?.batches?.fieldsToDisplay
      );
    }
  };
  const { showAlert } = useGlobalStore();
  const deleteRow = async (row) => {
    const data = await post({
      url: `/expenses_batch/delete-batch`,
      body: {
        import_batch_id: row?.import_batch_id,
        import_type: row?.import_type,
      },
    });

    if (data?.data?.err) {
      const message = data?.data?.error_msg || 'Default Error Message';
      showAlert({ message, type: 'error' });
    }
    if (data) {
      fetchData();
    }
    setDialogOpen(false);
  };
  useEffect(() => {
    fetchData();
  }, [page]);

  return {
    rows,
    schema,
    fieldsToDisplay,
    deleteRow,
    dialogOpen,
    setDialogOpen,
    pagesize,
    data,
    setPage,
  };
};

export default function ContractVehicle({ prefix, title, titleIcon }) {
  const {
    rows,
    schema,
    fieldsToDisplay,
    deleteRow,
    dialogOpen,
    setDialogOpen,
    pagesize,
    data,
    setPage,
  } = useStore();

  const { setDefaultTopnav } = useGlobalStore();

  useEffect(() => {
    setDefaultTopnav({
      url: `/expenses`,
      title,
      // canSave,
      // save,
      isExpensesBatch: true,
      titleIcon,
    });
  }, [prefix, title, titleIcon]);
  return (
    <>
      <Dialog open={!!dialogOpen}>
        <DialogTitle id='alert-dialog-title'>確定刪除此紀錄？</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>否</Button>
          <Button onClick={() => deleteRow(dialogOpen)} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <div className='flex gap-2 items-center justify-end'>
        <div className='text-lg'>
          {(data?.metadata?.page - 1) * pagesize + 1}-
          {(data?.metadata?.page - 1) * pagesize + (data?.records || []).length}{' '}
          of {data?.metadata?.total || 0}
        </div>
        <div className=''>
          <Button
            onClick={() => setPage(parseInt(data?.metadata?.page) - 1)}
            disabled={!data?.metadata?.hasPrevPage}
          >
            <NavigateBefore />
          </Button>
        </div>
        <div className=''>
          <Button
            onClick={() => setPage(parseInt(data?.metadata?.page) + 1)}
            disabled={!data?.metadata?.hasNextPage}
          >
            <NavigateNext />
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='right'></TableCell>
              {map(fieldsToDisplay, (field) => {
                return <TableCell align='right'>{field}</TableCell>;
              })}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align='right'>
                  <Button onClick={() => setDialogOpen(row)}>
                    <Delete />
                  </Button>
                </TableCell>
                {map(fieldsToDisplay, (field, key) => {
                  return (
                    <TableCell align='right'>
                      {formatData({ text: row[key], type: schema[key]?.type })}
                    </TableCell>
                  );
                })}
                <TableCell align='right'>
                  <To
                    url={`/expenses_batch?import_batch_id=${row.import_batch_id}`}
                  >
                    <ChevronRight />
                  </To>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
