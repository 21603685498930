import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { isUndefined, map, toNumber } from 'lodash';
import { formatData } from 'lib/input';
import {
  Delete,
  Edit,
  ChevronLeft,
  Search,
  Add,
  ContentCopy,
} from '@mui/icons-material';
import { Badge, Button } from '@mui/material';
import Popup from '../popup';
import To from '../to';
import { redirect } from 'lib/config';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    padding: 4,
  },
  [`&.${tableCellClasses.body}`]: {
    padding: 4,
  },
}));

const AddButton = ({
  schema,
  collection,
  collectionId,
  targetId,
  keyName,
  popup,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {open && (
        <Popup
          isAdd
          open={open}
          toClose={() => setOpen(false)}
          schema={schema}
          collection={collection}
          collectionId={collectionId}
          targetId={targetId}
          keyName={keyName}
        />
      )}

      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          if (popup === false) {
            redirect(`/${keyName}/new?vehicle=${collectionId}`, true);
          } else {
            setOpen(true);
          }
        }}
        endIcon={<Add />}
      >
        新增紀錄
      </Button>
    </div>
  );
};

const EditButton = ({
  data,
  schema,
  collection,
  collectionId,
  targetId,
  keyName,
  popup,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='text-zinc-400 hover:text-zinc-900 w-6'>
      {open && (
        <Popup
          isEdit
          open={open}
          toClose={() => {
            setOpen(false);
          }}
          data={data}
          schema={schema}
          collection={collection}
          collectionId={collectionId}
          targetId={targetId}
          keyName={keyName}
        />
      )}

      <Edit
        onClick={() => {
          if (popup === false) {
            redirect(`/${keyName}/${data._id}`, true);
          } else {
            setOpen(true);
          }
        }}
      />
    </div>
  );
};

const DeleteButton = ({
  data,
  schema,
  collection,
  collectionId,
  targetId,
  keyName,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className='text-zinc-400 hover:text-zinc-900 w-6'>
      {open && (
        <Popup
          isDelete
          open={open}
          toClose={() => setOpen(false)}
          data={data}
          schema={schema}
          collection={collection}
          collectionId={collectionId}
          targetId={targetId}
          keyName={keyName}
        />
      )}

      <Delete
        onClick={() => {
          setOpen(true);
        }}
      />
    </div>
  );
};

const isNumber = (v) => {
  return typeof value === 'number' || !isNaN(Number(v));
};

function descendingComparator(a, b, orderBy) {
  let _a = a[orderBy];
  let _b = b[orderBy];
  if (isNumber(_a) && isNumber(_b)) {
    _a = toNumber(_a);
    _b = toNumber(_b);
  }
  if (_b < _a) {
    return -1;
  }
  if (_b > _a) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort, schema } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding='checkbox' />
        {map(schema, (headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell padding='checkbox' />
      </TableRow>
    </TableHead>
  );
}
export default function EnhancedTable({
  rows,
  setting,
  schema,
  collectionId,
  targetId,
}) {
  const {
    collection,
    keyName,
    popup,
    editable = true,
    deletable = true,
    addable = true,
    copyable = false,
  } = setting;
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(setting.orderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [rows, order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <div className='flex flex-col'>
        <div className='flex justify-end mb-4'>
          {addable && (
            <AddButton
              collection={collection}
              collectionId={collectionId}
              targetId={targetId}
              schema={schema}
              keyName={keyName}
              popup={popup}
            />
          )}
        </div>
        <Paper sx={{ width: '100%', borderRadius: 0, overflowX: 'auto' }}>
          <div class='w-max min-w-full'>
            <TableContainer>
              <Table
                stickyHeader
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  schema={schema}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role='checkbox'
                        tabIndex={-1}
                        key={row.name}
                        sx={{ cursor: 'pointer' }}
                      >
                        <StyledTableCell padding='checkbox'>
                          <div className='flex gap-1'>
                            {/* <To
														url={`/${targetId ? collection : keyName}/${
															row._id
														}`}
														toNew
													>
														<div className='text-zinc-400 hover:text-zinc-900'>
															<Search />
														</div>
													</To> */}
                            {editable === true && (
                              <EditButton
                                data={row}
                                schema={schema}
                                collection={collection}
                                collectionId={collectionId}
                                targetId={targetId}
                                keyName={keyName}
                                popup={popup}
                              />
                            )}
                            {deletable === true && (
                              <DeleteButton
                                data={row}
                                schema={schema}
                                collection={collection}
                                collectionId={collectionId}
                                targetId={targetId}
                                keyName={keyName}
                                popup={popup}
                              />
                            )}
                          </div>
                        </StyledTableCell>
                        {map(schema, (header) => {
                          return (
                            <StyledTableCell key={header.id}>
                              {formatData({
                                text: row[header.id],
                                key: header.id,
                                type: header.type,
                                show_dollar: header?.show_dollar,
                              })}
                            </StyledTableCell>
                          );
                        })}

                        <StyledTableCell>
                          {copyable && (
                            <To
                              url={`/${targetId ? collection : keyName}/copy/${
                                row._id
                              }`}
                              toNew
                            >
                              <div className='text-zinc-400 hover:text-zinc-900'>
                                <ContentCopy />
                              </div>
                            </To>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <StyledTableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Paper>
        <div className='bg-white'>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </Box>
  );
}
