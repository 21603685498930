import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalStore } from '../../../lib/store';
import List from 'components/common/lists';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/zh-hk';
import dayjs from 'dayjs';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const ListTable = ({ start_date, end_date, select_month, titleIcon }) => {
  return (
    <List
      url={`/contract_vehicle/reports?start_date=${dayjs(select_month)
        .set('date', 1)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)}&end_date=${dayjs(select_month)
        .endOf('month')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)}&select_month=${dayjs(select_month)}`}
      rowUrl={(id) => `/contract/${id}`}
      prefix='contract_vehicle'
      downloadable={false}
      pageName='listing'
      titleIcon={titleIcon}
      // deleteRow={(row) => setDialogOpen(row)}
      defaultSort={{ field: 'contract_number', order: 'asc' }}
    />
  );
};

export default function ContractVehicle({ prefix, title, titleIcon }) {
  const [buttonDisable, setButtonDisable] = useState(false);
  const startOfMonth = new Date();
  startOfMonth.setDate(1);
  const lastDayOfMonth = new Date(
    startOfMonth.getFullYear(),
    startOfMonth.getMonth() + 1,
    0
  );
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initPath = location.pathname;

  // if (query.get('start_date') === null) {
  //   query.set('start_date', dayjs(startOfMonth).valueOf().toString());
  //   navigate(`${initPath}?${query.toString()}`, { replace: true });
  // }
  // if (query.get('end_date') === null) {
  //   query.set('end_date', dayjs(lastDayOfMonth).valueOf().toString());
  //   navigate(`${initPath}?${query.toString()}`, { replace: true });
  // }
  if (query.get('select_month') === null) {
    query.set(
      'select_month',
      dayjs()
        .set('month', dayjs().month() - 1)
        .valueOf()
        .toString()
    );
    navigate(`${initPath}?${query.toString()}`, { replace: true });
  }

  const start_date = parseInt(query.get('start_date')) || startOfMonth;
  const end_date = parseInt(query.get('end_date')) || lastDayOfMonth;
  const select_month = parseInt(query.get('select_month')) || startOfMonth;

  const { setDefaultTopnav } = useGlobalStore();
  useEffect(() => {
    setDefaultTopnav({
      url: `/contract_vehicle`,
      title,
      // canSave,
      // save,
      isExpensesBatch: false,
      titleIcon,
    });
  }, [prefix, title, titleIcon]);

  const routeTo = (query) => {
    const currentPath = location.pathname;
    const currentQuery = new URLSearchParams(location.search);
    Object.entries(query).forEach(([key, value]) => {
      currentQuery.set(key, value);
    });

    navigate(`${currentPath}?${currentQuery.toString()}`, { replace: true });
    // window.location.reload();
  };

  const updateButtonDisable = (key, value) => {
    if (dayjs(value).isValid()) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };

  const setCustomQuery = (key, value) => {
    query.set(key, value);
  };

  const sumbit = () => {
    navigate(`${initPath}?${query.toString()}`, { replace: true });
    window.location.reload();
  };

  return (
    <>
      {/* <div className='w-full h-full flex flex-row items-center -justify-center pt-4'>
        <div className='-text-center text-xl mb-2 mx-2'>Start Date:</div>
        <FormControl>
          <DatePicker
            clearable
            slotProps={{
              actionBar: { actions: ['clear', 'today'] },
            }}
            format='YYYY-MM-DD'
            value={dayjs(start_date) || dayjs()}
            onChange={(v, context) => {
              if (v && !context.validationError) {
                const date = dayjs(v);
                // routeTo({
                //   start_date: date.isValid() ? date.valueOf().toString() : '',
                // });
                setCustomQuery(
                  'start_date',
                  date.isValid() ? date.valueOf().toString() : ''
                );
              }
              updateButtonDisable('start_date', v);
            }}
          />
        </FormControl>
      </div>
      <div className='w-full h-full flex flex-row items-center -justify-center pt-4 pb-4'>
        <div className='-text-center text-xl mb-2 mx-2'>End Date:</div>
        <FormControl>
          <DatePicker
            clearable
            slotProps={{
              actionBar: { actions: ['clear', 'today'] },
            }}
            format='YYYY-MM-DD'
            value={dayjs(end_date) || dayjs()}
            onChange={(v, context) => {
              if (v && !context.validationError) {
                const date = dayjs(v);
                setCustomQuery(
                  'end_date',
                  date.isValid() ? date.valueOf().toString() : ''
                );
                // routeTo({
                //   end_date: date.isValid() ? date.valueOf().toString() : '',
                // });
              }
              updateButtonDisable('end_date', v);
            }}
          />
        </FormControl>
      </div> */}

      <div className='w-full h-full flex flex-row items-center -justify-center pt-4'>
        <div className='-text-center text-xl mb-2 mx-2'>月份:</div>
        <FormControl>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='zh-hk'
            localeText={{
              // cancelButtonLabel: 'Exit',
              clearButtonLabel: '清除',
              // okButtonLabel: 'Save',
              // todayButtonLabel: 'Set today',
            }}
          >
            <DatePicker
              clearable
              slotProps={{
                actionBar: { actions: ['clear'] },
              }}
              variant='inline'
              openTo='month'
              views={['year', 'month']}
              format='YYYY-MM'
              minDate={dayjs('2023-06-01')}
              maxDate={dayjs().set('month', dayjs().month() - 1)}
              value={
                dayjs(select_month) || dayjs().set('month', dayjs().month() - 1)
              }
              onChange={(v, context) => {
                if (v && !context.validationError) {
                  const date = dayjs(v);
                  // routeTo({
                  //   start_date: date.isValid() ? date.valueOf().toString() : '',
                  // });
                  setCustomQuery(
                    'select_month',
                    date.isValid() ? date.valueOf().toString() : ''
                  );
                }
                updateButtonDisable('select_month', v);
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
      {/* <To url={`/contract_vehicle/reports`}> */}
      <div className='m-2'>
        <Button onClick={sumbit} variant='contained' disabled={buttonDisable}>
          確定
        </Button>
      </div>
      {/* </To> */}

      {start_date && end_date ? (
        <>
          <ListTable
            start_date={start_date}
            end_date={end_date}
            select_month={select_month}
            titleIcon={titleIcon}
          />
        </>
      ) : // <List
      //   url={'/contract_vehicle/reports'}
      //   rowUrl={(id) => `/contract/${id}`}
      //   prefix='contract_vehicle'
      //   downloadable={false}
      //   pageName='listing'
      //   titleIcon={titleIcon}
      //   onChangeData={data}
      //   // deleteRow={(row) => setDialogOpen(row)}
      // />
      null}
    </>
  );
}
