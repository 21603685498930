import React, { useContext, useMemo } from 'react';

import Table from '../../../../components/common/table';

const fieldsToDisplay = {
	chassis_number: 'Chassis Number',
	effective_date: 'Installation Date',
	end_date: 'Remove Date',
};

function Vehicles({ context }) {
	const { data } = useContext(context);
	const rows = useMemo(() => {
		if (data && data.vehicles) {
			return data.vehicles.map((item, index) => {
				return {
					...item?.vehicle,
					...item,
					id: index,
					_id: item?.vehicle?._id,
				};
			});
		}
		return [];
	}, [data]);

	return (
		<Table
			data={rows}
			fieldsToDisplay={fieldsToDisplay}
			editEndPoint='/vehicle'
		/>
	);
}

export default Vehicles;
