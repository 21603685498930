import React, { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { get, post } from '../../lib/request';
import { useGlobalStore } from '../../lib/store';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { pull, isEmpty, reduce } from 'lodash';
import { ChevronRight, DriveFolderUploadOutlined } from '@mui/icons-material';
import To from '../../components/common/to';
import { redirect } from '../../lib/config';
import { DatePicker } from '@mui/x-date-pickers';

const headers = [
  {
    title: 'Reg Mark',
    key: 'reg_mark',
  },
  {
    title: 'Chassis Number',
    key: 'chassis_number',
  },
];

const filterVehicles = (vehicles, driverLicense) => {
  return reduce(
    vehicles,
    (prev, vehicle) => {
      if (vehicle.vehicle_class !== 'S.P.V.') return prev;
      if (
        vehicle.license &&
        driverLicense &&
        parseInt(driverLicense) >= parseInt(vehicle.license)
      ) {
        prev.push(vehicle);
      }
      return prev;
    },
    []
  );
};

function ApplyForm({ prefix, title, titleIcon }) {
  const { setDefaultTopnav } = useGlobalStore();
  const [applicationDate, setApplicationDate] = useState(dayjs());
  const [company, setCompany] = useState('');
  const [driver, setDriver] = useState('');
  const [companies, setCompanies] = useState([]);
  const [drivers, setDrivers] = useState([]);

  const [selected, setSelected] = useState([]);

  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      titleIcon,
    });
  }, [prefix, title, titleIcon]);
  useEffect(() => {
    get({ url: '/company/all-company-select' }).then(({ data }) => {
      setCompanies(data);
    });
    get({ url: '/driver/lists' }).then(({ data }) => {
      setDrivers(data.data.records);
    });
  }, []);

  useEffect(() => {
    if (company && driver) {
      get({ url: `/driver/get/${driver}` }).then((driverData) => {
        const driverLicense = driverData?.data?.record?.license || '';
        get({
          url: `/driver_permit/get-vehicles?company=${company}&driver=${driver}`,
        }).then(({ data }) => {
          const vehicles = filterVehicles(data, driverLicense);
          setVehicles(vehicles);
          setSelected(() => {
            return vehicles.map((v) => {
              return v._id;
            });
          });
        });
      });
    }
  }, [company, driver]);

  const selectAll = () => {
    setSelected(vehicles.map((v, i) => i));
  };

  const apply = useCallback(async () => {
    post({
      url: '/driver_permit/new',
      body: {
        company,
        driver,
        vehicles: selected,
        vehicle_count: selected.length,
        application_date: applicationDate,
      },
    }).then(({ data }) => {
      redirect(`/driver_permit/${data._id}?alert=Success!`);
    });
  }, [selected, company, driver, applicationDate]);

  return (
    <div>
      <div className='flex flex-col gap-4'>
        <FormControl fullWidth>
          <InputLabel id='driver'>司機</InputLabel>
          <Select
            labelId='driver'
            value={driver}
            label='Driver'
            onChange={(e) => {
              setDriver(e.target.value);
            }}
          >
            {drivers.map(({ _id, label, name, license, hkid }) => {
              return (
                <MenuItem value={_id} key={_id}>
                  {name} {hkid ? ` | HKID : ${hkid}` : ''}
                  {license ? ` | 駕駛執照 : ${license}` : ''}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='company'>公司簡稱</InputLabel>
          <Select
            labelId='company'
            value={company}
            label='Company'
            onChange={(e) => {
              setCompany(e.target.value);
            }}
          >
            {companies.map(({ _id, label }) => {
              return (
                <MenuItem value={_id} key={_id}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <DatePicker
            clearable
            slotProps={{
              actionBar: { actions: ['clear', 'today'] },
            }}
            format='YYYY-MM-DD'
            value={applicationDate}
            onChange={(v) => {
              if (v) {
                const date = dayjs(v);
                if (date.isValid()) {
                  setApplicationDate(date);
                }
              } else if (v === null) {
                setApplicationDate(null);
              }
            }}
          />
        </FormControl>
        <List
          selected={selected}
          setSelected={setSelected}
          selectAll={selectAll}
          data={vehicles}
          headers={headers}
        />
        {selected.length > 0 && vehicles.length > 0 && driver && (
          <div className='flex justify-end'>
            <Button onClick={apply} variant='outlined' size='large'>
              申請
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

const List = ({ selected, setSelected, data, headers }) => {
  return (
    <div className='p-4 shadow-md bg-white flex flex-col gap-2'>
      <div className='flex flex-col'>
        <div className='flex gap-4 items-center'>
          <div className='w-8'>
            <Checkbox
              checked={selected.length === data.length}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setSelected(data.map((v) => v._id));
                } else {
                  setSelected([]);
                }
              }}
            />
          </div>
          <div className='w-8 text-center'>No.</div>
          <div className='w-24'>車牌</div>
          <div className='w-40'>底盤號碼</div>
          <div className='w-40'>駕駛牌照</div>
          <div className='w-40'>許可車輛總重</div>
        </div>
      </div>
      <div className=''>
        {data &&
          data.length > 0 &&
          data.map((v, index) => {
            return (
              <div
                className='flex gap-4 items-center hover:bg-zinc-100'
                key={v._id}
              >
                <div className='w-8'>
                  <Checkbox
                    checked={selected.includes(v._id)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        setSelected([...selected, v._id]);
                      } else {
                        const arr = [...selected];
                        setSelected(pull(arr, v._id));
                      }
                    }}
                  />
                </div>
                <div className='w-8 text-center'>{index + 1}</div>
                <div className='w-24'>{v?.current_reg_mark?.reg_mark}</div>
                <div className='w-40'>{v.chassis_number}</div>
                <div className='w-40'>{v.license}</div>
                <div className='w-40'>{v.gross_vehicle_weight}</div>
                <div className='grow flex justify-end pr-2'>
                  <To url={`/vehicle/${v._id}`} toNew>
                    <ChevronRight />
                  </To>
                </div>
              </div>
            );
          })}
      </div>
      {selected.length ? (
        <div className=''>{selected.length} vehicles selected.</div>
      ) : null}
    </div>
  );
};

export default ApplyForm;
