import React, { useState, useEffect } from 'react';
import { IconButton, Button } from '@mui/material';
import {
  Add,
  Save,
  NavigateBefore,
  Upload,
  ContentCopy,
  Settings,
} from '@mui/icons-material';
import { JsonViewer } from '@textea/json-viewer';
import { Section } from '../../components/common/tabs/section-block';
import { get, useGet } from '../../lib/request';
import { useGlobalStore } from '../../lib/store';
import To from '../../components/common/to';

const dbMap = {
  autotolls: 'autotoll',
  companies: 'company',
  contract_deducts: 'contract_deduct',
  contracts: 'contract',
  driver_permits: 'driver_permit',
  drivers: 'driver',
  expenses: 'expenses',
  expenses_autotolls: 'expenses_autotoll',
  expenses_batches: 'expenses_batch',
  expenses_fuels: 'expenses_fuel',
  files: 'file',
  fuels: 'fuel',
  gps: 'gps',
  insurances: 'insurance',
  inventories: 'inventory',
  inventory_transactions: 'inventory_transaction',
  licenses: 'license',
  logs: 'log',
  permit_areas: 'permit_area',
  reg_marks: 'reg_mark',
  users: 'user',
  vehicles: 'vehicle',
  warehouses: 'warehouse',
};

function Detail({ _id }) {
  const { setTopnav } = useGlobalStore();
  const { result = null, getRequest } = useGet();

  useEffect(() => {
    setTopnav(<TopNav />);
  }, []);

  const data = result?.is200 ? result?.data?.record : null;

  // useEffect(() => {
  //   if (data?.created_by?._id) {
  //     get({
  //       url: `/user/get/${data.created_by._id}`,
  //     }).then(({ data }) => {
  //       const { display_name, username } = data?.record;
  //       setCreatedBy(display_name || username);
  //     });
  //   }
  // }, [data]);

  useEffect(() => {
    if (_id) {
      getRequest({ url: `/log/get/${_id}` });
    }
  }, [_id]);

  if (!data) return null;

  var showDifference = null;
  if (data?.old_data && data?.old_data) {
    const get_difference = getDifference(
      JSON.parse(data?.old_data),
      JSON.parse(data?.data)
    );
    showDifference = clearEmpties(get_difference);
  }

  return (
    <div className='flex gap-4 text-xl'>
      <Section>
        <div className='flex'>
          <div className='w-40'>Table</div>
          <div className='capitalize'>
            <To url={`/${dbMap[`${data?.collection_name}`]}`} toNew>
              {data?.collection_name}
            </To>
          </div>
        </div>
        <div className='flex'>
          <div className='w-40'>ID</div>
          <div className='capitalize'>
            <To
              url={`/${dbMap[`${data?.collection_name}`]}/${data.doc_id}`}
              toNew
            >
              {data?.doc_id}
            </To>
          </div>
        </div>
        <div className='flex'>
          <div className='w-40'>Creator</div>
          <div className='_capitalize'>
            {data?.auto ? (
              'SYSTEM'
            ) : data?.created_by?.display_name ? (
              <To url={`/user/${data?.created_by?._id}`} toNew>
                {data?.created_by?.username} ({data?.created_by?.display_name})
              </To>
            ) : data?.created_by?.username ? (
              <To url={`/user/${data?.created_by?._id}`} toNew>
                {data?.created_by?.username}
              </To>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='flex'>
          <div className='w-40'>Type</div>
          <div className='capitalize'>{data?.action}</div>
        </div>
        {/* {data?.difference && ( */}
        {showDifference && (
          <div className='flex'>
            <div className='w-40'>Difference</div>
            <div className='text-sm grow p-2 border bg-zinc-50'>
              {/* <JsonViewer value={JSON.parse(data?.difference)} /> */}
              <JsonViewer value={showDifference} />
            </div>
          </div>
        )}
        <div className='flex'>
          <div className='w-40'>New Data</div>
          <div className='text-sm grow p-2 border bg-zinc-50'>
            <JsonViewer value={data?.data ? JSON.parse(data?.data) : {}} />
          </div>
        </div>
        {data?.old_data && (
          <div className='flex'>
            <div className='w-40'>Old Data</div>
            <div className='text-sm grow p-2 border bg-zinc-50'>
              <JsonViewer
                value={data?.old_data ? JSON.parse(data?.old_data) : {}}
              />
            </div>
          </div>
        )}
      </Section>
    </div>
  );
}

const getDifference = (obj1, obj2) => {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = getDifference(obj1[key], obj2[key]);

        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
};

const clearEmpties = (o) => {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue; // If null or not an object, skip to the next iteration
    }

    // The property is an object
    clearEmpties(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
  return o;
};

const TopNav = () => {
  return (
    <div className='flex items-center'>
      <div className='pl-2 pr-2'>
        {/* <To url={'/log'}>
          <IconButton>
            <NavigateBefore />
          </IconButton>
        </To> */}
        <Settings />
      </div>
      <div className='text-xl capitalize'>Log</div>
    </div>
  );
};

export default Detail;
