import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find, forEach, isEmpty, map, reduce, get as _get } from 'lodash';

import ImportCsv from '../../../components/common/importcsv';
import { useGlobalStore } from '../../../lib/store';
import { get, post } from '../../../lib/request';
import { redirect, toISOString } from '../../../lib/config';
import { usePermissions } from 'lib/permissions';

const _schema = {
  vehicle: {
    type: 'text',
    title: 'Vehicle Number',
    is_required: true,
    tooltip: {},
  },
  vehicle_effective_date: {
    title: 'Vehicle Effective Date',
    regExp: /^\d{4}-\d{2}-\d{2}$/, // 2023-04-05
    tooltip: { format: 'YYYY-MM-DD', example: '2023-04-05' },
  },
  vehicle_end_date: {
    title: 'Vehicle End Date',
    regExp: /^\d{4}-\d{2}-\d{2}$/, // 2023-04-05
    tooltip: { format: 'YYYY-MM-DD', example: '2023-04-05' },
  },
  reg_mark: {
    type: 'text',
    title: 'Reg Mark',
  },
  contract_deduct: {
    title: 'Contract Deduct',
  },
};

const Upload = ({ prefix, title, fieldsToDisplay }) => {
  const [error, setError] = useState({});
  const [value, setValue] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [regMarks, setRegMarks] = useState([]);
  const [schema, setSchema] = useState(null);

  const { setDefaultTopnav } = useGlobalStore();
  const { isWritable } = usePermissions();

  const customError = useCallback(
    ({ msg, field, value, currentRow }) => {
      if (field === 'vehicle' && !find(vehicles, { label: value })) {
        msg = 'Vehicle does not exist';
      }
      return msg;
    },
    [vehicles]
  );

  const customWarning = useCallback(({ msg, field, value, currentRow }) => {
    return msg;
  }, []);

  const writable = isWritable({ collection: prefix });

  const canSave = useMemo(() => {
    return !isEmpty(value) && writable;
  }, [value, writable]);

  useEffect(() => {
    if (isEmpty(vehicles) && !isEmpty(value) && prefix) {
      get({ url: `/vehicle/all-vehicle-select?statusless=true` }).then(
        ({ is200, data }) => {
          if (is200) {
            setVehicles(data);
          }
        }
      );
      get({ url: `/reg_mark/all-reg_mark-select?statusless=true` }).then(
        ({ is200, data }) => {
          if (is200) {
            setRegMarks(data);
          }
        }
      );
      get({ url: `/contract/all-contract-select?statusless=true` }).then(
        ({ is200, data }) => {
          if (is200) {
            setContracts(data);
          }
        }
      );
    }
  }, [value, vehicles, prefix]);

  useEffect(() => {
    get({ url: `/${prefix}/schema` }).then(({ is200, data }) => {
      if (is200) {
        const { schema } = data;
        if (schema) {
          setSchema({
            ...schema,
            ..._schema,
          });
        }
      }
    });
  }, [prefix]);

  const save = useCallback(async () => {
    const body = map(value, (row) => {
      const vehicle = find(vehicles, { label: row.vehicle })?._id;
      const contract = find(contracts, { label: row.contract_deduct })?._id;
      const reg_mark = find(regMarks, { label: row.reg_mark })?._id;
      return {
        ...row,
        vehicle,
        contract,
        reg_mark,
      };
    });

    console.log({ body });

    const result = await post({
      url: `/${prefix}/import`,
      isImport: true,
      body,
    });

    if (result && result.is200 && result.data) {
      redirect(
        `/${prefix}?alert=${encodeURIComponent(
          `${result.data.success} record(s) Created/Updated!`
        )}`
      );
    }
  }, [prefix, value, vehicles, contracts, regMarks]);

  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      canSave,
      save,
    });
  }, [prefix, canSave, save]);

  if (isEmpty(schema)) return null;
  return (
    <ImportCsv
      fieldsToDisplay={[
        'vehicle',
        'vehicle_effective_date',
        'vehicle_end_date',
        'reg_mark',
        'contract_deduct',
        ...fieldsToDisplay,
      ]}
      schema={schema}
      setError={setError}
      setValue={setValue}
      value={value}
      customError={customError}
      customWarning={customWarning}
    />
  );
};
export default Upload;
