import React, { useContext } from 'react';
import { isEmpty } from 'lodash';

import Switcher from '../../../../components/common/input/switcher';
import Status from '../../../../components/status';
import {
	ContractSection,
	VehicleSection,
} from '../../../../components/common/tabs/section-block';
import MetaData from '../../../../components/meta-data';

function ProfileGeneral({ context }) {
	const {
		mode,
		prefix,
		data,
		schema,
		setInputs,
		saveBtnClicked,
		isEdit,
		pushItemToField,
		fieldsToDisplay,
		relationSeciton,
	} = useContext(context);

	if (isEmpty(schema)) return null;
	return (
		<div>
			<div className='flex gap-4 py-10' role='tabpanel'>
				<div className='w-full flex flex-col gap-4 shrink-0'>
					{/* <Status data={data} setInputs={setInputs} /> */}
					{relationSeciton?.contract ? (
						<ContractSection
							current_contract={data.current_contract}
							setInputs={setInputs}
							saveBtnClicked={saveBtnClicked}
							exclude={relationSeciton.contract?.exclude}
							isEdit={isEdit}
							readonly={
								relationSeciton.contract?.readonly
									? data?.current_contract?.contract?.contract_number
									: false
							}
						/>
					) : null}
					{relationSeciton?.vehicle ? (
						<VehicleSection
							current_vehicle={data.current_vehicle}
							setInputs={setInputs}
							saveBtnClicked={saveBtnClicked}
							exclude={relationSeciton.vehicle?.exclude}
							isEdit={isEdit}
							readonly={
								relationSeciton.vehicle?.readonly
									? data?.current_vehicle?.vehicle?.chassis_number
									: false
							}
						/>
					) : null}
				</div>
			</div>
			<MetaData data={data} />
		</div>
	);
}

export default ProfileGeneral;
