import Box from '@mui/material/Box';

import { useGlobalStore } from '../../lib/store';

const Username = () => {
	const { username } = useGlobalStore();

	return (
		<Box className='h-[48px] text-lg pl-4 flex items-center'>
			用戶: {username}
		</Box>
	);
};

export default Username;
