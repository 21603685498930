import React, { isValidElement } from 'react';
import { IconButton, Button } from '@mui/material';
import { useGlobalStore } from '../../lib/store';
import {
  Add,
  Save,
  NavigateBefore,
  Upload,
  Assignment,
  ContentCopy,
} from '@mui/icons-material';

import To from '../common/to';
import { isFunction } from 'lodash';

function TopNav() {
  const { topnav } = useGlobalStore();
  return (
    <div className='fixed pl-[280px] z-50 h-14 top-0 left-0 w-full bg-zinc-50 flex items-center border-b'>
      <div className='w-full px-5'>
        {topnav ? (
          isValidElement(topnav) ? (
            topnav
          ) : (
            <DefaultTopNav {...topnav} />
          )
        ) : null}
      </div>
    </div>
  );
}

const DefaultTopNav = ({
  url,
  title,
  save,
  canSave,
  upload,
  copy,
  newEntry,
  mode,
  isExpenses,
  isExpensesBatch,
  titleIcon,
}) => {
  const isCopy = mode === 'copy';

  return (
    <div className='flex items-center'>
      <div className='pl-2 pr-2'>{titleIcon}</div>
      {/* {url && (
        <div className='pr-2'>
          <To url={url}>
            <IconButton>
              <NavigateBefore />
            </IconButton>
          </To>
        </div>
      )} */}
      <div className='text-xl capitalize'>
        {isCopy ? 'Renew ' : null}
        {title}
      </div>
      <div className='grow flex justify-end gap-4'>
        {upload ? (
          <div>
            <To url={upload}>
              <Button variant='contained' startIcon={<Upload />}>
                Import CSV
              </Button>
            </To>
          </div>
        ) : null}
        {isExpenses ? (
          <div>
            <To url={'/expenses_batch/import'} className='pr-2'>
              <Button variant='contained' startIcon={<Upload />}>
                滙入
              </Button>
            </To>
            <To url={'/expenses_batch/batches'}>
              <Button variant='contained' startIcon={<Assignment />}>
                滙入紀錄
              </Button>
            </To>
          </div>
        ) : null}
        {isExpensesBatch ? (
          <div>
            <To url={'/expenses_batch/import'} className='pr-2'>
              <Button variant='contained' startIcon={<Upload />}>
                滙入
              </Button>
            </To>
          </div>
        ) : null}
        {/* {copy ? (
					<div>
						<To url={copy}>
							<Button variant='contained' startIcon={<ContentCopy />}>
								Renew
							</Button>
						</To>
					</div>
				) : null} */}
        {newEntry ? (
          <div>
            <To url={newEntry}>
              <Button variant='contained' startIcon={<Add />}>
                新記錄
              </Button>
            </To>
          </div>
        ) : null}
        {isFunction(save) ? (
          <div>
            <IconButton
              aria-label='save'
              onClick={() => save()}
              disabled={!canSave}
            >
              <Save color={canSave ? 'primary' : ''} />
            </IconButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TopNav;
