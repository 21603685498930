import React, { useContext } from 'react';
import { isEmpty } from 'lodash';

import Switcher from '../../../../components/common/input/switcher';
import Status from '../../../../components/status';
import dayjs from 'dayjs';
import {
	Section,
	RelationSection,
} from '../../../../components/common/tabs/section-block';
import MetaData from '../../../../components/meta-data';

function ProfileGeneral({ context }) {
	const {
		mode,
		prefix,
		data,
		schema,
		setInputs,
		saveBtnClicked,
		pushItemToField,
		fieldsToDisplay,
	} = useContext(context);

	if (isEmpty(schema)) return null;
	return (
		<div className='flex gap-4 py-10'>
			<div className='flex flex-col gap-4  w-full' role='tabpanel'>
				<Section>
					{fieldsToDisplay.map((field) => {
						if (field !== 'status') {
							return (
								<Switcher
									key={field}
									schema={{
										...schema[field],
										editable: mode === 'edit' ? schema[field]?.editable : true,
									}}
									value={data[field]}
									setInputs={setInputs}
									saveBtnClicked={saveBtnClicked}
									field={field}
									pushItemToField={pushItemToField}
									suggestEndpoint={`/${prefix}/column/${field}`}
								/>
							);
						} else {
							return null;
						}
					})}
				</Section>
				<MetaData data={data} />
			</div>
			<div className='w-6/12 xl:w-5/12 2xl:w-4/12 flex flex-col gap-4 shrink-0'>
				<Status data={data} setInputs={setInputs} />
				{/* <CompanySection
					current_company={data?.current_company}
					setInputs={setInputs}
					saveBtnClicked={saveBtnClicked}
				/>
				<ContractSection
					current_contract={data?.current_contract}
					setInputs={setInputs}
					saveBtnClicked={saveBtnClicked}
				/>
				<ContractDeductSection
					current_contract_deduct={data?.current_contract_deduct}
					setInputs={setInputs}
					saveBtnClicked={saveBtnClicked}
				/>
				<RegMarkSection current_reg_mark={data?.current_reg_mark} /> */}
			</div>
		</div>
	);
}

const CompanySection = ({
	current_company = {},
	setInputs,
	saveBtnClicked,
}) => {
	if (current_company && current_company?.company?._id) {
		return (
			<div>
				<RelationSection
					title='公司'
					isEdit={current_company?.company?._id}
					url={`/company/${current_company?.company?._id || ''}`}
				>
					<Switcher
						value={
							current_company?.company?._id && {
								_id: current_company?.company?._id,
								label: current_company?.company?.name_tc,
							}
						}
						field={'input__company_id'}
						setInputs={setInputs}
						suggestEndpoint={`/company/all-company-select?filters=_id^!=${
							current_company?.company?._id || ''
						}`}
						saveBtnClicked={saveBtnClicked}
						schema={{
							type: 'readonly',
							title: '公司簡稱',
							select: true,
						}}
					/>
					<Switcher
						value={current_company?.effective_date}
						field={'input__company_effective_date'}
						setInputs={setInputs}
						saveBtnClicked={saveBtnClicked}
						schema={{
							type: 'readonly',
							title: '擁有日期',
						}}
					/>
					<Switcher
						value={current_company?.end_date}
						field={'input__company_end_date'}
						setInputs={setInputs}
						saveBtnClicked={saveBtnClicked}
						schema={{
							type: 'readonly',
							title: '售出日期',
						}}
					/>
					<Switcher
						value={current_company?.value}
						field={'input__company_value'}
						setInputs={setInputs}
						saveBtnClicked={saveBtnClicked}
						schema={{
							type: 'readonly',
							title: '價值',
						}}
					/>
				</RelationSection>
			</div>
		);
	}
};

const ContractSection = ({
	current_contract = {},
	setInputs,
	saveBtnClicked,
}) => {
	if (current_contract && current_contract?.contract?._id) {
		return (
			<div>
				<RelationSection
					title='合約'
					isEdit={current_contract?.contract?._id}
					url={`/contract/${current_contract?.contract?._id || ''}`}
				>
					<Switcher
						value={
							current_contract?.contract?._id
								? {
										_id: current_contract?.contract?._id,
										label: current_contract?.contract?.contract_number,
								  }
								: undefined
						}
						field={'input__contract_id'}
						setInputs={setInputs}
						suggestEndpoint={`/contract/all-contract-select?filters=_id^!=${
							current_contract?.contract?._id || ''
						}`}
						saveBtnClicked={saveBtnClicked}
						schema={{
							title: '合約編號',
							select: true,
							readonly: true,
							type: 'readonly',
						}}
					/>
					<Switcher
						value={current_contract?.effective_date}
						field={'input__contract_effective_date'}
						setInputs={setInputs}
						saveBtnClicked={saveBtnClicked}
						schema={{
							title: '生效日期',
							type: 'readonly',
						}}
					/>
					<Switcher
						value={current_contract?.end_date}
						field={'input__contract_end_date'}
						setInputs={setInputs}
						saveBtnClicked={saveBtnClicked}
						schema={{
							type: 'readonly',
							title: '售出日期',
						}}
					/>
				</RelationSection>
			</div>
		);
	}
};

const ContractDeductSection = ({
	current_contract_deduct = {},
	setInputs,
	saveBtnClicked,
}) => {
	if (
		current_contract_deduct &&
		current_contract_deduct?.contract_deduct?._id
	) {
		return (
			<div>
				<RelationSection
					title='扣數合約'
					isEdit={current_contract_deduct?.contract_deduct?._id}
					url={`/contract_deduct/${
						current_contract_deduct?.contract_deduct?._id || ''
					}`}
				>
					<Switcher
						value={
							current_contract_deduct?.contract_deduct?._id
								? {
										_id: current_contract_deduct?.contract_deduct?._id,
										label:
											current_contract_deduct?.contract_deduct?.contract_number,
								  }
								: undefined
						}
						field={'input__contract_deduct_id'}
						setInputs={setInputs}
						suggestEndpoint={`/contract/all-contract-select`}
						saveBtnClicked={saveBtnClicked}
						schema={{
							type: 'readonly',
							title: '合約編號',
							select: true,
						}}
					/>
					<Switcher
						value={current_contract_deduct?.effective_date}
						field={'input__contract_deduct_effective_date'}
						setInputs={setInputs}
						saveBtnClicked={saveBtnClicked}
						schema={{
							type: 'readonly',
							title: '生效日期',
						}}
					/>
					<Switcher
						value={current_contract_deduct?.end_date}
						field={'input__contract_deduct_end_date'}
						setInputs={setInputs}
						saveBtnClicked={saveBtnClicked}
						schema={{
							type: 'readonly',
							title: '售出日期',
						}}
					/>
				</RelationSection>
			</div>
		);
	}
};

const RegMarkSection = ({ current_reg_mark = {} }) => {
	if (current_reg_mark && Object.keys(current_reg_mark).length > 0) {
		return (
			<div>
				<RelationSection
					title='車牌'
					isEdit={current_reg_mark?.reg_mark?._id}
					url={`/reg_mark/${current_reg_mark?.reg_mark?._id || ''}`}
				>
					<div className='flex flex-col gap-4'>
						<div className='flex gap-4'>
							<div className='2xl:w-52 xl:w-48 w-40 text-lg bold mt-1 shrink-0'>
								車牌號碼
							</div>
							<div className='flex items-center text-lg'>
								{current_reg_mark?.reg_mark?.reg_mark}
							</div>
						</div>
						<div className='flex gap-4'>
							<div className='2xl:w-52 xl:w-48 w-40 text-lg bold mt-1 shrink-0'>
								生效日期
							</div>
							<div className='flex items-center text-lg'>
								{current_reg_mark?.effective_date
									? dayjs(current_reg_mark?.effective_date).format('YYYY-MM-DD')
									: ''}
							</div>
						</div>
						<div className='flex gap-4'>
							<div className='2xl:w-52 xl:w-48 w-40 text-lg bold mt-1 shrink-0'>
								結束日期
							</div>
							<div className='flex items-center text-lg'>
								{current_reg_mark?.end_date
									? dayjs(current_reg_mark?.end_date).format('YYYY-MM-DD')
									: ''}
							</div>
						</div>
					</div>
				</RelationSection>
			</div>
		);
	}
};

export default ProfileGeneral;
