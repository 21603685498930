import React, { useContext, useMemo } from 'react';

import Table from '../../../../components/common/table';

const fieldsToDisplay = {
  chassis_number: '底盤號碼',
  current_reg_mark: '車牌號碼',
  effective_date: '生效日期',
  end_date: '結束日期',
};

function Vehicles({ context }) {
  const { data } = useContext(context);
  const rows = useMemo(() => {
    if (data && data.vehicles) {
      return data.vehicles.map((item, index) => {
        return {
          ...item?.vehicle,
          ...item,
          id: index,
          _id: item?.vehicle?._id,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <Table
      data={rows}
      fieldsToDisplay={fieldsToDisplay}
      editEndPoint='/vehicle'
    />
  );
}

export default Vehicles;
