import React, { useEffect, useState, useMemo } from 'react';
import { useAtom } from 'jotai';
import { MuiFileInput } from 'mui-file-input';
import { parse } from 'papaparse';

import {
	clone,
	get,
	isEmpty,
	map,
	pull,
	pullAt,
	reduce,
	forEach,
	isEqual,
	isFunction,
} from 'lodash';
import { getErrorMessage } from '../../../lib/input';
import { Section } from '../tabs/section-block';
import { Checkbox, Tooltip } from '@mui/material';
import { Warning } from '@mui/icons-material';

import { atom } from 'jotai';

const _checkedList = atom([]);
const _list = atom([]);

const useList = ({ setRawData } = {}) => {
	const [list, setList] = useAtom(_list);
	const [checkedList, setCheckedList] = useAtom(_checkedList);

	const allChecked = () => {
		setCheckedList(list.map((v, i) => i));
	};
	const deleteItems = () => {
		const newList = [...list];
		pullAt(newList, checkedList);
		setRawData(newList);
		setCheckedList([]);
	};

	return {
		setList,
		setCheckedList,
		checkedList,
		list,
		allChecked,
		deleteItems,
	};
};

const ListFooter = ({ setRawData, value }) => {
	const { checkedList, deleteItems } = useList({ setRawData });
	if (isEmpty(value)) return null;
	return (
		<div className='flex'>
			<div className='flex items-center gap-4'>
				<div className=''>{checkedList.length} row(s) selected.</div>
				<div
					className='hover:text-blue-400 cursor-pointer underline'
					onClick={deleteItems}
				>
					Delete
				</div>
			</div>
		</div>
	);
};

const ListHeader = ({ schema, fieldsToDisplay }) => {
	const [checked, setChecked] = useState(false);
	const { list, checkedList, allChecked, setCheckedList } = useList();
	useEffect(() => {
		if (!isEmpty(list)) {
			if (list.length === checkedList.length) {
				setChecked(true);
			} else {
				setChecked(false);
			}
		} else {
			setChecked(false);
		}
	}, [checkedList, list]);
	return (
		<div className='flex'>
			<div className='w-12 flex items-center shrink-0'>
				<Checkbox
					checked={checked}
					onChange={(v) => {
						const { checked } = v.target;
						if (checked) {
							allChecked();
						} else {
							setCheckedList([]);
						}
					}}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			</div>
			<div className='w-12 justify-center flex items-center shrink-0'>No.</div>
			{fieldsToDisplay.map((f, index) => {
				const { example, format } = schema[f]?.tooltip || {};
				return (
					<Tooltip
						key={index}
						title={example ? `e.g. ${example}` : ''}
						placement='top-start'
					>
						<div className='w-52 flex items-center shrink-0'>
							{schema[f]?.title || f}
						</div>
					</Tooltip>
				);
			})}
		</div>
	);
};

const ListRow = ({ fieldsToDisplay, value, index, error, warning }) => {
	const [checked, setChecked] = useState(false);
	const { checkedList, setCheckedList } = useList();
	useEffect(() => {
		setChecked(checkedList.includes(index));
	}, [checkedList, index]);
	return (
		<div className='flex'>
			<div className='w-12 flex items-center shrink-0'>
				<Checkbox
					checked={checked}
					onChange={(v) => {
						const { checked } = v.target;
						if (checked) {
							setCheckedList((list) => {
								return [...list, index];
							});
						} else {
							setCheckedList((list) => {
								return [...[...pull(list, index)]];
							});
						}
						setChecked(v.target.checked);
					}}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			</div>
			<div className='w-12 justify-center flex items-center shrink-0'>
				{index + 1}
			</div>
			{fieldsToDisplay.map((f) => {
				const errorMsg = get(error, `[${index}][${f}]`);
				const warningMsg = get(warning, `[${index}][${f}]`);
				return (
					<div
						key={f}
						className='w-52 flex items-center shrink-0 overflow-x-auto'
					>
						{value[f]}
						{errorMsg || warningMsg ? (
							<Tooltip title={errorMsg || warningMsg} placement='top' arrow>
								<Warning
									className={
										errorMsg
											? 'text-red-700'
											: warningMsg
											? 'text-yellow-500'
											: ''
									}
								/>
							</Tooltip>
						) : null}
					</div>
				);
			})}
		</div>
	);
};

const List = ({
	schema,
	fieldsToDisplay,
	value,
	setRawData,
	error,
	warning,
}) => {
	const { setList } = useList();
	useEffect(() => {
		if (value && !isEmpty(value)) {
			setList(value);
		}
	}, [value, setList]);
	return (
		<div className='overflow-x-auto flex flex-col gap-4'>
			<ListHeader schema={schema} fieldsToDisplay={fieldsToDisplay} />
			{value.map((v, index) => {
				return (
					<ListRow
						key={index}
						fieldsToDisplay={fieldsToDisplay}
						value={v}
						index={index}
						error={error}
						warning={warning}
					/>
				);
			})}
			<ListFooter setRawData={setRawData} value={value} />
		</div>
	);
};

function ImportCsv({
	schema,
	fieldsToDisplay,
	setValue,
	setError,
	customError,
	customWarning,
	value,
}) {
	const [rawData, setRawData] = useState([]);
	const [files, setFiles] = useState([]);
	const onChange = (v) => {
		if (!isEmpty(v)) {
			map(v, (p) => {
				parse(p, {
					header: true,
					complete: function (results) {
						console.log('Finished:', results.data);
						setRawData((v) => {
							return [...v, ...results.data];
						});
					},
				});
			});
		} else {
			setRawData([]);
		}
		setFiles(v);
	};

	const _value = useMemo(() => {
		if (!isEmpty(rawData)) {
			return map(rawData, (row) => {
				return reduce(
					fieldsToDisplay,
					(obj, field) => {
						obj[field] = row[field];
						return obj;
					},
					{}
				);
			});
		}
		return [];
	}, [rawData, fieldsToDisplay]);

	const _error = useMemo(() => {
		if (!isEmpty(value)) {
			return reduce(
				value,
				(obj, currentRow, index) => {
					forEach(currentRow, (text, field) => {
						let msg = getErrorMessage({ schema: schema[field], text });
						if (field === 'end_date' && value && currentRow['effective_date']) {
							const { effective_date } = currentRow;
							if (new Date(effective_date) > new Date(value)) {
								msg = 'End date must be after Effective date';
							}
						}
						if (isFunction(customError)) {
							msg = customError({ msg, field, value: text, currentRow });
						}
						if (msg) {
							obj[index] = {
								...obj[index],
								[field]: msg,
							};
						}
					});
					return obj;
				},
				{}
			);
		}
		return {};
	}, [value, schema, customError]);

	const warning = useMemo(() => {
		if (!isEmpty(value)) {
			return reduce(
				value,
				(obj, currentRow, index) => {
					forEach(currentRow, (text, field) => {
						let msg = '';
						if (isFunction(customWarning)) {
							msg = customWarning({ msg, field, value: text, currentRow });
						}
						if (msg) {
							obj[index] = {
								...obj[index],
								[field]: msg,
							};
						}
					});
					return obj;
				},
				{}
			);
		}
		return {};
	}, [value, customWarning]);

	useEffect(() => {
		setError(_error);
	}, [_error, setError]);

	useEffect(() => {
		if (!isEmpty(_value) && !isEqual(_value, value)) {
			setValue(_value);
		}
	}, [_value, value, setValue]);

	return (
		<div className='w-full h-full flex flex-col items-center justify-center'>
			<div className='p-4'>
				<div className='text-center text-xl mb-4'>Choose your CSV files:</div>
				<MuiFileInput
					value={files}
					accept='.csv'
					color='primary'
					multiple
					onChange={onChange}
				/>
			</div>
			<div className='grow w-full h-full'>
				<Section>
					<List
						schema={schema}
						fieldsToDisplay={fieldsToDisplay}
						value={_value}
						setRawData={setRawData}
						error={_error}
						warning={warning}
					/>
				</Section>
			</div>
		</div>
	);
}

export default ImportCsv;
