import React, { useMemo, useContext } from 'react';
import { isArray } from 'lodash';

import Table from '../../../../components/common/table';
import TabTable from 'components/common/tabs/table';

function RegMarks({ context, fieldsToDisplay }) {
  const { data } = useContext(context);

  const rows = useMemo(() => {
    if (data?.reg_marks && isArray(data?.reg_marks)) {
      return data.reg_marks.map((item, index) => {
        const { reg_mark } = item;
        return {
          id: index,
          _id: reg_mark?._id,
          reg_mark: reg_mark?.reg_mark,
          effective_date: item?.effective_date,
          end_date: item?.end_date,
          objectId: item?._id,
        };
      });
    }
    return [];
  }, [data]);

  const addable = useMemo(() => {
    if (rows.length === 0) return true;
    return rows.every(
      (row) => row.end_date !== undefined && row.end_date !== null
    );
  }, [rows]);

  return (
    <TabTable
      schema={[
        {
          id: 'reg_mark',
          isId: true,
          label: '車牌號碼',
          editable: false,
          selectPath: '/reg_mark/all-reg_mark-select',
          free_solo: false,
        },
        {
          id: 'effective_date',
          label: '生效日期',
          type: 'date',
        },
        {
          id: 'end_date',
          label: '結束日期',
          type: 'date',
        },
      ]}
      setting={{
        collection: 'vehicle',
        keyName: 'reg_mark',
        orderBy: 'effective_date',
        addable,
      }}
      collectionId={data._id}
      rows={rows}
    />
  );
}

export default RegMarks;
