import React, { useContext } from 'react';
import { isEmpty } from 'lodash';

import Switcher from '../../../../components/common/input/switcher';
import Status from '../../../../components/status';
import {
  Section,
  RelationSection,
} from '../../../../components/common/tabs/section-block';
import MetaData from '../../../../components/meta-data';

function ProfileGeneral({ context }) {
  const {
    mode,
    prefix,
    data,
    schema,
    setInputs,
    saveBtnClicked,
    pushItemToField,
    fieldsToDisplay,
  } = useContext(context);

  if (isEmpty(schema)) return null;
  return (
    <div className='flex gap-4 py-10' role='tabpanel'>
      <div className='_p-8 _shadow-md _bg-white grow flex flex-col gap-4'>
        <div>
          <Section>
            {fieldsToDisplay.map((field) => {
              if (field !== 'status') {
                return (
                  <Switcher
                    key={field}
                    schema={{
                      ...schema[field],
                      editable:
                        mode === 'edit' ? schema[field]?.editable : true,
                    }}
                    value={data?.[field]}
                    setInputs={setInputs}
                    saveBtnClicked={saveBtnClicked}
                    field={field}
                    pushItemToField={pushItemToField}
                    suggestEndpoint={`/${prefix}/column/${field}`}
                  />
                );
              }
              return null;
            })}
          </Section>
        </div>
        <MetaData data={data} />
      </div>
      {/* <div className='w-6/12 xl:w-5/12 2xl:w-4/12 flex flex-col gap-4 shrink-0'>
				<Status data={data} setInputs={setInputs} />
				<VehicleSection
					current_vehicle={data.current_vehicle}
					setInputs={setInputs}
					saveBtnClicked={saveBtnClicked}
				/>
			</div> */}
    </div>
  );
}

const VehicleSection = ({
  current_vehicle = null,
  setInputs,
  saveBtnClicked,
}) => {
  const { vehicle, effective_date, end_date } = current_vehicle || {};
  return (
    <div>
      <RelationSection
        title='車輛/機械'
        isEdit={vehicle?._id}
        url={`/vehicle/${vehicle?._id || ''}`}
      >
        <Switcher
          value={
            current_vehicle
              ? {
                  _id: vehicle?._id,
                  label: vehicle?.chassis_number,
                }
              : undefined
          }
          field={'input__vehicle_id'}
          setInputs={setInputs}
          suggestEndpoint={`/vehicle/all-vehicle-select?filters=_id^!=${
            vehicle?._id || ''
          }`}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: 'text',
            title: '底盤號碼',
            select: true,
          }}
        />
        <Switcher
          value={effective_date}
          field={'input__vehicle_effective_date'}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: 'date',
            title: '生效日期',
            is_required: effective_date,
          }}
        />
        <Switcher
          value={end_date}
          field={'input__vehicle_end_date'}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: 'date',
            title: '結束日期',
            is_required: end_date,
          }}
        />
      </RelationSection>
    </div>
  );
};

export default ProfileGeneral;
