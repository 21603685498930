import React, { useEffect, useContext } from 'react';
import { isEmpty, pull } from 'lodash';

import Switcher from '../../../../components/common/input/switcher';
import Status from '../../../../components/status';
// import { Section } from '../../../../components/common/tabs/section-block';
import MetaData from '../../../../components/meta-data';
import {
  Section,
  VehicleSection,
  ContractDeductSection,
  CompanySection,
  ContractSection,
  RegMarkSection,
  ExpensesImportDetailSection,
  WarehouseSection,
} from 'components/common/tabs/section-block';

function ProfileGeneral({ context }) {
  // const {
  //   mode,
  //   prefix,
  //   data,
  //   schema,
  //   setInputs,
  //   saveBtnClicked,
  //   pushItemToField,
  //   fieldsToDisplay,
  // } = useContext(context);

  const {
    isEdit,
    mode,
    prefix,
    data,
    schema,
    setInputs,
    saveBtnClicked,
    pushItemToField,
    fieldsToDisplay,
  } = useContext(context);
  console.log('general page data', data);

  if (mode === 'new') {
    pull(
      fieldsToDisplay,
      'from_key',
      'print_number',
      'reg_mark_number',
      'unit',
      'import_batch_id'
    );
  }

  if (isEmpty(schema)) return null;
  return (
    <div className='flex gap-4 py-10' role='tabpanel'>
      <div className='_p-8 _shadow-md _bg-white grow flex flex-col gap-4'>
        <div>
          <Section>
            {fieldsToDisplay.map((field) => {
              if (field !== 'status') {
                return (
                  <Switcher
                    key={field}
                    schema={{
                      ...schema[field],
                      editable:
                        mode === 'edit' ? schema[field]?.editable : true,
                    }}
                    value={data[field]}
                    setInputs={setInputs}
                    saveBtnClicked={saveBtnClicked}
                    field={field}
                    pushItemToField={pushItemToField}
                    suggestEndpoint={`/${prefix}/column/${field}`}
                  />
                );
              }
              return null;
            })}
          </Section>
        </div>
        <MetaData data={data} />
      </div>
      {/* <div className='w-6/12 xl:w-5/12 2xl:w-4/12 flex flex-col gap-4 shrink-0'>
        <Status data={data} setInputs={setInputs} />
      </div> */}
      <div className='w-6/12 xl:w-5/12 2xl:w-4/12 flex flex-col gap-4 shrink-0'>
        <Status data={data} setInputs={setInputs} />
        <ContractSection
          current_contract={data}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          exclude={['effective_date', 'end_date']}
          isEdit={isEdit}
          title='扣數合約'
        />
        {mode !== 'new' && (
          <ExpensesImportDetailSection data={data} title='滙入細明' />
        )}
        {/* <ContractDeductSection
          current_contract_deduct={data}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          exclude={['effective_date', 'end_date']}
          isEdit={isEdit}
          title='扣數合約'
        /> */}
        {(mode === 'new' || data?.reg_mark) && (
          <RegMarkSection
            current_reg_mark={data}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            exclude={['effective_date', 'end_date']}
            isEdit={mode !== 'new'}
            readonly={mode !== 'new'}
            // preset={{
            //   reg_mark: {
            //     options: regMarkOptions,
            //     selected: !data?.reg_mark?._id ? regMarkPreset : null,
            //   },
            // }}
          />
        )}
        {data?.vehicle && (
          <VehicleSection
            current_vehicle={data}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            // readonly={
            //   isEdit ? data?.current_vehicle?.vehicle?.chassis_number : false
            // }
            readonly={true}
            exclude={['effective_date', 'end_date']}
            // preset={{
            //   selected: path.get('vehicle'),
            // }}
            // events={{
            //   vehicle: {
            //     onChange: fetchContractOptions,
            //   },
            // }}
            isEdit={isEdit}
          />
        )}
        <WarehouseSection
          warehouse={data?.warehouses}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          // readonly={
          //   isEdit ? data?.current_vehicle?.vehicle?.chassis_number : false
          // }
          readonly={true}
          exclude={['effective_date', 'end_date']}
          isEdit={isEdit}
        />
        {/* <RegMarkSection
          current_reg_mark={data}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          exclude={['effective_date', 'end_date']}
          isEdit={isEdit}
        /> */}
        {/* <CompanySection
          current_company={data}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          exclude={['effective_date', 'end_date']}
          isEdit={isEdit}
        /> */}
      </div>
    </div>
  );
}

export default ProfileGeneral;
