import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find, forEach, isEmpty, map, reduce, get as _get, uniq } from 'lodash';

import ImportCsv from '../../../components/common/importcsv';
import { useGlobalStore } from '../../../lib/store';
import { get, post } from '../../../lib/request';
import { redirect } from '../../../lib/config';
import { usePermissions } from 'lib/permissions';

const Upload = ({ prefix, title }) => {
  const [error, setError] = useState({});
  const [value, setValue] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [fieldsToDisplay, setFieldsToDisplay] = useState([]);
  const [schema, setSchema] = useState(null);
  const { isWritable } = usePermissions();

  const { setDefaultTopnav } = useGlobalStore();

  const customError = useCallback(({ msg, field, value, currentRow }) => {
    return msg;
  }, []);

  const customWarning = useCallback(
    ({ msg, field, value, currentRow }) => {
      if (field === 'vehicle' && !find(vehicles, { label: value })) {
        msg = 'Vehicle does not exist';
      }
      if (field === 'contract_number' && !find(contracts, { label: value })) {
        msg = 'Contract does not exist';
      }
      return msg;
    },
    [contracts, vehicles]
  );
  const writable = isWritable({ collection: prefix });

  const canSave = useMemo(() => {
    return !isEmpty(value) && writable;
  }, [value, writable]);

  useEffect(() => {
    if (isEmpty(vehicles) && isEmpty(contracts) && !isEmpty(value) && prefix) {
      get({ url: `/contract/all-contract-select?statusless=true` }).then(
        ({ is200, data }) => {
          if (is200) {
            setContracts(data);
          }
        }
      );
      get({ url: `/vehicle/all-vehicle-select?statusless=true` }).then(
        ({ is200, data }) => {
          if (is200) {
            setVehicles(data);
          }
        }
      );
    }
  }, [value, contracts, vehicles, prefix]);

  useEffect(() => {
    get({ url: `/${prefix}/schema` }).then(({ is200, data }) => {
      if (is200) {
        const { fieldsToImport } = data?.pageConfig?.pages?.import;
        if (fieldsToImport) {
          setSchema(fieldsToImport);
          setFieldsToDisplay(Object.keys(fieldsToImport));
        }
      }
    });
  }, [prefix]);

  const save = useCallback(async () => {
    const body = map(value, (row) => {
      const vehicle = find(vehicles, { label: row.vehicle })?._id;
      const contract = find(contracts, { label: row.contract_number })?._id;
      return {
        ...row,
        vehicle,
        contract,
      };
    });

    console.log({ body });

    const result = await post({
      url: `/${prefix}/import`,
      isImport: true,
      body,
    });

    if (result && result.is200 && result.data) {
      console.log(result);
      redirect(
        `/${prefix}?alert=${encodeURIComponent(
          `${result.data.success} record(s) Created/Updated!`
        )}`
      );
    }
  }, [prefix, value, vehicles]);

  useEffect(() => {
    setDefaultTopnav({ url: `/${prefix}`, title, canSave, save });
  }, [prefix, canSave, save]);

  if (isEmpty(schema)) return null;
  return (
    <ImportCsv
      fieldsToDisplay={fieldsToDisplay}
      schema={schema}
      setError={setError}
      setValue={setValue}
      value={value}
      customError={customError}
      customWarning={customWarning}
    />
  );
};
export default Upload;
