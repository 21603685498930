import { useAtom } from 'jotai';

import { _username } from '../lib/store';

function App() {
	const [username] = useAtom(_username);

	return (
		<div className='flex w-full justify-center items-center'>
			<img src='/assets/images/welcomec-VMS-systemchart-v01.png' alt='chart' />
		</div>
	);
}

export default App;
