import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Index from './listing';

import { useGlobalStore } from '../../lib/store';
import Detail from './detail';

function Page({ mode, prefix, titleIcon }) {
  const { _id } = useParams();
  const { setActiveSidebarItem } = useGlobalStore();
  useEffect(() => {
    setActiveSidebarItem(['System Management', 'Log']);
  }, []);
  const isHome = mode === 'home';

  return (
    <div>
      {isHome ? (
        <Index prefix={prefix} titleIcon={titleIcon} />
      ) : (
        <Detail _id={_id} titleIcon={titleIcon} />
      )}
    </div>
  );
}

export default Page;
