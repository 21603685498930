import React, { useEffect, useState, useRef } from 'react';
import { FormControlLabel, Switch } from '@mui/material';

function Status({ setEdited, data, setInputs, saveBtnClicked }) {
  const [isOn, setIsOn] = useState(
    data.status === undefined || data.status === true ? true : false
  );
  const ref = useRef();

  useEffect(() => {
    setInputs((v) => {
      return {
        ...v,
        status: ref,
      };
    });
  }, [setInputs]);
  return (
    <div className='p-5 shadow-md bg-white flex items-center justify-center'>
      <div className=''>
        <FormControlLabel
          control={
            <Switch
              inputRef={ref}
              checked={isOn}
              onChange={(e) => {
                const on = e.target.checked;
                setIsOn(on);
              }}
            />
          }
          label='狀況'
        />
      </div>
    </div>
  );
}

export default Status;
