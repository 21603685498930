import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find, forEach, isEmpty, map, reduce, get as _get, keys } from 'lodash';

import ImportCsv from '../../../components/common/importcsv';
import ImportExpenses from './importexpenses';
import { useGlobalStore } from '../../../lib/store';
import { get, post } from '../../../lib/request';
import { redirect } from '../../../lib/config';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFullScreenLoading } from 'components/common/loading';

const _schema = {
  // vehicle: {
  //   type: 'text',
  //   title: 'Vehicle Number',
  //   is_required: true,
  //   tooltip: {},
  // },
  effective_date: {
    title: 'Effective Date',
    regExp: /^\d{4}-\d{2}-\d{2}$/, // 2023-04-05
    tooltip: { format: 'YYYY-MM-DD', example: '2023-04-05' },
  },
  end_date: {
    title: 'End Date',
    regExp: /^\d{4}-\d{2}-\d{2}$/, // 2023-04-05
    tooltip: { format: 'YYYY-MM-DD', example: '2023-04-05' },
  },
};

const Upload = ({ prefix, title, fieldsToDisplay, titleIcon }) => {
  const [error, setError] = useState({});
  const [value, setValue] = useState([]);
  const [type, setType] = useState(null);
  const [dieselAddress, setDieselAddress] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [schema, setSchema] = useState(null);

  const { setActive } = useFullScreenLoading();

  const { setDefaultTopnav, showAlert } = useGlobalStore();

  const customError = useCallback(
    ({ msg, field, value, currentRow }) => {
      if (field === 'vehicle' && !find(vehicles, { label: value })) {
        msg = 'Vehicle does not exist';
      }
      return msg;
    },
    [vehicles]
  );

  const customWarning = useCallback(({ msg, field, value, currentRow }) => {
    return msg;
  }, []);

  const canSave = useMemo(() => {
    return (
      !isEmpty(value) ||
      type === 'gps' ||
      type === 'license' ||
      type === 'insurance'
    );
  }, [error, value, type]);

  useEffect(() => {
    if (isEmpty(vehicles) && !isEmpty(value) && prefix) {
      // get({ url: `/vehicle/all-vehicle-select?statusless=true` }).then(
      //   ({ is200, data }) => {
      //     if (is200) {
      //       setVehicles(data);
      //     }
      //   }
      // );
    }
  }, [value, vehicles, prefix]);

  useEffect(() => {
    get({ url: `/warehouse/all-warehouse-select?statusless=true` }).then(
      ({ is200, data }) => {
        if (is200) {
          setWarehouses(data);
        }
      }
    );
  }, [prefix]);

  useEffect(() => {
    get({ url: `/${prefix}/schema` }).then(({ is200, data }) => {
      if (is200) {
        const { schema } = data;
        if (schema) {
          setSchema({
            ...schema,
            ..._schema,
          });
        }
      }
    });
  }, [prefix]);

  const save = useCallback(async () => {
    // const body = map(value, (row) => {
    //   const vehicle = find(vehicles, { label: row.vehicle })?._id;
    //   return {
    //     ...row,
    //     vehicle,
    //   };
    // });
    const body = value;

    setActive(true);

    const result = await post({
      url: `/${prefix}/import`,
      isImport: true,
      body: {
        type: type,
        dieselAddress,
        data: body,
      },
    });

    setActive(false);

    if (result && !result.is200) {
      const message = result?.data?.err || 'Default Error Message';
      showAlert({ message, type: 'error' });
    } else if (result && result.is200 && result.data) {
      redirect(
        `/expenses?alert=${encodeURIComponent(
          `${result.data.success} record(s) Created/Updated!`
        )}`
      );
    }
  }, [prefix, value, type, dieselAddress]);

  useEffect(() => {
    setDefaultTopnav({
      // url: `/${prefix}`,
      url: `/expenses`,
      title,
      canSave,
      save,
      titleIcon,
    });
  }, [prefix, canSave, save, titleIcon]);

  if (isEmpty(schema)) return null;
  return (
    <div>
      {/* <div className='w-full h-full flex flex-col items-center justify-center'>
        <div className='p-4'>
          <div className='text-center text-xl mb-4'>Import Type: </div>
          <div className='text-center text-xl mb-4'>
            <select
              defaultValue={''}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value='' disabled>
                Please select import type
              </option>
              <option value='shell'>Shell</option>
              <option value='etoll'>Etoll</option>
              <option value='diesel'>Diesel</option>
            </select>
          </div>
        </div>
      </div> */}
      <div className='flex flex-col gap-4'>
        <FormControl fullWidth>
          <InputLabel id='import_type'>Import Type</InputLabel>
          <Select
            labelId='import_type'
            label='ImportType'
            defaultValue={''}
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <MenuItem value='shell' key='import_type_shell'>
              Shell
            </MenuItem>
            <MenuItem value='etoll' key='import_type_etoll'>
              Etoll
            </MenuItem>
            <MenuItem value='diesel' key='import_type_diesel'>
              用油
            </MenuItem>
            <MenuItem
              value='diesel_inventory'
              key='import_type_diesel_inventory'
            >
              買油
            </MenuItem>
            <MenuItem value='gps' key='import_type_gps'>
              GPS費用
            </MenuItem>
            <MenuItem value='license' key='import_type_license'>
              車輛續牌費
            </MenuItem>
            <MenuItem value='insurance' key='import_type_insurance'>
              車輛續保費
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className='pt-4'>
        {(type === 'diesel' || type === 'diesel_inventory') && (
          <FormControl fullWidth>
            <InputLabel id='diesel_address'>Warehouse</InputLabel>
            <Select
              labelId='diesel_address'
              label='DieselAddress'
              defaultValue={''}
              onChange={(e) => {
                setDieselAddress(e.target.value);
              }}
            >
              {warehouses?.map((warehouse) => (
                <MenuItem value={warehouse?._id} key={warehouse?._id}>
                  {warehouse?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      {type && type != 'gps' && type != 'license' && type != 'insurance' ? (
        <ImportExpenses
          fieldsToDisplay={[...Object.keys(_schema), ...fieldsToDisplay]}
          schema={schema}
          setError={setError}
          setValue={setValue}
          value={value}
          customError={customError}
          customWarning={customWarning}
          type={type}
        />
      ) : null}
    </div>
  );
};
export default Upload;
