import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find, forEach, isEmpty, map, reduce, get as _get, keys } from 'lodash';

import ImportCsv from '../../../components/common/importcsv';
import ImportExpenses from './importexpenses';
import { useGlobalStore } from '../../../lib/store';
import { get, post } from '../../../lib/request';
import { redirect } from '../../../lib/config';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { usePermissions } from 'lib/permissions';

const _schema = {
  // vehicle: {
  //   type: 'text',
  //   title: 'Vehicle Number',
  //   is_required: true,
  //   tooltip: {},
  // },
  effective_date: {
    title: 'Effective Date',
    regExp: /^\d{4}-\d{2}-\d{2}$/, // 2023-04-05
    tooltip: { format: 'YYYY-MM-DD', example: '2023-04-05' },
  },
  end_date: {
    title: 'End Date',
    regExp: /^\d{4}-\d{2}-\d{2}$/, // 2023-04-05
    tooltip: { format: 'YYYY-MM-DD', example: '2023-04-05' },
  },
};

const Upload = ({ prefix, title, fieldsToDisplay }) => {
  const [error, setError] = useState({});
  const [value, setValue] = useState([]);
  const [type, setType] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [schema, setSchema] = useState(null);

  const { setDefaultTopnav } = useGlobalStore();
  const { isWritable } = usePermissions();

  const customError = useCallback(
    ({ msg, field, value, currentRow }) => {
      if (field === 'vehicle' && !find(vehicles, { label: value })) {
        msg = 'Vehicle does not exist';
      }
      return msg;
    },
    [vehicles]
  );

  const customWarning = useCallback(({ msg, field, value, currentRow }) => {
    return msg;
  }, []);

  const writable = isWritable({ collection: prefix });

  const canSave = useMemo(() => {
    return !isEmpty(value) && writable;
  }, [value, writable]);

  useEffect(() => {
    if (isEmpty(vehicles) && !isEmpty(value) && prefix) {
      // get({ url: `/vehicle/all-vehicle-select?statusless=true` }).then(
      //   ({ is200, data }) => {
      //     if (is200) {
      //       setVehicles(data);
      //     }
      //   }
      // );
    }
  }, [value, vehicles, prefix]);

  useEffect(() => {
    get({ url: `/${prefix}/schema` }).then(({ is200, data }) => {
      if (is200) {
        const { schema } = data;
        if (schema) {
          setSchema({
            ...schema,
            ..._schema,
          });
        }
      }
    });
  }, [prefix]);

  const save = useCallback(async () => {
    // const body = map(value, (row) => {
    //   const vehicle = find(vehicles, { label: row.vehicle })?._id;
    //   return {
    //     ...row,
    //     vehicle,
    //   };
    // });

    const body = value;

    const result = await post({
      url: `/${prefix}/import`,
      isImport: true,
      body: {
        type: type,
        data: body,
      },
    });

    if (result && result.is200 && result.data) {
      redirect(
        `/expenses?alert=${encodeURIComponent(
          `${result.data.success} record(s) Created/Updated!`
        )}`
      );
    }
  }, [prefix, value, type]);

  useEffect(() => {
    setDefaultTopnav({
      // url: `/${prefix}`,
      url: `/expenses`,
      title,
      canSave,
      save,
    });
  }, [prefix, canSave, save]);

  if (isEmpty(schema)) return null;
  return (
    <div>
      {/* <div className='w-full h-full flex flex-col items-center justify-center'>
        <div className='p-4'>
          <div className='text-center text-xl mb-4'>Import Type: </div>
          <div className='text-center text-xl mb-4'>
            <select
              defaultValue={''}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value='' disabled>
                Please select import type
              </option>
              <option value='shell'>Shell</option>
              <option value='etoll'>Etoll</option>
              <option value='diesel'>Diesel</option>
            </select>
          </div>
        </div>
      </div> */}
      <div className='flex flex-col gap-4'>
        <FormControl fullWidth>
          <InputLabel id='import_type'>Import Type</InputLabel>
          <Select
            labelId='import_type'
            label='ImportType'
            defaultValue={''}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <MenuItem value='shell' key='import_type_shell'>
              Shell
            </MenuItem>
            <MenuItem value='etoll' key='import_type_etoll'>
              Etoll
            </MenuItem>
            {/* <MenuItem value='diesel' key='import_type_diesel'>
              Diesel
            </MenuItem> */}
          </Select>
        </FormControl>
      </div>

      {type ? (
        <ImportExpenses
          fieldsToDisplay={[...Object.keys(_schema), ...fieldsToDisplay]}
          schema={schema}
          setError={setError}
          setValue={setValue}
          value={value}
          customError={customError}
          customWarning={customWarning}
        />
      ) : null}
    </div>
  );
};
export default Upload;
