import React, { useEffect, useMemo } from 'react';
import { useGlobalStore } from '../../../lib/store';
import { useInputStore } from '../../../lib/input';

import Tabs from '../../../components/common/tabs';
import General from './tabs/general';
import Vehicles from './tabs/vehicles';

const InputContext = React.createContext();

const tabs = [
  {
    label: '基本資料',
    component: <General context={InputContext} />,
    props: {},
  },
  // {
  //   label: '車輛/機械',
  //   component: <Vehicles context={InputContext} />,
  //   props: {},
  // },
];

const Profile = ({ _id, mode, prefix, title, titleIcon }) => {
  const { setDefaultTopnav } = useGlobalStore();
  const store = useInputStore({
    title,
    id: _id,
    prefix,
    mode,
    queryParams: '',
    customValidateFields: {},
    customFieldsModifier: (changes, rawData) => {
      return changes;
    },
  });
  const { canSave, initStore, save } = store;

  useEffect(() => {
    initStore({ _id, mode, prefix });
  }, []);
  useEffect(() => {
    setDefaultTopnav({
      url: `/expenses`,
      title,
      canSave: false,
      save: false,
      mode,
      titleIcon,
      // copy: mode === 'edit' && `/${prefix}/copy/${_id}`,
    });
  }, [prefix, canSave, save, titleIcon]);
  return (
    <InputContext.Provider value={store}>
      <Tabs tabs={tabs} prefix={prefix} />
    </InputContext.Provider>
  );
};

export default Profile;
