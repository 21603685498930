import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { DataGrid } from '@mui/x-data-grid';
import { ContentCopy, Edit } from '@mui/icons-material';
import { map } from 'lodash';
import { isISODateString } from '../../../lib/config';

import To from '../to';

function Table({ fieldsToDisplay, data, editEndPoint, copyable }) {
	const cols = useMemo(() => {
		return [
			...map(fieldsToDisplay, (name, field) => {
				return {
					field,
					headerName: name,
					flex: 1,
					renderHeader: (params) => {
						const { headerName } = params.colDef;
						return <div className='text-lg font-bold'>{headerName}</div>;
					},
					renderCell: (params) => {
						const { formattedValue } = params;
						const value = formattedValue
							? isISODateString(formattedValue)
								? dayjs(formattedValue).format('YYYY/MM/DD')
								: formattedValue
							: '';
						return <div className='text-base'>{value}</div>;
					},
				};
			}),
			{
				field: 'edit',
				headerName: '動作',
				width: 100,
				renderCell: (params) => {
					return params?.row?._id ? (
						<>
							<To
								url={`${editEndPoint}/${params?.row?._id}`}
								className='flex items-center justify-center w-full'
								toNew
							>
								<Edit />
							</To>
							{copyable && (
								<To
									url={`${editEndPoint}/copy/${params?.row?._id}`}
									className='flex items-center justify-center w-full'
									toNew
								>
									<ContentCopy />
								</To>
							)}
						</>
					) : null;
				},
			},
		];
	}, [fieldsToDisplay, editEndPoint]);
	return (
		<DataGrid
			autoWidth
			autoHeight
			rows={data}
			columns={cols}
			sx={{ backgroundColor: 'white', borderRadius: 0 }}
		/>
	);
}

export default Table;
