import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Cancel, Clear, Done } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  isEmpty,
  isUndefined,
  map,
  reduce,
  find,
  isObject,
  isString,
} from 'lodash';
import { formatData } from 'lib/input';
import { get, post, put } from 'lib/request';
import { redirect, redirectWithQuery } from 'lib/config';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Autocomplete } from '@mui/material';

const useSubmitForm = ({
  data,
  schema,
  toClose,
  isAdd,
  isEdit,
  isDelete,
  collection,
  collectionId,
  targetId,
  keyName,
}) => {
  const [input, setInput] = useState({});
  const submit = useCallback(() => {
    const redirectTab = collectionId ? keyName : targetId ? collection : '';
    if (isDelete) {
      put({
        url: `/${collection}/update/${collectionId || data._id}`,
        body: {
          [`input__${keyName}_object_id`]: data.objectId,
          [`input__${keyName}_${keyName}`]: data._id,
          input__type: 'delete',
        },
      }).then(() => {
        redirectWithQuery({
          query: {
            alert: '刪除成功!',
          },
          hash: redirectTab,
        });
      });
    } else if (isEdit && !isEmpty(input)) {
      const body = reduce(
        input,
        (acc, val, key) => {
          acc[`input__${keyName}_${key}`] = val;
          return acc;
        },
        {
          input__type: 'update',
          [`input__${keyName}_object_id`]: data.objectId,
        }
      );
      console.log({ body });
      put({
        url: `/${collection}/update/${collectionId || data._id}`,
        body,
      }).then(() => {
        redirectWithQuery({
          query: {
            alert: '更新成功!',
          },
          hash: redirectTab,
        });
      });
    } else if (isAdd && !isEmpty(input)) {
      const body = reduce(
        input,
        (acc, val, key) => {
          const _schema = find(schema, { id: key });
          if (_schema.isId) {
            if (collectionId) {
              acc[`input__${keyName}_id`] = val;
            }
          } else {
            acc[`input__${keyName}_${key}`] = val;
          }

          return acc;
        },
        {
          input__type: 'add',
          ...(targetId
            ? {
                [`input__${keyName}_id`]: targetId,
              }
            : {}),
        }
      );
      const refKey = find(schema, { isId: true })?.id;
      const refId = collectionId || input[refKey];
      if (refId) {
        put({
          url: `/${collection}/update/${refId}`,
          body,
        }).then(() => {
          redirectWithQuery({
            query: {
              alert: '新增成功!',
            },
            hash: redirectTab,
          });
        });
      }
    }
    console.log(input);
  }, [
    schema,
    data,
    input,
    isAdd,
    isDelete,
    isEdit,
    collection,
    collectionId,
    keyName,
    targetId,
  ]);
  return {
    submit,
    input,
    setInput: ({ data, key }) => {
      setInput((v) => {
        return {
          ...v,
          [key]: data,
        };
      });
    },
  };
};

export default function FormDialog({
  open,
  toClose,
  isAdd,
  isEdit,
  isDelete,
  data,
  schema,
  collection,
  collectionId,
  targetId,
  keyName,
}) {
  const { submit, setInput, input } = useSubmitForm({
    data,
    schema,
    toClose,
    isAdd,
    isDelete,
    isEdit,
    collection,
    collectionId,
    targetId,
    keyName,
  });
  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        if (r === 'backdropClick') {
          return;
        }
        toClose();
      }}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '12px',
          maxWidth: '50vw',
          minWidth: '600px',
        },
        '.MuiDialogTitle-root': {
          borderBottom: '1px solid #e8e8e8',
        },
        '.MuiDialogContent-root': {
          padding: '0',
        },
      }}
    >
      <DialogTitle>
        <div className='flex w-full justify-between'>
          <div>
            {isEdit ? '更新' : isDelete ? '刪除' : isAdd ? '新增' : ''}紀錄
          </div>
          <div className='text-zinc-400 cursor-pointer'>
            <Clear onClick={toClose} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className='min-w-[50vw]'>
          <Content
            schema={schema}
            data={data}
            isDelete={isDelete}
            isEdit={isEdit}
            isAdd={isAdd}
            setInput={setInput}
            input={input}
          />
        </div>
        {/* <DialogContentText>紀錄刪除後，將無法復原</DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <div className='flex w-full justify-center'>
          <Button onClick={submit} color='primary' endIcon={<Done />}>
            確定{isEdit ? '修改' : isDelete ? '刪除' : ''}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const Content = ({
  schema,
  data,
  isEdit,
  isDelete,
  isAdd,
  setInput,
  input,
}) => {
  return (
    <div className='flex flex-col gap-4 p-4'>
      {map(schema, (item) => {
        return (
          <Input
            key={item.id}
            schema={item}
            data={data?.[item?.id]}
            isEdit={isEdit}
            isDelete={isDelete}
            isAdd={isAdd}
            setInput={setInput}
            input={input}
          />
        );
      })}
    </div>
  );
};

const Input = ({ data, schema, setInput, isDelete, isEdit, isAdd, input }) => {
  const { id, type, label, editable, selectPath, free_solo } = schema;
  const value = useMemo(() => {
    let v = data;
    if (id in input) {
      v = input[id];
    }
    return v ? (type === 'date' ? dayjs(v) : v) : undefined;
  }, [data, type, input, id]);

  if (isAdd && editable === false && !selectPath) return null;

  if (isDelete || (editable === false && !isAdd)) {
    return (
      <div className='flex flex-col gap-2'>
        <div className='font-bold'>{label}</div>
        <div className='min-h-[24px]'>{formatData({ text: data, type })}</div>
      </div>
    );
  } else if (isEdit || isAdd) {
    return (
      <div className='flex flex-col gap-2'>
        <div className='font-bold'>{label}</div>
        <div className='min-h-[24px] flex items-center gap-2'>
          {type === 'date' ? (
            <DatePicker
              clearable
              value={value}
              slotProps={{
                actionBar: { actions: ['clear', 'today'] },
              }}
              onChange={(v) => {
                if (v) {
                  const date = dayjs(v);
                  if (date.isValid()) {
                    setInput({
                      data: date.toISOString(),
                      key: id,
                    });
                  }
                } else if (v === null) {
                  setInput({ key: id, data: null });
                }
              }}
            />
          ) : selectPath ? (
            <SelectField
              id={id}
              setInput={setInput}
              value={value}
              free_solo={free_solo}
              selectPath={selectPath}
            />
          ) : (
            <TextField
              value={value}
              onChange={(e) => {
                setInput({
                  data: e.target.value,
                  key: id,
                });
              }}
            />
          )}
        </div>
      </div>
    );
  }
};

const SelectField = ({ id, setInput, value, free_solo, selectPath }) => {
  const [options, setOptions] = useState([]);
  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    if (selectPath && !fetched) {
      get({ url: selectPath }).then(({ data, is200 }) => {
        setFetched(true);
        if (data && is200) {
          setOptions(data);
        }
      });
    }
  }, [options, selectPath]);
  return (
    <Autocomplete
      value={value || ''}
      autoWidth
      getOptionLabel={(v) => {
        if (isObject(v) && v.label) {
          return v.label;
        } else if (isString(v)) {
          const option = find(options, { _id: v });
          if (option) {
            return option.label;
          }
          return v;
        }
        return '';
      }}
      onChange={(e, v) => {
        setInput({
          key: id,
          data: v?._id,
        });
      }}
      style={{
        minWidth: 231,
      }}
      options={options}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
