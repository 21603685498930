import { atom, useSetAtom } from 'jotai';

export const _modal = atom({});

export const usePopup = () => {
	const setModal = useSetAtom(_modal);

	const popup = ({ children, onClose }) => {
		setModal({ children, onClose });
	};

	return {
		popup,
	};
};
