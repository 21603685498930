import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { atom, useAtom } from 'jotai';

import { get } from '../../../lib/request';

export const _data = atom([]);

export const _sort = atom(null);
export const _filters = atom(null);
export const _page = atom(null);
export const _model = atom(null);

const formatList = (data) => {
  return {
    schema: data.schema,
    metadata: data.data.metadata,
    records: data.data.records.map((d, id) => {
      return {
        ...d,
        id,
      };
    }),
  };
};

export const useStore = ({ url }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [prefix, setPrefix] = useState(false);
  const [data, setData] = useAtom(_data);
  const [model, setModel] = useAtom(_model);
  const [fieldsToDisplay, setFieldsToDisplay] = useState([]);
  const [headerNames, setHeaderNames] = useState([]);
  const [pageName, setPageName] = useState('');
  const [page, setPage] = useState(0);

  const [sort, setSort] = useAtom(_sort);
  const [filters, setFilters] = useAtom(_filters);

  const pagesize = 50;

  const fetch = async () => {
    setLoading(true);
    const result = await get({
      url: url || `/${model || prefix}/lists`,
      params,
    });

    if (result.is200 && result.data) {
      setData(formatList(result.data));
      setHeaderNames(
        result?.data?.pageConfig?.pages[pageName]?.fieldsToDisplay
      );
      setFieldsToDisplay(
        Object.keys(result?.data?.pageConfig?.pages[pageName]?.fieldsToDisplay)
      );
    }
    setLoading(false);
  };

  const currentPage = useMemo(() => {
    return data?.metadata?.page || 0;
  }, [data]);

  const params = useMemo(() => {
    if (page) {
      return {
        sort,
        page,
        pagesize,
        filters,
      };
    }
    return null;
  }, [sort, page, filters]);

  useEffect(() => {
    if ((url || prefix) && params && pageName) {
      fetch();
    }
  }, [prefix, params, pageName, model, url]);

  const initStore = ({ prefix, pageName, model, sort, filter }) => {
    setModel(model);
    setPrefix(prefix);
    setSort(sort);
    setFilters(filter);
    setPageName(pageName);
    setPage(page || parseInt(query.get('page')) || 1);
  };

  return {
    fetch,
    initStore,
    loading,
    data,
    setSort,
    setFilters,
    filters,
    pagesize,
    currentPage,
    setPage,
    fieldsToDisplay,
    headerNames,
  };
};
