import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import LogoutIcon from '@mui/icons-material/Logout';

import Username from './username';
import Logo from './logo';
import Items from './items';
import { Button } from '@mui/material';
import { useGlobalStore } from '../../lib/store';

export default function Sidebar() {
	const { logout } = useGlobalStore();
	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				variant='permanent'
				PaperProps={{
					sx: {
						backgroundColor: '#343a40',
						color: 'white',
					},
				}}
			>
				<Toolbar>
					<Logo />
				</Toolbar>
				<Box sx={{ overflow: 'auto' }}>
					<div className='flex flex-col'>
						<hr className='border-zinc-500' />
						<Username />
						<hr className='border-zinc-500' />
						<div className='grow'>
							<Items />
						</div>
						<div className='text-center mt-4'>
							<Button
								onClick={logout}
								variant='outlined'
								endIcon={<LogoutIcon />}
							>
								登出
							</Button>
						</div>
					</div>
				</Box>
			</Drawer>
		</Box>
	);
}
