import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { get, post } from '../../lib/request';
import To from '../../components/common/to';
import { Settings } from '@mui/icons-material';

function MetaData({ data }) {
  const [createdBy, setCreatedBy] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');

  useEffect(() => {
    if (data?.created_by) {
      get({
        url: `/user/get/${data.created_by}`,
      }).then(({ data }) => {
        const { display_name, username } = data?.record || {};
        setCreatedBy(display_name || username);
      });
    }
    if (data?.updated_by) {
      get({
        url: `/user/get/${data.updated_by}`,
      }).then(({ data }) => {
        const { display_name, username } = data?.record || {};
        setUpdatedBy(display_name || username);
      });
    }
  }, [data]);
  console.log('data111', data);
  if (data?.createdAt) {
    return (
      <div className='text-gray-400'>
        <div className=''>
          <div>
            created: {createdBy} (
            {dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss')})
          </div>
          <div>
            {updatedBy
              ? `updated: ${updatedBy} (${dayjs(data.updatedAt).format(
                  'YYYY-MM-DD HH:mm:ss'
                )})`
              : ''}
          </div>
          <div className='text-sm'>
            <To url={`/log?doc_id=${data?._id}`} toNew>
              <Settings />
              log record
            </To>
          </div>
        </div>
      </div>
    );
  }
}

export default MetaData;
