import React, { useMemo } from 'react';
import {
  Page,
  Font,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import NotoSansTC from '../../fonts/NotoSansTC-Regular.otf';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useGlobalStore } from '../../lib/store';
import { get } from '../../lib/request';

Font.register({
  family: 'Noto Sans TC',
  src: NotoSansTC,
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Noto Sans TC',
    paddingTop: 35,
    paddingBottom: 85,
    paddingHorizontal: 35,
    fontSize: 12,
  },
  paragraph: {
    marginBottom: 12,
  },
  header: {
    fontSize: 24,
    marginBottom: 22,
  },
  footer: {
    position: 'absolute',
    bottom: 45,
    paddingHorizontal: 35,
    fontSize: 10,
  },
  logoImage: {
    height: 80,
    objectFit: 'contain',
    objectPosition: '0',
  },

  table: {
    // width: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    borderBottom: '1px solid #000',
    fontSize: 10,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
    borderTop: '1px solid #000',
    verticalAlign: 'bottom',
    alignItems: 'center',
    justifyContent: 'center',
  },
  test: {
    alignSelf: 'center',
  },
  cell: {
    borderRight: '1px solid #000',
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    paddingVertical: 1,
    paddingHorizontal: 2,
  },
  cellNum: {
    borderLeft: '1px solid #000',
    // borderRight: '1px solid #000',
    flexBasis: 22,
    textAlign: 'right',
  },
  cellRegMark: {
    flexBasis: 62,
  },
  cellChassis: {
    flexBasis: 130,
  },
  cellWeight: {
    flexBasis: 42,
    textAlign: 'center',
  },
  driverInfo: {
    textDecoration: 'underline',
  },

  signature: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  signatureTitle: {
    paddingRight: 15,
  },
  signatureTitleText: {
    height: 20,
    textAlign: 'right',
    width: 200,
  },
  signatureTitleImage: {
    paddingRight: 15,
    height: 60,
  },
  signatureLine: {
    borderBottom: '1px solid black',
    width: 230,
    height: 20,
    textAlign: 'center',
  },
  signatureLineImageLine: {
    width: 230,
    height: 58,
  },
  signatureLineImage: {
    height: 70,
    width: 230,
    position: 'absolute',
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
  },
  signatureImage: {
    width: '40%',
    objectFit: 'contain',
    marginHorizontal: '1%',
    objectPosition: '100%',
  },
  chopImage: { height: 70, width: 70 },
});

const domain =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8002'
    : window.location.origin;

const MyDocument = ({ data }) => {
  const { setShowSidebar, setShowTopnav } = useGlobalStore();
  const [company, setCompany] = useState('');
  const [driver, setDriver] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [applicationDate, setApplicationDate] = useState('');
  useEffect(() => {
    // setShowSidebar(false);
    // setShowTopnav(false);
  }, []);

  useEffect(() => {
    const { vehicles, driver, company, application_date } = data;
    setVehicles(vehicles);
    setDriver(driver);
    setCompany(company);
    if (application_date) {
      setApplicationDate(dayjs(application_date).format('YYYY-MM-DD'));
    }
  }, [data]);

  useEffect(() => {
    document.title = `駕駛許可申請`;
  }, []);

  const logoImageUrl = useMemo(() => {
    if (company && company?.logo_image?.name) {
      return `${domain}/file/${company?.logo_image?.name}`;
    }
    return null;
  }, [company]);

  const signImageUrl = useMemo(() => {
    if (company && company?.sign_image?.name) {
      return `${domain}/file/${company?.sign_image?.name}`;
    }
    return null;
  }, [company]);

  const chopImageUrl = useMemo(() => {
    if (company && company?.chop_image?.name) {
      return `${domain}/file/${company?.chop_image?.name}`;
    }
    return null;
  }, [company]);

  const record = vehicles.reduce((acc, current, index) => {
    if (index % 2 === 0) {
      acc.push([current]);
    } else {
      acc[acc.length - 1].push(current);
    }
    return acc;
  }, []);

  return (
    <Document title='駕駛許可'>
      <Page size='A4' style={styles.page}>
        <View style={styles.header} fixed>
          {logoImageUrl ? (
            <Image style={styles.logoImage} src={logoImageUrl} />
          ) : (
            <Text>{company?.name_tc && <Text>{company.name_tc}</Text>}</Text>
          )}
        </View>
        <View style={styles.body}>
          <Text>致運輸署署長：</Text>
          <Text style={styles.paragraph}>
            <Text>本公司乃以下車輛之登記車主，現擬推薦 </Text>
            <Text style={styles.driverInfo}>
              {driver?.name_tc || driver?.name}[身份證號碼：
              {driver?.hkid}]
            </Text>
            <Text>，資料如下：</Text>
          </Text>
          <View style={[styles.table, styles.paragraph]}>
            <View style={[styles.tableRow, styles.tableHeader]} wrap={false}>
              <Text style={[styles.cell, styles.cellNum]}>序號</Text>
              <Text style={[styles.cell, styles.cellRegMark]}>車牌號碼</Text>
              <Text style={[styles.cell, styles.cellChassis]}>底盤號碼</Text>
              <Text style={[styles.cell, styles.cellWeight]}>
                許可 車輛總重
              </Text>
              {/* for 2 vehicles per row */}
              {vehicles.length > 1 && (
                <>
                  <Text style={[styles.cell, styles.cellNum]}>序號</Text>
                  <Text style={[styles.cell, styles.cellRegMark]}>
                    車牌號碼
                  </Text>
                  <Text style={[styles.cell, styles.cellChassis]}>
                    底盤號碼
                  </Text>
                  <Text style={[styles.cell, styles.cellWeight]}>
                    許可 車輛總重
                  </Text>
                </>
              )}
            </View>
            {/* for 2 vehicles per row */}
            {record.map((d, index) => {
              return (
                <View style={[styles.tableRow]} wrap={false}>
                  {d.map((vehicle, rowIndex) => {
                    const num = index * 2 + rowIndex + 1;
                    return (
                      <>
                        <Text style={[styles.cell, styles.cellNum]}>{num}</Text>
                        <Text style={[styles.cell, styles.cellRegMark]}>
                          {vehicle?.current_reg_mark?.reg_mark}
                        </Text>
                        <Text style={[styles.cell, styles.cellChassis]}>
                          {vehicle?.chassis_number}
                        </Text>
                        <Text style={[styles.cell, styles.cellWeight]}>
                          {vehicle?.gross_vehicle_weight}
                        </Text>
                      </>
                    );
                  })}
                  {record.length > 1 &&
                    record.length - 1 === index &&
                    d.length % 2 === 1 && (
                      <>
                        <Text style={[styles.cell, styles.cellNum]}></Text>
                        <Text style={[styles.cell, styles.cellRegMark]}></Text>
                        <Text style={[styles.cell, styles.cellChassis]}></Text>
                        <Text style={[styles.cell, styles.cellWeight]}></Text>
                      </>
                    )}
                </View>
              );
            })}

            {/* for 1 vehicles per row */}
            {/* {vehicles.map((d, index) => {
              const num = index + 1;
              return (
                <View style={[styles.tableRow]} wrap={false} key={index}>
                  <>
                    <Text style={[styles.cellTitle]}>
                      {num}. {d?.current_reg_mark?.reg_mark}
                    </Text>
                    <Text style={[styles.cell]}>{d?.chassis_number}</Text>
                    <Text style={[styles.cell]}>{d?.gross_vehicle_weight}</Text>
                  </>
                </View>
              );
            })} */}
          </View>
          <View style={styles.paragraph}>
            <Text>本公司謹此聲明及確定:</Text>
            <Text>
              （一）上述司機持有與所申請的特別用途車輛總重量相符之車輛類別的有效正式駕駛執照
              ;及
            </Text>
            <Text>（二）上述司機有工作需要駕駛上述特別用途車輛。</Text>
          </View>
          <View style={styles.paragraph}>
            <Text>
              本人明白所簽發的特別用途車輛駕駛資格只批准上述司機駕駛有關的駕駛用途車輛；
              並會按需要，
            </Text>
            <Text>
              隨函附上上述車輛的車輛登記文件副本及上述司機的駕駛執照副本。
            </Text>
          </View>
          <View style={styles.signature} wrap={false}>
            <View style={styles.signatureTitle}>
              <Text style={styles.signatureTitleImage}></Text>
              <Text style={styles.signatureTitleText}>登記車主簽名 :</Text>
              <Text style={styles.signatureTitleText}>姓名人 (請用正階) :</Text>
              <Text style={styles.signatureTitleText}>電話號碼 :</Text>
              <Text style={styles.signatureTitleText}>有限公司註冊號碼 :</Text>
              <Text style={styles.signatureTitleText}>日期 :</Text>
            </View>
            <View style={[styles.signatureLineImage]}>
              {signImageUrl && (
                <Image style={styles.signatureImage} src={signImageUrl} />
              )}
              {chopImageUrl && (
                <Image style={styles.signatureImage} src={chopImageUrl} />
              )}
            </View>
            <View>
              <Text style={styles.signatureLineImageLine}></Text>
              <Text style={styles.signatureLine}></Text>
              <Text style={styles.signatureLine}>{company?.name_tc}</Text>
              <Text style={styles.signatureLine}>
                {company?.contact_number}
              </Text>
              <Text style={styles.signatureLine}>{company?.reg_number}</Text>
              <Text style={styles.signatureLine}>{applicationDate}</Text>
            </View>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <Text>{company?.address ? `${company.address}` : ''}</Text>
          <Text>
            {company?.contact_number ? `Tel: ${company.contact_number}` : ''}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const MyApp = ({ data }) => {
  if (!data) return null;
  return (
    <PDFDownloadLink
      document={<MyDocument data={data} />}
      fileName={`${document.title}.pdf`}
    >
      {({ blob, url, loading, error }) => (loading ? '加載中' : '下載表格')}
    </PDFDownloadLink>
    // <PDFViewer width='100%' height='850px'>
    //   <MyDocument _id={_id} />
    // </PDFViewer>
  );
};

export default MyApp;
