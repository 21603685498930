import React, { useContext, useState, useCallback, useEffect } from 'react';
import { isEmpty, isEqual, uniqWith, isObject } from 'lodash';
import { usePath } from 'lib/store';

import Switcher from 'components/common/input/switcher';
import Status from 'components/status';
import {
  Section,
  VehicleSection,
  ContractSection,
  RegMarkSection,
} from 'components/common/tabs/section-block';
import MetaData from 'components/meta-data';
import { get } from 'lib/request';

const useOptions = () => {
  const [contractOptions, setContractOptions] = useState(null);
  const [contractPreset, setContractPreset] = useState(null);
  const [regMarkOptions, setRegMarkOptions] = useState(null);
  const [regMarkPreset, setRegMarkPreset] = useState(null);
  const [vehicle, setVehicle] = useState(null);

  const vehicleOnChange = useCallback(
    (v) => {
      const id = isObject(v) ? v?._id : v;
      if (id && id !== vehicle) {
        get({
          url: `/vehicle/get/${id}`,
        }).then(({ data }) => {
          // setContractOptions(
          //   uniqWith(
          //     data?.record?.contracts.map((contract) => {
          //       return {
          //         _id: contract?.contract._id,
          //         label: contract?.contract?.contract_number,
          //       };
          //     }),
          //     isEqual
          //   )
          // );
          setRegMarkOptions(
            uniqWith(
              data?.record?.reg_marks.map((reg_mark) => {
                return {
                  _id: reg_mark?.reg_mark?._id,
                  label: reg_mark?.reg_mark?.reg_mark,
                };
              }),
              isEqual
            )
          );
          setContractPreset(data?.record?.contracts[0]?.contract?._id);
          setRegMarkPreset(data?.record?.current_reg_mark?.reg_mark?._id);
          setVehicle(id);
        });
      }
    },
    [vehicle]
  );
  return {
    contractOptions,
    contractPreset,
    regMarkOptions,
    regMarkPreset,
    vehicleOnChange,
  };
};

function ProfileGeneral({ context }) {
  const {
    isEdit,
    prefix,
    data,
    schema,
    setInputs,
    saveBtnClicked,
    pushItemToField,
    fieldsToDisplay,
  } = useContext(context);
  const path = usePath();
  const {
    contractOptions,
    contractPreset,
    regMarkPreset,
    regMarkOptions,
    vehicleOnChange,
  } = useOptions();

  const vehicle = path.get('vehicle');

  useEffect(() => {
    if (isEdit && data?.current_vehicle?.vehicle?._id) {
      vehicleOnChange(data?.current_vehicle?.vehicle?._id);
    } else if (vehicle) {
      vehicleOnChange(vehicle);
    }
  }, [isEdit, data]);

  if (isEmpty(schema)) return null;
  return (
    <div className='flex gap-4 py-10' role='tabpanel'>
      <div className='_p-8 _shadow-md _bg-white grow flex flex-col gap-4'>
        <div>
          <Section>
            {fieldsToDisplay.map((field) => {
              if (field !== 'status') {
                return (
                  <Switcher
                    key={field}
                    schema={{
                      ...schema[field],
                      editable: isEdit ? schema[field]?.editable : true,
                    }}
                    value={data[field]}
                    setInputs={setInputs}
                    saveBtnClicked={saveBtnClicked}
                    field={field}
                    pushItemToField={pushItemToField}
                    suggestEndpoint={`/${prefix}/column/${field}`}
                  />
                );
              }
              return null;
            })}
          </Section>
        </div>
        <MetaData data={data} />
      </div>
      <div className='w-6/12 xl:w-5/12 2xl:w-4/12 flex flex-col gap-4 shrink-0'>
        {/* <Status data={data} setInputs={setInputs} /> */}
        <VehicleSection
          current_vehicle={data?.current_vehicle}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          readonly={
            isEdit ? data?.current_vehicle?.vehicle?.chassis_number : false
          }
          exclude={['effective_date', 'end_date']}
          preset={{
            selected: vehicle,
          }}
          // events={{
          //   vehicle: {
          //     onChange: vehicleOnChange,
          //   },
          // }}
          isEdit={isEdit}
        />

        <ContractSection
          current_contract={data}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          exclude={['effective_date', 'end_date']}
          isEdit={isEdit}
          preset={{
            contract: {
              options: contractOptions,
              selected: !data?.contract?._id ? contractPreset : null,
            },
          }}
          title='扣數合約'
        />

        <RegMarkSection
          current_reg_mark={data}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          exclude={['effective_date', 'end_date']}
          isEdit={isEdit}
          preset={{
            reg_mark: {
              options: regMarkOptions,
              selected: !data?.reg_mark?._id ? regMarkPreset : null,
            },
          }}
        />
      </div>
    </div>
  );
}

export default ProfileGeneral;
