import React, { useMemo, useContext } from 'react';
import { isArray } from 'lodash';

import Table from '../../../../components/common/table';
import TabTable from 'components/common/tabs/table';

function Insurances({ context, fieldsToDisplay }) {
  const { data } = useContext(context);

  const rows = useMemo(() => {
    if (data?.insurances && isArray(data?.insurances)) {
      return data.insurances.map((item, index) => {
        return {
          id: index,
          _id: item?.insurance?._id,
          insurance_company: item?.insurance?.insurance_company,
          insurance_kind: item?.insurance?.insurance_kind,
          policy_number: item?.insurance?.policy_number,
          policy_number2: item?.insurance?.policy_number2,
          insurance_fee: item?.insurance?.insurance_fee,
          effective_date: item?.insurance?.effective_date,
          end_date: item?.insurance?.end_date,
          objectId: item?._id,
          contract_number: item?.insurance?.contract?.contract_number,
          reg_mark: item?.insurance?.reg_mark?.reg_mark,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <TabTable
      schema={[
        {
          id: 'insurance_company',
          label: '投保公司',
        },
        {
          id: 'insurance_kind',
          label: '保單種類',
        },
        {
          id: 'policy_number',
          label: '保單編號',
        },
        {
          id: 'policy_number2',
          label: '保單編號(中港)',
        },
        {
          id: 'insurance_fee',
          label: '保費',
          show_dollar: true,
        },
        {
          id: 'effective_date',
          label: '承保日期',
          type: 'date',
        },
        {
          id: 'end_date',
          label: '結束日期',
          type: 'date',
        },
        {
          id: 'reg_mark',
          label: '車牌',
        },
        {
          id: 'contract_number',
          label: '扣數合約',
        },
      ]}
      setting={{
        popup: false,
        collection: 'vehicle',
        keyName: 'insurance',
        orderBy: 'effective_date',
        copyable: true,
      }}
      collectionId={data._id}
      rows={rows}
    />
  );
}

export default Insurances;
