import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  find,
  forEach,
  isEmpty,
  isNull,
  map,
  reduce,
  get as _get,
} from 'lodash';

import ImportCsv from '../../../components/common/importcsv';
import { useGlobalStore } from '../../../lib/store';
import { get, post } from '../../../lib/request';
import { redirect, toISOString } from '../../../lib/config';
import { usePermissions } from 'lib/permissions';

const Upload = ({ prefix, title, fieldsToDisplay }) => {
  const [error, setError] = useState({});
  const [value, setValue] = useState([]);
  const [vehicles, setVehicles] = useState(null);
  const [schema, setSchema] = useState(null);

  const { setDefaultTopnav } = useGlobalStore();
  const { isWritable } = usePermissions();

  const customError = useCallback(({ msg, field, value, currentRow }) => {
    return msg;
  }, []);

  const customWarning = useCallback(({ msg, field, value, currentRow }) => {
    return msg;
  }, []);

  const writable = isWritable({ collection: prefix });

  const canSave = useMemo(() => {
    return !isEmpty(value) && writable;
  }, [value, writable]);

  useEffect(() => {
    if (isNull(vehicles) && !isEmpty(value) && prefix) {
      get({ url: `/vehicle/all-vehicle-select?statusless=true` }).then(
        ({ is200, data }) => {
          if (is200) {
            setVehicles(data);
          }
        }
      );
    }
  }, [value, vehicles, prefix]);

  useEffect(() => {
    get({ url: `/${prefix}/schema` }).then(({ is200, data }) => {
      if (is200) {
        const { schema } = data;
        if (schema) {
          setSchema(schema);
        }
      }
    });
  }, [prefix]);

  const save = useCallback(async () => {
    const body = value;

    console.log({ body });

    const result = await post({
      url: `/${prefix}/import`,
      isImport: true,
      body,
    });

    console.log(result);
    if (result && result.is200 && result.data) {
      redirect(
        `/${prefix}?alert=${encodeURIComponent(
          `${result.data.success} record(s) Created/Updated!`
        )}`
      );
    }
  }, [prefix, value, vehicles]);

  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      canSave,
      save,
    });
  }, [prefix, canSave, save]);

  if (isEmpty(schema)) return null;
  return (
    <ImportCsv
      fieldsToDisplay={fieldsToDisplay}
      schema={schema}
      setError={setError}
      setValue={setValue}
      value={value}
      customError={customError}
      customWarning={customWarning}
    />
  );
};
export default Upload;
