import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Index from '../../components/pages/listing';
import Profile from './profile';
import Upload from './upload';

import { useGlobalStore } from '../../lib/store';

const fieldsToDisplay = ['autotoll_number'];

function Page({ mode, prefix, title, sidebarItem, newEntry, titleIcon }) {
  const { _id } = useParams();
  const { setActiveSidebarItem } = useGlobalStore();
  useEffect(() => {
    setActiveSidebarItem(sidebarItem);
  }, []);
  const isHome = mode === 'home';
  const isImport = mode === 'import';

  return (
    <div className='w-full h-full'>
      {isHome ? (
        <Index
          prefix={prefix}
          title={title}
          newEntry={newEntry}
          titleIcon={titleIcon}
        />
      ) : isImport ? (
        <Upload
          prefix={prefix}
          title={title}
          fieldsToDisplay={fieldsToDisplay}
          titleIcon={titleIcon}
        />
      ) : (
        <Profile
          _id={_id}
          mode={mode}
          prefix={prefix}
          title={title}
          titleIcon={titleIcon}
        />
      )}
    </div>
  );
}

export default Page;
