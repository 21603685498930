import { Divider, IconButton } from '@mui/material';
import { Add, Edit, Search } from '@mui/icons-material';
import Switcher from '../input/switcher';
import To from '../to';

export const Section = ({ children }) => {
  return (
    <div className='p-8 shadow-md bg-white grow flex flex-col gap-4'>
      {children}
    </div>
  );
};

export const RelationSection = ({ title, isEdit, url, children }) => {
  return (
    <Section>
      <div className='flex justify-between items-end mt-[-16px]'>
        <div className='text-2xl'>{title}</div>
        <div className=''>
          {url && (
            <To url={url} toNew>
              <IconButton>{isEdit ? <Search /> : ''}</IconButton>
            </To>
          )}
        </div>
      </div>
      <Divider />
      {children}
    </Section>
  );
};

export const VehicleSection = ({
  current_vehicle = null,
  setInputs,
  saveBtnClicked,
  readonly,
  isEdit,
  exclude = [],
  preset = {},
  events,
}) => {
  const { vehicle, effective_date, end_date } = current_vehicle || {};
  return (
    <div>
      <RelationSection
        title='車輛/機械'
        isEdit={vehicle?._id}
        url={vehicle?._id && `/vehicle/${vehicle?._id || ''}`}
      >
        <Switcher
          value={
            current_vehicle
              ? {
                  _id: vehicle?._id,
                  label: vehicle?.chassis_number,
                }
              : undefined
          }
          field={'input__vehicle_id'}
          setInputs={setInputs}
          suggestEndpoint={`/vehicle/all-vehicle-select?filters=_id^!=${
            vehicle?._id || ''
          }`}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: readonly && isEdit ? 'readonly' : 'text',
            title: '底盤號碼',
            select: true,
          }}
          metadata={{ preset }}
          events={events?.vehicle}
        />
        {!exclude.includes('effective_date') && (
          <Switcher
            value={effective_date}
            field={'input__vehicle_effective_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: readonly && isEdit ? 'readonly' : 'date',
              title: '生效日期',
              is_required: effective_date,
            }}
          />
        )}
        {!exclude.includes('end_date') && (
          <Switcher
            value={end_date}
            field={'input__vehicle_end_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: readonly && isEdit ? 'readonly' : 'date',
              title: '結束日期',
              is_required: end_date,
            }}
          />
        )}
      </RelationSection>
    </div>
  );
};

export const ExpenseVehicleSection = ({
  current_vehicle = null,
  setInputs,
  saveBtnClicked,
  readonly,
  isEdit,
  exclude = [],
  preset = {},
  events,
}) => {
  const { vehicle, effective_date, end_date } = current_vehicle || {};
  if (!current_vehicle) {
    return null;
  }
  return (
    <div>
      <RelationSection
        title='車輛/機械'
        isEdit={current_vehicle}
        url={current_vehicle && `/vehicle/${current_vehicle._id || ''}`}
      >
        <Switcher
          value={
            current_vehicle
              ? {
                  _id: current_vehicle?._id,
                  label: current_vehicle?.chassis_number,
                }
              : undefined
          }
          field={'input__vehicle_id'}
          setInputs={setInputs}
          suggestEndpoint={`/vehicle/all-vehicle-select?filters=_id^!=${
            current_vehicle || ''
          }`}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: readonly && isEdit ? 'readonly' : 'text',
            title: '底盤號碼',
            select: true,
          }}
          metadata={{ preset }}
          events={events?.current_vehicle}
        />
        {!exclude.includes('effective_date') && (
          <Switcher
            value={effective_date}
            field={'input__vehicle_effective_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: readonly && isEdit ? 'readonly' : 'date',
              title: '生效日期',
              is_required: effective_date,
            }}
          />
        )}
        {!exclude.includes('end_date') && (
          <Switcher
            value={end_date}
            field={'input__vehicle_end_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: readonly && isEdit ? 'readonly' : 'date',
              title: '結束日期',
              is_required: end_date,
            }}
          />
        )}
      </RelationSection>
    </div>
  );
};

export const WarehouseSection = ({
  warehouse = null,
  setInputs,
  saveBtnClicked,
  readonly,
  isEdit,
  exclude = [],
  preset = {},
  events,
}) => {
  const { vehicle, effective_date, end_date } = warehouse || {};
  console.log('warehouse', warehouse);
  if (!warehouse) {
    return null;
  }
  return (
    <div>
      <RelationSection
        title='油倉'
        isEdit={warehouse}
        url={warehouse && `/warehouse/${warehouse?._id || ''}`}
      >
        <Switcher
          value={
            warehouse
              ? {
                  _id: warehouse?._id,
                  label: warehouse?.title,
                }
              : undefined
          }
          field={'input__vehicle_id'}
          setInputs={setInputs}
          suggestEndpoint={`/vehicle/all-vehicle-select?filters=_id^!=${
            warehouse || ''
          }`}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: readonly && isEdit ? 'readonly' : 'text',
            title: '標題',
            select: true,
          }}
          metadata={{ preset }}
          events={events?.warehouse}
        />
      </RelationSection>
    </div>
  );
};

export const RegMarkSection = ({
  current_reg_mark = null,
  setInputs,
  saveBtnClicked,
  readonly,
  isEdit,
  exclude = [],
  title = '車牌',
  preset,
}) => {
  const { reg_mark, effective_date, end_date } = current_reg_mark || {};
  return (
    <div>
      <RelationSection
        title={title}
        isEdit={reg_mark?._id}
        url={reg_mark?._id && `/reg_mark/${reg_mark?._id || ''}`}
      >
        <Switcher
          value={
            current_reg_mark
              ? {
                  _id: reg_mark?._id,
                  label: reg_mark?.reg_mark,
                }
              : undefined
          }
          field={'input__reg_mark_id'}
          setInputs={setInputs}
          suggestEndpoint={`/reg_mark/all-reg_mark-select`}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: isEdit && readonly ? 'readonly' : 'text',
            title: '車牌',
            select: true,
          }}
          metadata={{ preset: preset ? preset.reg_mark : '' }}
        />
        {!exclude.includes('effective_date') && (
          <Switcher
            value={effective_date}
            field={'input__reg_mark_effective_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: isEdit && readonly ? 'readonly' : 'date',
              title: '有效日期',
              is_required: effective_date,
            }}
          />
        )}
        {!exclude.includes('end_date') && (
          <Switcher
            value={end_date}
            field={'input__reg_mark_end_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: isEdit && readonly ? 'readonly' : 'date',
              title: '完結日期',
              is_required: end_date,
            }}
          />
        )}
      </RelationSection>
    </div>
  );
};

export const CompanySection = ({
  current_company = null,
  setInputs,
  saveBtnClicked,
  readonly,
  isEdit,
  exclude = [],
  title = '公司',
  preset,
}) => {
  const { company, effective_date, end_date } = current_company || {};
  return (
    <div>
      <RelationSection
        title={title}
        isEdit={company?._id}
        url={company?._id && `/company/${company?._id || ''}`}
      >
        <Switcher
          value={
            current_company
              ? {
                  _id: company?._id,
                  label: company?.short_name || company?.name_tc,
                }
              : undefined
          }
          field={'input__company_id'}
          setInputs={setInputs}
          suggestEndpoint={`/company/all-company-select`}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: isEdit && readonly ? 'readonly' : 'text',
            title: '公司名稱',
            select: true,
          }}
          metadata={{ preset: preset ? preset.company : '' }}
        />
        {!exclude.includes('effective_date') && (
          <Switcher
            value={effective_date}
            field={'input__company_effective_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: isEdit && readonly ? 'readonly' : 'date',
              title: 'Company effective date',
              is_required: effective_date,
            }}
          />
        )}
        {!exclude.includes('end_date') && (
          <Switcher
            value={end_date}
            field={'input__company_end_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: isEdit && readonly ? 'readonly' : 'date',
              title: 'Company end date',
              is_required: end_date,
            }}
          />
        )}
      </RelationSection>
    </div>
  );
};

export const ExpensesImportDetailSection = ({
  data = null,
  title = '滙入細明',
}) => {
  return (
    <div>
      <RelationSection
        title={title}
        isEdit={data?._id}
        url={data?._id && `/expenses_batch/${data?._id || ''}`}
      ></RelationSection>
    </div>
  );
};

export const ContractSection = ({
  current_contract = null,
  setInputs,
  saveBtnClicked,
  readonly,
  isEdit,
  exclude = [],
  title = '合約',
  preset,
}) => {
  const { contract, effective_date, end_date } = current_contract || {};
  return (
    <div>
      <RelationSection
        title={title}
        isEdit={contract?._id}
        url={contract?._id && `/contract/${contract?._id || ''}`}
      >
        <Switcher
          value={
            current_contract
              ? {
                  _id: contract?._id,
                  label: contract?.contract_number,
                }
              : undefined
          }
          field={'input__contract_id'}
          setInputs={setInputs}
          suggestEndpoint={`/contract/all-contract-select`}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: isEdit && readonly ? 'readonly' : 'text',
            title: '合約編號',
            select: true,
          }}
          metadata={{ preset: preset ? preset.contract : '' }}
        />
        {!exclude.includes('effective_date') && (
          <Switcher
            value={effective_date}
            field={'input__contract_effective_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: isEdit && readonly ? 'readonly' : 'date',
              title: 'Contract effective date',
              is_required: effective_date,
            }}
          />
        )}
        {!exclude.includes('end_date') && (
          <Switcher
            value={end_date}
            field={'input__contract_end_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: isEdit && readonly ? 'readonly' : 'date',
              title: 'Contract end date',
              is_required: end_date,
            }}
          />
        )}
      </RelationSection>
    </div>
  );
};

export const ContractDeductSection = ({
  current_contract_deduct = {},
  setInputs,
  saveBtnClicked,
  readonly,
  isEdit,
  exclude = [],
  title = '扣數合約',
}) => {
  const { contract_deduct, effective_date, end_date } = current_contract_deduct;
  return (
    <div>
      <RelationSection
        title={title}
        isEdit={current_contract_deduct?._id}
        url={
          current_contract_deduct?._id &&
          `/contract_deduct/${current_contract_deduct?._id || ''}`
        }
      >
        <Switcher
          value={
            contract_deduct?._id
              ? {
                  _id: contract_deduct?._id,
                  label: contract_deduct?.contract_number,
                }
              : undefined
          }
          field={'input__contract_deduct_id'}
          setInputs={setInputs}
          suggestEndpoint={`/contract_deduct/all-contract_deduct-select`}
          saveBtnClicked={saveBtnClicked}
          schema={{
            type: isEdit && readonly ? 'readonly' : 'text',
            title: '合約編號',
            select: true,
          }}
        />
        {!exclude.includes('effective_date') && (
          <Switcher
            value={current_contract_deduct?.effective_date}
            field={'input__contract_deduct_effective_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: 'date',
              title: '生效日期',
            }}
          />
        )}
        {!exclude.includes('end_date') && (
          <Switcher
            value={current_contract_deduct?.end_date}
            field={'input__contract_deduct_end_date'}
            setInputs={setInputs}
            saveBtnClicked={saveBtnClicked}
            schema={{
              type: 'date',
              title: '結束日期',
            }}
          />
        )}
      </RelationSection>
    </div>
  );
};

export const TextSection = ({
  custom_header = null,
  custom_title = null,
  custom_value = null,
}) => {
  return (
    <div>
      <RelationSection title={custom_header}>
        <Switcher
          value={custom_value}
          schema={{
            // type: readonly && isEdit ? 'readonly' : 'text',
            type: 'readonly',
            title: `${custom_title}`,
          }}
        />
      </RelationSection>
    </div>
  );
};
