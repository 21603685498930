import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { atom, useAtom } from 'jotai';

export const _active = atom(false);

export const useFullScreenLoading = () => {
  const [active, setActive] = useAtom(_active);

  return {
    active,
    setActive,
  };
};

export default function FullScreenLoading() {
  const { active } = useFullScreenLoading();

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={active}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}
