import React from 'react';
import { useAtom } from 'jotai';
import { Modal } from '@mui/material';

import { _modal } from './store';
import { isEmpty } from 'lodash';
function Popup() {
	const [modal, setModal] = useAtom(_modal);
	const open = !isEmpty(modal);

	if (!open) return null;
	return (
		<Modal open={open} onClose={() => setModal({})}>
			{modal.children}
		</Modal>
	);
}

export default Popup;
