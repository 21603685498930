import dayjs from 'dayjs';
import { forEach } from 'lodash';

export const basename = '/admin';
export const isDev = process.env.NODE_ENV === 'development';

export const backend = isDev ? 'http://localhost:8002' : '';
export const backendPrefix = '/api';

export const redirect = (url, toNew) => {
  const path = `${basename}${url}`;
  if (!toNew) {
    window.location.href = path;
  } else {
    window.open(path, '_blank');
  }
};

export const redirectWithQuery = ({ query, hash }) => {
  const queryParams = new URLSearchParams(window.location.search);
  forEach(query, (value, key) => {
    queryParams.set(key, value);
  });

  const newSearch = queryParams.toString();
  window.location.href = `${window.location.pathname}?${newSearch}#${hash}`;
  window.location.reload();
};
export const flattenObject = (obj) => {
  const result = {};

  function recursiveFlatten(obj, prefix = '') {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          recursiveFlatten(obj[key], newKey);
        } else {
          result[newKey] = obj[key];
        }
      }
    }
  }

  recursiveFlatten(obj);
  return result;
};

export const isISODateString = (str) => {
  try {
    const day = new Date(str);
    return day.toISOString() === str;
  } catch {
    return false;
  }
};

export const toISOString = (str) => {
  try {
    if (str) {
      const day = dayjs(str);
      if (day.isValid()) {
        return day.toISOString();
      }
    }
    return undefined;
  } catch {
    return undefined;
  }
};

export const parseISODateString = (str) => {
  try {
    if (isISODateString(str)) {
      const day = dayjs(str);
      if (day.isValid()) {
        return day.format('YYYY-MM-DD');
      }
    }
    return str;
  } catch (err) {
    return str;
  }
};
