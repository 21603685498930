import React, { useEffect } from 'react';

function WithLabel({ label, field, children, ...props }) {
  return (
    <div className='flex'>
      <div className='2xl:w-52 xl:w-40 w-36 text-lg bold mt-1 shrink-0'>
        <label htmlFor={field}>{label}</label>
      </div>
      {children}
    </div>
  );
}

export default WithLabel;
