import axios from 'axios';
import { useState } from 'react';
import { backend, backendPrefix } from './config';
import { isUndefined } from 'lodash';

axios.defaults.withCredentials = true;

const is200 = (status) => status === 200;

export const get = async ({ url, prefix = backendPrefix, params = {} }) => {
  console.log(`fetching... ${prefix}${url}`);
  try {
    const result = await axios
      .get(`${backend}${prefix}${url}`, {
        params,
        headers: { testing: 'true' },
      })
      .then(({ data, status }) => {
        return {
          status,
          data,
          is200: is200(status),
        };
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const post = async ({
  url,
  prefix = backendPrefix,
  body = {},
  isImport = false,
}) => {
  console.log(`[POST] fetching... ${prefix}${url}`);
  try {
    const formData = new FormData();
    if (isImport) {
      formData.append('body', JSON.stringify(body));
    } else {
      for (const key in body) {
        if (!isUndefined(body[key])) {
          formData.append(key, JSON.stringify(body[key]));
        }
      }
    }
    const result = await axios
      .post(`${backend}${prefix}${url}`, formData, {
        headers: { testing: 'true', 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data, status }) => {
        if (!status) return null;
        return {
          status,
          data,
          is200: is200(status),
        };
      })
      .catch((data) => {
        console.error(data);
        return { is200: false, data: data?.response?.data };
      });
    return result;
  } catch (err) {
    console.error(err);
    return { is200: false };
  }
};

export const put = async ({ url, prefix = backendPrefix, body = {} }) => {
  console.log(`[PUT] fetching... ${prefix}${url}`);
  try {
    const formData = new FormData();
    for (const key in body) {
      if (!isUndefined(body[key])) {
        if (
          key == 'chop_image' ||
          key == 'sign_image' ||
          key == 'logo_image' ||
          key == 'approval_file'
        ) {
          formData.append(key, body[key]);
        } else {
          formData.append(key, JSON.stringify(body[key]));
        }
      }
    }
    const result = await axios
      .put(`${backend}${prefix}${url}`, formData, {
        headers: { testing: 'true', 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data, status }) => {
        if (!status) return null;
        return {
          status,
          data,
          is200: is200(status),
        };
      })
      .catch((err) => {
        console.error(err);
        return { is200: false };
      });
    return result;
  } catch (err) {
    console.error(err);
    return { is200: false };
  }
};

export const del = async ({ url, prefix = backendPrefix }) => {
  console.log(`fetching... ${prefix}${url}`);
  // 'Content-Type': 'multipart/form-data'
  try {
    const result = await axios
      .delete(`${backend}${prefix}${url}`, {
        headers: { testing: 'true', 'Content-Type': 'application/json' },
      })
      .then(({ data, status }) => {
        return {
          status,
          data,
          is200: is200(status),
        };
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const uploadFiles = async ({
  url,
  prefix = backendPrefix,
  body = {},
}) => {
  console.log(`fetching... ${prefix}${url}`);
  try {
    const result = await axios
      .put(`${backend}${prefix}${url}`, body, {
        headers: { testing: 'true', 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data, status }) => {
        return {
          status,
          data,
          is200: is200(status),
        };
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const useGet = () => {
  const [result, setResult] = useState(null);
  const getRequest = async (value) => {
    const data = await get(value);
    if (data) {
      setResult(data);
    }
  };
  return {
    getRequest,
    result,
  };
};

export const usePost = () => {
  const [result, setResult] = useState(null);
  const postRequest = async (value) => {
    const data = await post(value);
    if (data) {
      setResult(data);
    }
  };
  return {
    postRequest,
    result,
  };
};
