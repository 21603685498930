import React, { useMemo, useContext } from 'react';
import { isArray } from 'lodash';

import Table from '../../../../components/common/table';
import TabTable from 'components/common/tabs/table';

function Companies({ context, fieldsToDisplay }) {
  const { data } = useContext(context);

  const rows = useMemo(() => {
    if (data?.companies && isArray(data?.companies)) {
      return data.companies.map((item, index) => {
        return {
          id: index,
          _id: item?.company?._id,
          short_name: item?.company?.short_name,
          effective_date: item?.effective_date,
          end_date: item?.end_date,
          value: item?.value,
          objectId: item?._id,
        };
      });
    }
    return [];
  }, [data]);

  const addable = useMemo(() => {
    if (rows.length === 0) return true;
    return rows.every(
      (row) => row.end_date !== undefined && row.end_date !== null
    );
  }, [rows]);

  return (
    <TabTable
      schema={[
        {
          id: 'short_name',
          isId: true,
          label: '公司簡稱',
          editable: false,
          selectPath: '/company/all-company-select',
          free_solo: false,
        },
        {
          id: 'effective_date',
          label: '擁有日期',
          type: 'date',
        },
        {
          id: 'end_date',
          label: '售出日期',
          type: 'date',
        },
        {
          id: 'value',
          label: '價值',
          show_dollar: true,
        },
      ]}
      setting={{
        collection: 'vehicle',
        keyName: 'company',
        orderBy: 'effective_date',
        addable,
      }}
      collectionId={data._id}
      rows={rows}
    />
  );
}

export default Companies;
