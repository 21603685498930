import React, { useContext } from 'react';
import { isEmpty } from 'lodash';

import Switcher from '../../../../components/common/input/switcher';
import Status from '../../../../components/status';
import {
  Section,
  CompanySection,
  VehicleSection,
} from '../../../../components/common/tabs/section-block';
import MetaData from '../../../../components/meta-data';

function ProfileGeneral({ context }) {
  const {
    mode,
    prefix,
    data,
    schema,
    setInputs,
    saveBtnClicked,
    pushItemToField,
    fieldsToDisplay,
  } = useContext(context);

  if (isEmpty(schema)) return null;
  return (
    <div className='flex gap-4 py-10' role='tabpanel'>
      <div className='_p-8 _shadow-md _bg-white grow flex flex-col gap-4'>
        <div>
          <Section>
            {fieldsToDisplay.map((field) => {
              if (field !== 'status') {
                return (
                  <Switcher
                    key={field}
                    schema={{
                      ...schema[field],
                      editable:
                        mode === 'edit' ? schema[field]?.editable : true,
                    }}
                    value={data?.[field]}
                    setInputs={setInputs}
                    saveBtnClicked={saveBtnClicked}
                    field={field}
                    pushItemToField={pushItemToField}
                    suggestEndpoint={`/${prefix}/column/${field}`}
                  />
                );
              }
              return null;
            })}
          </Section>
        </div>
        <MetaData data={data} />
      </div>
      <div className='w-6/12 xl:w-5/12 2xl:w-4/12 flex flex-col gap-4 shrink-0'>
        <Status data={data} setInputs={setInputs} />
        {/* <VehicleSection
          current_vehicle={data.current_vehicle}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
        /> */}
        <CompanySection
          current_company={data}
          setInputs={setInputs}
          saveBtnClicked={saveBtnClicked}
          exclude={['effective_date', 'end_date']}
        />
      </div>
    </div>
  );
}

export default ProfileGeneral;
