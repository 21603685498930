import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalStore } from '../../lib/store';
import Index from '../../components/pages/listing';
import ApplyForm from './apply_form';
import Profile from './profile';
import View from './view';
import PDF from './pdf';

function DriverPermitPage({
  title,
  prefix,
  mode,
  sidebarItem,
  newEntry,
  titleIcon,
}) {
  const { _id } = useParams();
  const { setActiveSidebarItem } = useGlobalStore();
  useEffect(() => {
    setActiveSidebarItem(sidebarItem);
  }, []);
  const isNew = mode === 'new';
  const isHome = mode === 'home';
  const isEdit = mode === 'edit';
  const isView = mode === 'view';
  const isPDF = mode === 'pdf';
  return isHome ? (
    <Index
      prefix={prefix}
      title={title}
      newEntry={newEntry}
      titleIcon={titleIcon}
    />
  ) : isNew ? (
    <ApplyForm title={title} prefix={prefix} titleIcon={titleIcon} />
  ) : isEdit && _id ? (
    <Profile _id={_id} title={title} prefix={prefix} titleIcon={titleIcon} />
  ) : isView && _id ? (
    <View _id={_id} />
  ) : isPDF && _id ? (
    <PDF _id={_id} />
  ) : null;
}

export default DriverPermitPage;
