import React, { useEffect, useState } from 'react';
import { useGlobalStore } from '../../lib/store';
import { get } from '../../lib/request';

function ViewForm({ _id }) {
	const { setShowSidebar, setShowTopnav } = useGlobalStore();
	const [company, setCompany] = useState('');
	const [driver, setDriver] = useState('');
	const [vehicles, setVehicles] = useState([]);
	useEffect(() => {
		setShowSidebar(false);
		setShowTopnav(false);
	}, []);

	useEffect(() => {
		get({
			url: `/driver_permit/get/${_id}?populate[]=driver&populate[]=company&populate[]=vehicles&populate[]=vehicles.current_reg_mark`,
		}).then(({ data }) => {
			const { vehicles, driver, company } = data?.record;
			setVehicles(vehicles);
			setDriver(driver);
			setCompany(company);
		});
	}, [_id]);

	useEffect(() => {
		document.title = `${driver?.name} permit_application_form`;
	}, [driver]);
	return (
		<>
			<div id='print-page__header' className='w-full top-0 h-28'>
				<div className='flex flex-col gap-4 h-full justify-center'>
					{company?.name_tc && (
						<div className='text-3xl'>{company.name_tc}</div>
					)}
					{company?.name && <div className='text-xl'>{company.name}</div>}
				</div>
			</div>
			<Content>
				<div className='flex flex-col gap-4'>
					<P1 driver={driver} />
					<List vehicles={vehicles} />
					<div className='print-page__page'>
						<Statement />
						<Sign company={company} />
					</div>
				</div>
			</Content>
			<Footer company={company} />
		</>
	);
}

const Footer = ({ company }) => {
	return (
		<div id='print-page__footer' className='w-full bottom-0 text-xs'>
			<div className='flex flex-col gap-1'>
				{company?.address_tc && <div className=''>{company.address_tc}</div>}
				{company?.address && <div className=''>{company.address}</div>}
				{company?.contact_number && (
					<div className=''>Tel: {company?.contact_number}</div>
				)}
				{company?.website && (
					<div className=''>Website: {company?.website}</div>
				)}
			</div>
		</div>
	);
};

const Content = ({ children }) => {
	return (
		<table>
			<thead>
				<tr>
					<td>
						<div className='h-28'></div>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{children}</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<td>
						<div className='h-12'></div>
					</td>
				</tr>
			</tfoot>
		</table>
	);
};

const Driver = ({ driver }) => {
	return (
		<span className='inline-block mx-2'>
			<div className='flex border-b-[1px] border-black'>
				<div className=''>{driver?.name_tc || driver?.name}</div>
				<div className='ml-2'>
					[身份證號碼：
					<span className='inline-block w-24 text-center'>{driver?.hkid}</span>]
				</div>
			</div>
		</span>
	);
};

const P1 = ({ driver }) => {
	return (
		<div className='flex flex-col'>
			<div className=''>致運輸署署長：</div>
			<div className=''>
				本公司乃以下車輛之登記車主，現擬推薦 <Driver driver={driver} />
				，受僱於本司之司機，現申請免試簽發特別用途車輛駕駛執照，資料如下：
			</div>
		</div>
	);
};

const Statement = () => {
	return (
		<div className='flex flex-col text-sm'>
			<div className=''>本公司謹此聲明及確定:-</div>
			<div className=''>
				（一）上述司機持有與所申請的特別用途車輛總重量相符之車輛類別的有效正式駕駛執照;及
			</div>
			<div className=''>（二）上述司機持有工作需要駕駛上述特別用途車輛</div>
			<div className=''>
				本人明白所簽發的特別用途車輛駕駛資格只批准上述司機駕駛有關的駕駛用途車輛；並會按需要，安排合適人手操作上述特別用途車輛的設備，以符合相關法例要求。
			</div>
			<div className=''>
				隨函附上上述車輛的車輛登記文件副本及上述司機的駕駛執照副本。
			</div>
		</div>
	);
};

const Sign = ({ company }) => {
	const data = [
		{ title: '登記車主簽名:', text: '' },
		{ title: '姓名（請用正階）:', text: company?.name_tc },
		{ title: '電話號碼', text: company?.contact_number },
		{ title: '有限公司註冊號碼', text: company?.reg_number },
		{ title: '日期', text: '' },
	];
	return (
		<div className='flex justify-end mt-24 text-sm'>
			<div className='flex flex-col gap-4 w-fit'>
				{data.map(({ title, text }) => (
					<div className='flex gap-4 items-end'>
						<div className='w-[200px]'>{title}</div>
						<div className='border-b-[1px] border-black grow text-center px-8 text-base min-w-[160px]'>
							{text}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const List = ({ vehicles }) => {
	const data = vehicles.reduce((acc, current, index) => {
		if (index % 2 === 0) {
			acc.push([current]);
		} else {
			acc[acc.length - 1].push(current);
		}
		return acc;
	}, []);
	return (
		<table className='w-full border-[1px] border-black'>
			<thead>
				<tr className=''>
					<th className='border-r-[1px] border-black px-1'>車牌號碼</th>
					<th className='border-r-[1px] border-black px-1'>底盤號碼</th>
					{data.length > 1 && (
						<>
							<th className='border-r-[1px] border-black px-1'>車牌號碼</th>
							<th className='px-1'>底盤號碼</th>
						</>
					)}
				</tr>
			</thead>
			<tbody>
				{data.map((d, index) => {
					return (
						<tr className='text border-[1px] border-black' key={index}>
							{d.map((vehicle, rowIndex) => {
								const num = index * 2 + rowIndex + 1;
								return (
									<>
										<td className='border-r-[1px] border-black px-1'>
											{num}. {vehicle?.current_reg_mark?.reg_mark}
										</td>
										<td className='border-r-[1px] border-black px-1'>
											{vehicle?.chassis_number}
										</td>
									</>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default ViewForm;
