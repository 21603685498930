import React, { useContext, useMemo } from 'react';

import TabTable from 'components/common/tabs/table';

function Vehicles({ context }) {
  const { data } = useContext(context);
  const rows = useMemo(() => {
    if (data && data.vehicles) {
      return data.vehicles.map((item, index) => {
        return {
          ...item,
          id: index,
          print_number: item?.vehicle?.print_number,
          vehicle_class: item?.vehicle?.vehicle_class,
          current_reg_mark: item?.vehicle?.current_reg_mark,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <TabTable
      schema={[
        {
          id: 'chassis_number',
          isId: true,
          label: '底盤號碼',
          editable: false,
        },
        {
          id: 'print_number',
          label: '車身編號',
        },
        {
          id: 'vehicle_class',
          label: '類別',
        },
        {
          id: 'effective_date',
          label: '生效日期',
          type: 'date',
        },
        {
          id: 'end_date',
          label: '結束日期',
          type: 'date',
        },
        {
          id: 'current_reg_mark',
          label: '車牌號碼',
        },
      ]}
      setting={{
        addable: false,
        editable: false,
        deletable: false,
      }}
      collectionId={data._id}
      rows={rows}
    />
  );
}

export default Vehicles;
