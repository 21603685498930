import React, { useMemo, useContext } from 'react';
import { isArray } from 'lodash';

import Table from '../../../../components/common/table';
import TabTable from 'components/common/tabs/table';

function PermitAreas({ context, fieldsToDisplay }) {
  const { data } = useContext(context);

  const rows = useMemo(() => {
    if (data?.permit_areas && isArray(data?.permit_areas)) {
      return data.permit_areas.map((item, index) => {
        return {
          id: index,
          _id: item?.permit_area?._id,
          area: item?.permit_area?.area,
          fee: item?.permit_area?.fee,
          effective_date: item?.permit_area?.effective_date,
          end_date: item?.permit_area?.end_date,
          objectId: item?._id,
          contract_number: item?.permit_area?.contract?.contract_number,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <TabTable
      schema={[
        {
          id: 'area',
          label: '許可區',
          editable: false,
          selectPath: '/permit_area/column/area',
          free_solo: true,
        },
        {
          id: 'fee',
          label: '費用',
          show_dollar: true,
        },
        {
          id: 'effective_date',
          label: '生效日期',
          type: 'date',
        },
        {
          id: 'end_date',
          label: '結束日期',
          type: 'date',
        },
        {
          id: 'contract_number',
          label: '扣數合約',
        },
      ]}
      setting={{
        popup: false,
        collection: 'vehicle',
        keyName: 'permit_area',
        orderBy: 'effective_date',
        copyable: true,
      }}
      collectionId={data._id}
      rows={rows}
    />
  );
}

export default PermitAreas;
