import React, { useEffect, useMemo } from 'react';
import { isUndefined } from 'lodash';
import { useGlobalStore } from '../../../lib/store';
import { useInputStore } from '../../../lib/input';

import General from './tabs/general';

import Tabs from '../../../components/common/tabs';
import Vehicles from './tabs/vehicles';

const InputContext = React.createContext();

const Profile = ({ _id, mode, prefix, title, titleIcon }) => {
  const { setDefaultTopnav } = useGlobalStore();
  const store = useInputStore({
    title,
    id: _id,
    prefix,
    mode,
    queryParams: '?populate[]=contracts.contract',
    customValidateFields: {
      input__vehicle_id: 'current_vehicle.vehicle._id',
      input__vehicle_effective_date: 'current_vehicle.effective_date',
      input__vehicle_end_date: 'current_vehicle.end_date',
    },
    customFieldsModifier: (changes, rawData) => {
      if (!isUndefined(changes['input__vehicle_id'])) {
        changes['input__vehicle_effective_date'] =
          changes['input__vehicle_effective_date'] ||
          rawData?.current_vehicle?.effective_date;
        changes['input__vehicle_end_date'] =
          changes['input__vehicle_end_date'] ||
          rawData?.current_vehicle?.end_date;
      } else if (
        changes['input__vehicle_effective_date'] ||
        changes['input__vehicle_end_date']
      ) {
        changes['input__vehicle_object_id'] = rawData?.current_vehicle?._id;
      }
      return changes;
    },
  });
  const { canSave, initStore, save } = store;

  const tabs = useMemo(
    () => [
      {
        label: '扣數合約基本資料',
        component: <General context={InputContext} />,
        props: {},
      },
      // {
      // 	label: 'Vehicles',
      // 	component: <Vehicles context={InputContext} />,
      // 	props: {},
      // },
    ],
    []
  );
  useEffect(() => {
    initStore({ _id, mode, prefix });
  }, []);
  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      canSave,
      save,
      mode,
      titleIcon,
      // copy: mode === 'edit' && `/${prefix}/copy/${_id}`,
    });
  }, [prefix, canSave, save, title, titleIcon]);

  return (
    <InputContext.Provider value={store}>
      <Tabs tabs={tabs} prefix={prefix} />
    </InputContext.Provider>
  );
};

export default Profile;
