import { Fragment } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoginPage from 'pages/login';
import Document from 'pages/_document';
import ContractPage from 'pages/contract';
import CompanyPage from 'pages/company';
import VehiclePage from 'pages/vehicle';
import LogPage from 'pages/log';
import UserPage from 'pages/user';
import RegMarkPage from 'pages/reg_mark';
import DriverPage from 'pages/driver';
import ContractDeductPage from 'pages/contract_deduct';
import PermitAreaPage from 'pages/permit_area';
import LicensePage from 'pages/license';
import ValidLicensePage from 'pages/valid_license';
import InsurancePage from 'pages/insurance';
import ValidInsurancePage from 'pages/valid_license';
import GpsPage from 'pages/gps';
import AutotollPage from 'pages/autotoll';
import FuelPage from 'pages/fuel';
import EmptyRegMark from 'pages/empty_reg_mark';
import DriverPermitPage from 'pages/driver_permit';
import ExpensesPage from 'pages/expenses';
import ExpensesAutotollPage from 'pages/expenses_autotoll';
import ExpensesFuelPage from 'pages/expenses_fuel';
import ExpensesBatchPage from 'pages/expenses_batch';
import ReportPage from 'pages/report';
import ContractVehiclePage from 'pages/contract_vehicle';
import MonthlyExpensesPage from 'pages/monthly_expenses';
import MonthlyVehiclePage from 'pages/monthly_vehicle';
import WarehousePage from 'pages/warehouse';
import InventoryPage from 'pages/inventory';
import InventoryTransactionPage from 'pages/inventory_transaction';
import IndexPage from 'pages';
import Loading from 'components/common/loading';
import {
  KeyboardArrowDown,
  OtherHouses,
  Storage,
  Topic,
  Settings,
  AttachMoney,
  DriveFileMove,
  Assignment,
} from '@mui/icons-material';
import { useGlobalStore } from 'lib/store';
import { find } from 'lodash';

const routes = [
  {
    prefix: 'company',
    Element: CompanyPage,
    title: '公司',
    sidebarItem: ['DATABASE', 'company'],
    newEntry: true,
    titleIcon: <Storage />,
  },
  {
    prefix: 'contract',
    Element: ContractPage,
    title: '合約',
    sidebarItem: ['DATABASE', 'contract'],
    newEntry: true,
    titleIcon: <Storage />,
  },
  {
    prefix: 'vehicle',
    Element: VehiclePage,
    title: '車輛/機械',
    sidebarItem: ['DATABASE', 'vehicle'],
    newEntry: true,
    titleIcon: <Storage />,
  },
  {
    prefix: 'reg_mark',
    Element: RegMarkPage,
    title: '車牌',
    sidebarItem: ['DATABASE', 'reg_mark'],
    newEntry: true,
    titleIcon: <Storage />,
  },
  {
    prefix: 'driver',
    Element: DriverPage,
    title: '司機',
    sidebarItem: ['DATABASE', 'driver'],
    newEntry: true,
    titleIcon: <Storage />,
  },
  {
    prefix: 'warehouse',
    Element: WarehousePage,
    title: '油倉',
    sidebarItem: ['DATABASE', 'warehouse'],
    newEntry: true,
    titleIcon: <Storage />,
  },
  {
    prefix: 'inventory',
    Element: InventoryPage,
    title: 'Inventory',
    sidebarItem: ['DATABASE', 'inventory'],
    newEntry: true,
  },
  {
    prefix: 'inventory_transaction',
    Element: InventoryTransactionPage,
    title: 'Inventory Transaction',
    sidebarItem: ['DATABASE', 'inventory_transaction'],
    newEntry: true,
  },
  {
    prefix: 'contract_deduct',
    Element: ContractDeductPage,
    title: '扣數合約',
    sidebarItem: ['VEHICLE INFO', 'contract_deduct'],
    newEntry: false,
    titleIcon: <Topic />,
  },
  {
    prefix: 'permit_area',
    Element: PermitAreaPage,
    title: '許可證',
    sidebarItem: ['VEHICLE INFO', 'permit_area'],
    newEntry: false,
    titleIcon: <Topic />,
  },
  {
    prefix: 'license',
    Element: LicensePage,
    title: '車輛續牌',
    sidebarItem: ['VEHICLE INFO', 'license'],
    newEntry: false,
    titleIcon: <Topic />,
  },
  {
    prefix: 'insurance',
    Element: InsurancePage,
    title: '車輛續保',
    sidebarItem: ['VEHICLE INFO', 'insurance'],
    newEntry: false,
    titleIcon: <Topic />,
  },
  {
    prefix: 'gps',
    Element: GpsPage,
    title: 'GPS',
    sidebarItem: ['VEHICLE INFO', 'gps'],
    newEntry: true,
    titleIcon: <Topic />,
  },
  {
    prefix: 'fuel',
    Element: FuelPage,
    title: '燃油卡',
    sidebarItem: ['VEHICLE INFO', 'fuel'],
    newEntry: false,
    titleIcon: <Topic />,
  },
  {
    prefix: 'autotoll',
    Element: AutotollPage,
    sidebarItem: ['VEHICLE INFO', 'autotoll'],
    newEntry: false,
    titleIcon: <Topic />,
  },
  {
    prefix: 'user',
    Element: UserPage,
    title: '用戶管理',
    sidebarItem: ['SETTING', 'user'],
    newEntry: true,
    titleIcon: <Settings />,
  },
  {
    prefix: 'driver_permit',
    Element: DriverPermitPage,
    title: '駕駛許可',
    sidebarItem: ['APPLICATION', 'driver_permit'],
    newEntry: true,
    titleIcon: <DriveFileMove />,
  },
  {
    prefix: 'expenses',
    Element: ExpensesPage,
    title: '費用扣數',
    sidebarItem: ['EXPENSE', 'expenses'],
    newEntry: true,
    showUpload: false,
    titleIcon: <AttachMoney />,
  },
  {
    prefix: 'expenses_batch',
    Element: ExpensesBatchPage,
    title: '滙入紀錄',
    sidebarItem: ['EXPENSE', 'expenses_batch'],
    newEntry: false,
    titleIcon: <AttachMoney />,
  },
  {
    prefix: 'expenses_autotoll',
    Element: ExpensesAutotollPage,
    title: '過路費',
    sidebarItem: ['EXPENSE', 'expenses_autotoll'],
    newEntry: true,
  },
  {
    prefix: 'expenses_fuel',
    Element: ExpensesFuelPage,
    title: '燃油費',
    sidebarItem: ['EXPENSE', 'expenses_fuel'],
    newEntry: true,
  },
  {
    prefix: 'report',
    Element: ReportPage,
    title: 'Report',
    sidebarItem: ['REPORT', 'report'],
    newEntry: true,
    titleIcon: <AttachMoney />,
  },
  // {
  //   prefix: 'contract_vehicle',
  //   Element: ContractVehiclePage,
  //   title: 'Contract Vehicle',
  //   sidebarItem: ['REPORT', 'contract_vehicle'],
  //   newEntry: false,
  //   titleIcon: <Assignment />,
  // },
  // {
  //   prefix: 'monthly_expenses',
  //   Element: MonthlyExpensesPage,
  //   title: 'Monthly Expenses',
  //   sidebarItem: ['REPORT', 'monthly_expenses'],
  //   newEntry: false,
  //   titleIcon: <Assignment />,
  // },
  // {
  //   prefix: 'monthly_vehicle',
  //   Element: MonthlyVehiclePage,
  //   title: 'Monthly Vehicle',
  //   sidebarItem: ['REPORT', 'monthly_vehicle'],
  //   newEntry: false,
  //   titleIcon: <Assignment />,
  // },
];

const AuthElement = (props) => {
  const { permissions, username } = useGlobalStore();
  const readable = find(permissions, {
    collection: props.collection,
  })?.readable;
  if (!username) {
    return <Navigate to='/login' replace={true} />;
  }
  if (readable) {
    return props.children;
  }
  return <Navigate to='/?alert=無權限&alertType=error' />;
};

function App() {
  const location = useLocation();
  const isLogin = location.pathname === '/login';
  return (
    <div>
      <Loading />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {isLogin ? (
          <Routes>
            <Route path='/login' element={<LoginPage />} />
          </Routes>
        ) : (
          <Document>
            <Routes>
              {routes.map((r) => {
                const {
                  Element,
                  prefix,
                  title,
                  sidebarItem,
                  newEntry,
                  showUpload,
                  titleIcon,
                } = r;
                return (
                  <Fragment key={prefix}>
                    <Route
                      path={`/${prefix}`}
                      element={
                        <AuthElement collection={prefix}>
                          <Element
                            mode='home'
                            prefix={prefix}
                            title={title || prefix}
                            sidebarItem={sidebarItem}
                            newEntry={newEntry}
                            upload={showUpload}
                            titleIcon={titleIcon}
                          />
                        </AuthElement>
                      }
                    />
                    <Route
                      path={`/${prefix}/new`}
                      element={
                        <AuthElement collection={prefix}>
                          <Element
                            mode='new'
                            prefix={prefix}
                            title={title || prefix}
                            sidebarItem={sidebarItem}
                            newEntry={newEntry}
                            upload={showUpload}
                            titleIcon={titleIcon}
                          />
                        </AuthElement>
                      }
                    />
                    <Route
                      path={`/${prefix}/copy/:_id`}
                      element={
                        <AuthElement collection={prefix}>
                          <Element
                            mode='copy'
                            prefix={prefix}
                            title={title || prefix}
                            sidebarItem={sidebarItem}
                            newEntry={newEntry}
                            upload={showUpload}
                            titleIcon={titleIcon}
                          />
                        </AuthElement>
                      }
                    />
                    <Route
                      path={`/${prefix}/:_id`}
                      element={
                        <AuthElement collection={prefix}>
                          <Element
                            mode='edit'
                            prefix={prefix}
                            title={title || prefix}
                            sidebarItem={sidebarItem}
                            newEntry={newEntry}
                            upload={showUpload}
                            titleIcon={titleIcon}
                          />
                        </AuthElement>
                      }
                    />
                    <Route
                      path={`/${prefix}/import`}
                      element={
                        <AuthElement collection={prefix}>
                          <Element
                            mode='import'
                            prefix={prefix}
                            title={title || prefix}
                            sidebarItem={sidebarItem}
                            newEntry={newEntry}
                            upload={showUpload}
                            titleIcon={titleIcon}
                          />
                        </AuthElement>
                      }
                    />
                  </Fragment>
                );
              })}
              <Route path='/' element={<IndexPage />} />
              {/* Logs */}
              <Route
                path='/log'
                element={
                  <AuthElement collection='log'>
                    <LogPage mode='home' prefix='log' />
                  </AuthElement>
                }
              />
              <Route
                path='/log/:_id'
                element={
                  <AuthElement collection='log'>
                    <LogPage prefix='log' />
                  </AuthElement>
                }
              />
              {/* Logs */}
              {/* User */}
              {/* <Route
								path='/user'
								element={
									<UserPage
										mode='home'
										prefix='user'
										title='user'
										sidebarItem={['System Management', 'User']}
									/>
								}
							/>
							<Route
								path='/user/:_id'
								element={
									<UserPage
										mode='edit'
										prefix='user'
										title='user'
										sidebarItem={['System Management', 'User']}
									/>
								}
							/> */}
              {/* User */}
              {/* Empty Reg_mark */}
              <Route
                path='empty_reg_mark'
                element={
                  <AuthElement collection='reg_mark'>
                    <EmptyRegMark
                      mode='home'
                      prefix='reg_mark'
                      model='empty_reg_mark'
                      title='未套車牌'
                      sidebarItem={['DATABASE', 'empty_reg_mark']}
                      newEntry={true}
                      titleIcon={<Storage />}
                    />
                  </AuthElement>
                }
              />
              {/* Empty Reg_mark */}
              {/* Driver License Apply */}
              <Route
                path='/driver_permit/:_id/view'
                element={
                  <AuthElement collection='driver_permit'>
                    <DriverPermitPage
                      mode='view'
                      sidebarItem={['APPLICATION', 'driver_permit']}
                      newEntry={true}
                    />
                  </AuthElement>
                }
              />
              <Route
                path='/driver_permit/:_id/pdf'
                element={
                  <AuthElement collection='driver_permit'>
                    <DriverPermitPage
                      mode='pdf'
                      sidebarItem={['APPLICATION', 'driver_permit']}
                      newEntry={true}
                    />
                  </AuthElement>
                }
              />
              {/* Driver License Apply */}
              {/* Valid License */}
              <Route
                path='valid_license'
                element={
                  <AuthElement collection='license'>
                    <ValidLicensePage
                      mode='home'
                      prefix='license'
                      model='valid_license'
                      title='車輛續牌'
                      sidebarItem={['VEHICLE INFO', 'valid_license']}
                      newEntry={false}
                      titleIcon={<Topic />}
                    />
                  </AuthElement>
                }
              />
              {/* Valid License */}
              {/* Valid Insurance */}
              <Route
                path='valid_insurance'
                element={
                  <AuthElement collection='insurance'>
                    <ValidInsurancePage
                      mode='home'
                      prefix='insurance'
                      model='valid_insurance'
                      title='車輛續保'
                      sidebarItem={['VEHICLE INFO', 'valid_insurance']}
                      newEntry={false}
                      titleIcon={<Topic />}
                    />
                  </AuthElement>
                }
              />
              {/* Valid Insurance */}
              {/* Expenses Batch */}
              <Route
                path='/expenses_batch/batches'
                element={
                  <AuthElement collection='expenses_batch'>
                    <ExpensesBatchPage
                      mode='batch'
                      prefix='expenses_batch'
                      title='滙入紀錄'
                      sidebarItem={['EXPENSE', 'expenses_batch']}
                      newEntry={false}
                      titleIcon={<AttachMoney />}
                    />
                  </AuthElement>
                }
              />
              {/* Expenses Batch */}
              {/* Report */}
              <Route
                path='/contract_vehicle'
                element={
                  <AuthElement collection='contract_vehicle'>
                    <ContractVehiclePage
                      mode='contract_vehicle'
                      prefix='contract_vehicle'
                      title='合約車輛總結'
                      sidebarItem={['REPORT', 'contract_vehicle']}
                      newEntry={false}
                      titleIcon={<Assignment />}
                    />
                  </AuthElement>
                }
              />
              <Route
                path='/monthly_expenses'
                element={
                  <AuthElement collection='monthly_expenses'>
                    <MonthlyExpensesPage
                      mode='monthly_expenses'
                      prefix='monthly_expenses'
                      title='每月車輛開支'
                      sidebarItem={['REPORT', 'monthly_expenses']}
                      newEntry={false}
                      titleIcon={<Assignment />}
                    />
                  </AuthElement>
                }
              />
              {/* /monthly_expenses/reports may no need */}
              <Route
                path='/monthly_expenses/reports'
                element={
                  <AuthElement collection='monthly_expenses'>
                    <MonthlyExpensesPage
                      mode='monthly_expenses_reports'
                      prefix='monthly_expenses'
                      title='每月車輛開支'
                      sidebarItem={['REPORT', 'monthly_expenses']}
                      newEntry={false}
                      titleIcon={<Assignment />}
                    />
                  </AuthElement>
                }
              />
              <Route
                path='/monthly_vehicle'
                element={
                  <AuthElement collection='monthly_vehicle'>
                    <MonthlyVehiclePage
                      mode='monthly_vehicle'
                      prefix='monthly_vehicle'
                      title='每月車輛轉移'
                      sidebarItem={['REPORT', 'monthly_vehicle']}
                      newEntry={false}
                      titleIcon={<Assignment />}
                    />
                  </AuthElement>
                }
              />
              {/* Report */}
            </Routes>
          </Document>
        )}
      </LocalizationProvider>
    </div>
  );
}

export default App;
