import React, { useEffect, useMemo } from 'react';
import { useGlobalStore } from '../../../lib/store';
import { useInputStore } from '../../../lib/input';
import { isUndefined, isString } from 'lodash';

import General from './tabs/general';

import Tabs from '../../../components/common/tabs/';
import Companies from './tabs/companies';
import RegMarks from './tabs/reg_marks';
import Contracts from './tabs/contracts';
import PermitAreas from './tabs/permit_areas';
import Insurances from './tabs/insurances';
import Licenses from './tabs/licenses';
import Autotolls from './tabs/autotolls';
import Gpses from './tabs/gpses';
import Fuels from './tabs/fuels';
import ContractDeducts from './tabs/contract_deducts';
const InputContext = React.createContext();

const tabs = [
  {
    label: '車輛/機械基本資料',
    key: 'general',
    component: <General context={InputContext} />,
  },
  {
    key: 'company',
    label: '擁有公司',
    component: (
      <Companies
        context={InputContext}
        fieldsToDisplay={{
          short_name: '公司簡稱',
          effective_date: '擁有日期',
          end_date: '售出日期',
          value: '價值',
        }}
      />
    ),
  },
  {
    key: 'contract',
    label: '資產合約',
    component: (
      <Contracts
        context={InputContext}
        fieldsToDisplay={{
          contract_number: '合約編號',
          effective_date: '生效日期',
          end_date: '結束日期',
        }}
      />
    ),
  },
  {
    label: '車牌記錄',
    key: 'reg_mark',
    component: (
      <RegMarks
        context={InputContext}
        fieldsToDisplay={{
          reg_mark: '車牌號碼',
          effective_date: '生效日期',
          end_date: '結束日期',
        }}
      />
    ),
  },
  {
    label: '扣數合約記錄',
    key: 'contract_deduct',
    component: (
      <ContractDeducts
        context={InputContext}
        fieldsToDisplay={{
          contract_number: '合約編號',
          effective_date: '生效日期',
          end_date: '結束日期',
        }}
      />
    ),
  },
  {
    label: '許可證',
    key: 'permit_area',
    component: (
      <PermitAreas
        context={InputContext}
        fieldsToDisplay={{
          area: '許可區',
          fee: '費用',
          effective_date: '生效日期',
          end_date: '結束日期',
        }}
      />
    ),
  },
  {
    label: '車輛續牌',
    key: 'license',
    component: (
      <Licenses
        context={InputContext}
        fieldsToDisplay={{
          effective_date: '續牌日期',
          end_date: '結束日期',
          license_fee: '牌費',
          special_permit: '特別許可證',
          permit_fee: '特別許可費',
        }}
      />
    ),
  },
  {
    label: '車輛續保',
    key: 'insurance',
    component: (
      <Insurances
        context={InputContext}
        fieldsToDisplay={{
          insurance_company: '投保公司',
          insurance_kind: '保單種類',
          policy_number: '保單號碼',
          policy_number2: '保單號碼(中港)',
          insurance_fee: '保費',
          effective_date: '承保日期 ',
          end_date: '結束日期',
        }}
      />
    ),
  },
  {
    label: 'GPS',
    key: 'gps',
    component: (
      <Gpses
        context={InputContext}
        fieldsToDisplay={{
          gps_number: 'GPS No.',
          charge: '每月費用',
          effective_date: '生效日期',
          end_date: '結束日期',
        }}
      />
    ),
  },
  {
    label: 'Autotoll',
    key: 'autotoll',
    component: (
      <Autotolls
        context={InputContext}
        fieldsToDisplay={{
          autotoll_number: 'Autotoll No.',
          effective_date: '生效日期',
          end_date: '結束日期',
        }}
      />
    ),
  },
  {
    label: '燃油卡',
    key: 'fuel',
    component: (
      <Fuels
        context={InputContext}
        fieldsToDisplay={{
          fuel_type: '燃油種類',
          provider: '供應商',
          account_number: '油咭賬號',
          card_number: '油咭號碼',
          effective_date: '生效日期',
          end_date: '結束日期',
        }}
      />
    ),
  },
];

const Profile = ({ _id, mode, prefix, title, titleIcon }) => {
  const { setDefaultTopnav } = useGlobalStore();
  const store = useInputStore({
    title,
    id: _id,
    prefix,
    mode,
    customValidateFields: {
      input__company_id: 'current_company.company._id',
      input__company_effective_date: 'current_company.effective_date',
      input__company_end_date: 'current_company.end_date',
      input__company_value: 'current_company.value',
      input__contract_id: 'current_contract.contract._id',
      input__contract_effective_date: 'current_contract.effective_date',
      input__contract_end_date: 'current_contract.end_date',
      input__contract_deduct_id: 'current_contract_deduct.contract_deduct._id',
      input__contract_deduct_effective_date:
        'current_contract_deduct.effective_date',
      input__contract_deduct_end_date: 'current_contract_deduct.end_date',
    },
    customFieldsModifier: (changes, rawData) => {
      if (
        changes['input__company_effective_date'] ||
        changes['input__company_end_date'] ||
        changes['input__company_value']
      ) {
        changes['input__company_object_id'] = rawData?.current_company?._id;
      }

      if (!isUndefined(changes['input__contract_id'])) {
        changes['input__contract_effective_date'] =
          changes['input__contract_effective_date'] ||
          rawData?.current_contract?.effective_date;
        changes['input__contract_end_date'] =
          changes['input__contract_end_date'] ||
          rawData?.current_contract?.end_date;
      } else if (
        changes['input__contract_effective_date'] ||
        changes['input__contract_end_date']
      ) {
        changes['input__contract_object_id'] = rawData?.current_contract?._id;
      }

      if (!isUndefined(changes['input__contract_deduct_id'])) {
        changes['input__contract_deduct_effective_date'] =
          changes['input__contract_deduct_effective_date'] ||
          rawData?.current_contract_deduct?.effective_date;
        changes['input__contract_deduct_end_date'] =
          changes['input__contract_deduct_end_date'] ||
          rawData?.current_contract_deduct?.end_date;
      } else if (
        changes['input__contract_deduct_effective_date'] ||
        changes['input__contract_deduct_end_date']
      ) {
        changes['input__contract_deduct_object_id'] =
          rawData?.current_contract_deduct?._id;
      }
      return changes;
    },
  });
  const { canSave, initStore, save, isEdit } = store;

  useEffect(() => {
    initStore({ _id, mode, prefix });
  }, []);
  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      canSave,
      save,
      mode,
      titleIcon,
      // copy: mode === 'edit' && `/${prefix}/copy/${_id}`,
    });
  }, [prefix, canSave, save, titleIcon]);
  return (
    <InputContext.Provider value={store}>
      <Tabs
        tabs={!isEdit ? tabs.filter((v) => v.key === 'general') : tabs}
        prefix={prefix}
      />
    </InputContext.Provider>
  );
};

export default Profile;
