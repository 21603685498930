import React, { useContext, useMemo } from 'react';

import Table from '../../../../components/common/table';

const fieldsToDisplay = {
	name: 'Company English Name',
	name_tc: 'Company Name',
	reg_number: 'Reg No.',
	effective_date: 'Effective Date',
	end_date: 'End Date',
};

function Companies({ context }) {
	const { data } = useContext(context);
	const rows = useMemo(() => {
		if (data && data.companies) {
			return data.companies.map((item, index) => {
				return {
					...item?.company,
					...item,
					id: index,
					_id: item?.company?._id,
				};
			});
		}
		return [];
	}, [data]);

	return (
		<Table
			data={rows}
			fieldsToDisplay={fieldsToDisplay}
			editEndPoint='/company'
		/>
	);
}

export default Companies;
