import React, { useMemo, useContext } from 'react';
import { isArray } from 'lodash';

import Table from '../../../../components/common/table';
import TabTable from 'components/common/tabs/table';

function Contracts({ context, fieldsToDisplay }) {
  const { data, schema } = useContext(context);
  console.log({ data });

  const rows = useMemo(() => {
    if (data?.contracts && isArray(data?.contracts)) {
      return data.contracts.map((item, index) => {
        return {
          id: index,
          _id: item?.contract?._id,
          contract_number: item?.contract?.contract_number,
          effective_date: item?.effective_date,
          end_date: item?.end_date,
          objectId: item?._id,
        };
      });
    }
    return [];
  }, [data]);

  const addable = useMemo(() => {
    if (rows.length === 0) return true;
    return rows.every(
      (row) => row.end_date !== undefined && row.end_date !== null
    );
  }, [rows]);

  return (
    <TabTable
      schema={[
        {
          id: 'contract_number',
          isId: true,
          label: '合約編號',
          editable: false,
          selectPath: '/contract/all-contract-select',
          free_solo: false,
        },
        {
          id: 'effective_date',
          label: '生效日期',
          type: 'date',
        },
        {
          id: 'end_date',
          label: '結束日期',
          type: 'date',
        },
      ]}
      setting={{
        collection: 'vehicle',
        keyName: 'contract',
        orderBy: 'effective_date',
        addable,
      }}
      collectionId={data._id}
      rows={rows}
    />
  );
}

export default Contracts;
