import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { get, put } from 'lib/request';
import { ChevronRight } from '@mui/icons-material';
import { useGlobalStore } from '../../lib/store';
import To from '../../components/common/to';
import { Button, Paper } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MetaData from '../../components/meta-data';
import { DatePicker } from '@mui/x-date-pickers';
import { MuiFileInput } from 'mui-file-input';
import { DeleteForever } from '@mui/icons-material';
import dayjs from 'dayjs';
import { forEach, isEmpty, reduce } from 'lodash';
import PDF from './pdf';

const domain =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8002'
    : window.location.origin;

const useSubmit = ({ _id, schema, data, fetchData }) => {
  const [input, setInput] = useState({});
  const { showAlert } = useGlobalStore();

  const _setInput = ({ key, data }) => {
    setInput((v) => ({
      ...v,
      [key]: data,
    }));
  };

  useEffect(() => {
    forEach(schema, (item, key) => {
      if (item.editable !== false && key !== 'status') {
        console.log(data[key]);
        _setInput({ key, data: data[key] });
      }
    });
  }, [schema, data]);

  const body = useMemo(() => {
    if (!data) return {};
    return reduce(
      schema,
      (body, item, key) => {
        if (
          item.editable !== false &&
          data[key] !== input[key] &&
          key !== 'status'
        ) {
          body[key] = input[key];
        }
        return body;
      },
      {}
    );
  }, [input, schema, data]);

  const canSave = useMemo(() => {
    return body && !isEmpty(body);
  }, [body]);
  const save = useCallback(() => {
    if (canSave) {
      console.log(body);
      put({ url: `/driver_permit/update/${_id}`, body }).then((response) => {
        showAlert({ message: '更新成功!' });
        fetchData();
      });
    }
  }, [body, canSave]);

  return {
    save,
    canSave,
    input,
    setInput: _setInput,
  };
};

function ApplyForm({ _id, prefix, title, titleIcon }) {
  const [schema, setSchema] = useState({});
  const [data, setData] = useState(null);
  const { setDefaultTopnav } = useGlobalStore();
  const [dataRecord, setDataRecord] = useState('');
  const fetchData = useCallback(() => {
    get({
      url: `/driver_permit/get/${_id}`,
    }).then(({ data }) => {
      setSchema(data?.schema);
      setData(data?.record);
      setDataRecord(data);
    });
  }, [_id]);
  const { save, canSave, input, setInput } = useSubmit({
    schema,
    data,
    _id,
    fetchData,
  });

  useEffect(() => {
    setDefaultTopnav({
      url: `/${prefix}`,
      title,
      canSave,
      save,
      titleIcon,
    });
  }, [prefix, title, save, canSave, titleIcon]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className='flex gap-4 justify-center mb-5'>
        <div className='flex flex-col gap-4 w-full'>
          <div className='flex justify-end'>
            {/* <To url={`/driver_permit/${_id}/pdf`} toNew>
              <Button
                variant='contained'
                size='large'
                endIcon={<PictureAsPdfIcon />}
              >
                查看表格
              </Button>
            </To> */}
            <Button
              variant='contained'
              size='large'
              endIcon={<PictureAsPdfIcon />}
            >
              <PDF _id={_id} data={data} />
            </Button>
          </div>
          {/* <Status data={data} setInputs={() => {}} /> */}
          <Paper>
            <div className='flex flex-col p-4 gap-4'>
              <Field
                title={schema?.driver?.title}
                url={`/driver/${data?.driver?._id}`}
              >
                <div className=''>
                  {data?.driver?.name || data?.driver?.name_tc}
                  {data?.driver?.hkid
                    ? ` | HKID : ${(data?.driver?.hkid).substr(0, 4)}`
                    : ''}
                  {data?.driver?.license
                    ? ` | 駕駛執照 : ${data?.driver?.license}`
                    : ''}
                </div>
              </Field>
              <Field
                title={schema?.company?.title}
                url={`/company/${data?.company?._id}`}
              >
                <div className=''>
                  {data?.company?.short_name ||
                    data?.company?.name_tc ||
                    data?.company?.name}
                </div>
              </Field>
              {['application_date', 'approval_date'].map((key, i) => {
                return (
                  <Field title={schema[key]?.title} key={i}>
                    <DatePicker
                      clearable
                      slotProps={{
                        actionBar: { actions: ['clear', 'today'] },
                      }}
                      format='YYYY-MM-DD'
                      value={input[key] ? dayjs(input[key]) : null}
                      onChange={(v) => {
                        if (v) {
                          const date = dayjs(v);
                          if (date.isValid()) {
                            setInput({
                              data: date.toISOString(),
                              key,
                            });
                          }
                        } else if (v === null) {
                          setInput({ key, data: null });
                        }
                      }}
                    />
                  </Field>
                );
              })}
              <ApprovalFile
                approval_file={data?.approval_file}
                setInput={setInput}
              />
            </div>
          </Paper>
          <div className='flex flex-col gap-4 w-full'>
            <List data={data?.vehicles || []} />
          </div>
        </div>
      </div>
      <MetaData data={data} />
    </div>
  );
}

const ApprovalFile = ({ approval_file, setInput }) => {
  const [file, setFile] = useState(null);
  return (
    <Field title='獲批文件'>
      <div className='flex flex-col'>
        <MuiFileInput
          value={file}
          size='small'
          onChange={(v) => {
            setFile(v);
            setInput({ key: 'approval_file', data: v });
          }}
        />
        {approval_file && (
          <PreviewFile
            file={approval_file}
            field='approval_file'
            setInput={setInput}
          />
        )}
      </div>
    </Field>
  );
};

function convertFileSize(sizeInBytes) {
  if (!sizeInBytes) return '';
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = sizeInBytes;
  let unitIndex = 0;
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }
  return size.toFixed(2) + ' ' + units[unitIndex];
}

const Image = ({ src, size, name, removeFile }) => {
  return (
    <div className='max-w-[300px] relative'>
      <div
        className='absolute top-0 right-0 bg-zinc-200/50 rounded'
        onClick={removeFile}
      >
        <DeleteForever />
      </div>
      <img src={src} alt='preview' />
      <div className=''>
        {name} - {convertFileSize(size)}
      </div>
    </div>
  );
};

const PreviewFile = ({ file, setInput, field }) => {
  const { mimetype, name, size } = file;
  const [isRemoved, setIsRemoved] = useState(false);
  const src = `${domain}/file/${name}`;
  const removeFile = (e) => {
    e.preventDefault();
    setInput({ key: field, data: null });
    setIsRemoved(true);
  };
  if (isRemoved) return null;
  return (
    <div className='mt-2 underline'>
      <To url={src} withoutDomain toNew>
        {mimetype.includes('image') ? (
          <Image src={src} size={size} name={name} removeFile={removeFile} />
        ) : (
          <div className='flex'>
            <div>{name}</div>
            <div onClick={removeFile}>
              <DeleteForever />
            </div>
          </div>
        )}
      </To>
    </div>
  );
};

const Field = ({ title, url, children }) => {
  return (
    <div className='flex gap-4 items-center min-h-10 text-lg'>
      <div className='text-bold w-40 shrink-0'>{title}</div>
      <div className='grow-0'>
        {url ? (
          <To url={url} className='underline' toNew>
            {children}
          </To>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

const List = ({ data }) => {
  return (
    <div className='p-4 shadow-md bg-white flex flex-col gap-2'>
      <div className='flex flex-col'>
        <div className='flex gap-4 items-center'>
          <div className='w-8 text-center'>No.</div>
          <div className='w-24'>車牌</div>
          <div className='w-40'>底盤號碼</div>
          <div className='w-40'>駕駛牌照</div>
          <div className='w-40'>許可車輛總重</div>
        </div>
      </div>
      <div className=''>
        {data &&
          data.length > 0 &&
          data.map((v, index) => {
            return (
              <div
                className='flex gap-4 items-center hover:bg-zinc-100 py-2'
                key={v._id}
              >
                <div className='w-8 text-center'>{index + 1}</div>
                <div className='w-24'>{v?.current_reg_mark?.reg_mark}</div>
                <div className='w-40'>{v.chassis_number}</div>
                <div className='w-40'>{v.license}</div>
                <div className='w-40'>{v.gross_vehicle_weight}</div>
                <div className='grow flex justify-end pr-2'>
                  <To url={`/vehicle/${v._id}`} toNew>
                    <ChevronRight />
                  </To>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ApplyForm;
