import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAtom } from 'jotai';
import { _username, useGlobalStore } from '../../lib/store';
import { basename } from '../../lib/config';

import {
  KeyboardArrowDown,
  OtherHouses,
  Storage,
  Topic,
  Settings,
  AttachMoney,
  DriveFileMove,
  Assignment,
} from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import To from '../common/to';

const items = [
  {
    name: '系統概覽',
    url: '/',
    icon: <OtherHouses sx={{ color: 'white' }} />,
    disabled: true,
    key: '',
  },
  {
    name: '資料庫',
    icon: <Storage sx={{ color: 'white' }} />,
    key: 'DATABASE',
    children: [
      {
        name: '合約',
        url: '/contract',
        key: 'contract',
      },
      {
        name: '公司',
        url: '/company',
        key: 'company',
      },
      {
        name: '車輛/機械',
        url: '/vehicle',
        key: 'vehicle',
      },
      {
        name: '司機',
        url: '/driver',
        key: 'driver',
      },
      {
        name: '車牌',
        url: '/reg_mark',
        key: 'reg_mark',
      },
      {
        name: '未套車牌',
        url: '/empty_reg_mark',
        key: 'empty_reg_mark',
      },
      {
        name: '油倉',
        url: '/warehouse',
        key: 'warehouse',
      },
    ],
  },
  {
    name: '車輛/機械資料',
    icon: <Topic sx={{ color: 'white' }} />,
    key: 'VEHICLE INFO',
    children: [
      {
        name: '扣數合約',
        url: '/contract_deduct',
        key: 'contract_deduct',
      },
      {
        name: '許可證',
        url: '/permit_area',
        key: 'permit_area',
      },
      {
        name: '車輛續牌',
        url: '/valid_license',
        key: 'valid_license',
      },
      {
        name: '車輛續牌(全部)',
        url: '/license',
        key: 'license',
      },
      {
        name: '車輛續保',
        url: '/valid_insurance',
        key: 'valid_insurance',
      },
      {
        name: '車輛續保(全部)',
        url: '/insurance',
        key: 'insurance',
      },
      {
        name: 'GPS',
        url: '/gps',
        key: 'gps',
      },
      {
        name: 'Autotoll',
        url: '/autotoll',
        key: 'autotoll',
      },
      {
        name: '燃油卡',
        url: '/fuel',
        key: 'fuel',
      },
    ],
  },
  {
    name: '提交申請',
    icon: <DriveFileMove sx={{ color: 'white' }} />,
    key: 'APPLICATION',
    children: [
      {
        name: '駕駛許可',
        url: '/driver_permit',
        key: 'driver_permit',
      },
      {
        name: '維修',
        url: '/repair_record',
        key: 'repair_record',
      },
    ],
  },
  {
    name: '支出',
    icon: <AttachMoney sx={{ color: 'white' }} />,
    key: 'EXPENSE',
    children: [
      {
        name: '滙入紀錄',
        url: '/expenses_batch/batches',
        key: 'expenses_batch',
      },
      {
        name: '費用扣數',
        url: '/expenses',
        key: 'expenses',
      },
      // {
      //   name: '過路費',
      //   url: '/expenses_autotoll',
      //   key: 'expenses_autotoll',
      // },
      // {
      //   name: '燃油費',
      //   url: '/expenses_fuel',
      //   key: 'expenses_fuel',
      // },
    ],
  },
  {
    key: 'REPORT',
    name: '報表',
    icon: <Assignment sx={{ color: 'white' }} />,
    children: [
      {
        name: '合約車輛總結',
        url: '/contract_vehicle',
        key: 'contract_vehicle',
      },
      {
        name: '每月車輛開支',
        url: '/monthly_expenses',
        key: 'monthly_expenses',
      },
      {
        name: '每月車輛轉移',
        url: '/monthly_vehicle',
        key: 'monthly_vehicle',
      },
    ],
  },
  {
    name: '系統設置',
    key: 'SETTING',
    icon: <Settings sx={{ color: 'white' }} />,
    isAdminOnly: true,
    children: [
      {
        name: '用戶管理',
        url: '/user',
        key: 'user',
      },
      {
        name: 'Log',
        url: '/log',
        key: 'log',
      },
    ],
  },
];

const Bar = ({ active, item, icon, isChild, hasChild, expanded }) => {
  const to = !hasChild && item?.url ? item.url : null;
  return (
    <To
      className={`flex w-full gap-5 p-2 ${isChild && 'pl-5'} ${
        active && 'bg-zinc-500'
      } rounded-md hover:bg-zinc-500`}
      url={to}
      onClick={(e) => {
        if (!to) {
          e.preventDefault();
        }
      }}
    >
      <Box>{icon}</Box>
      <div className='text-white'>{item.name}</div>
      <div className='grow flex justify-end'>
        <div className={expanded ? 'rotate-180' : ''}>
          {hasChild && <KeyboardArrowDown sx={{ color: 'white' }} />}
        </div>
      </div>
    </To>
  );
};

const Group = ({ item }) => {
  const [expanded, setExpanded] = useState(false);
  const { activeSidebarItem, isAdmin } = useGlobalStore();
  useEffect(() => {
    if (activeSidebarItem[0] && activeSidebarItem[0] === item.key) {
      setExpanded(true);
    }
  }, [activeSidebarItem]);
  if (!isAdmin && item.isAdminOnly) return null;
  return (
    <Accordion
      disableGutters
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded(expanded);
      }}
      sx={{
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          '.MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
      >
        <Bar
          item={item}
          icon={item.icon}
          hasChild={item.children}
          expanded={expanded}
        />
      </AccordionSummary>
      {item.children && (
        <AccordionDetails>
          <div className='flex flex-col gap-1'>
            {item.children.map((child) => (
              <Bar
                active={
                  expanded &&
                  activeSidebarItem[1] &&
                  activeSidebarItem[1] === child.key
                }
                key={child.name}
                item={child}
                icon={item.icon}
                isChild={true}
              />
            ))}
          </div>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const Items = () => {
  return (
    <Box>
      <div className='mt-3 w-[280px]'>
        {items.map((item) => {
          return <Group key={item.name} item={item} />;
        })}
      </div>
    </Box>
  );
};

export default Items;
